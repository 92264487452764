import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        feed_languages_selector: [],
        feed_languages: [],
        feed_languages_evaluator: [],
        feed_professional_activity_sector: [],
        feed_pallette: [],
        feed_font: [],
        feed_social_reason: [],
        feed_country: [],
        feed_country_companies: [],
        feed_num_employees_companies: [],
        feed_activity_companies: [],
        feed_activity: [],
        feed_num_employers: [],
        feed_tabs: [],
        feed_positions: [],
        feed_perms: [],
        feed_groups: [],
        feed_profiles: [],
        feed_topic: [],
        feed_procedures: [],
        feed_examples: [],
        feed_bibliography: [],
        feed_videography: [],
        feed_support_material: [],
        feed_type: [],
        feed_type_exercise: [],
        feed_supp_groups: [],
        feed_type_exercise_by_parent: [],
        feed_condition: [],
        feed_exercises: [],
        feed_selector_activities: [],
        feed_selector_ambits_certificates: [],
        data_treatment_text: '',
        feed_support_categories: '',
        feed_categories_club: [],
        feed_support_priority: '',
        feed_support_asigned: '',
        feed_incidence_priority: [],
        feed_support_workflow_tasks: [],
        feed_incidence_category: [],
        feed_incidence_asigned: [],
        feed_company: [],
        feed_organizational_unit: [],
        feed_level: [],
        feed_state_phone_number: [],
        feed_state_client: [],
        feed_responsible: [],
        feed_excerises_no_subquestions: [],
        feed_reference_thesauros: [],
        feed_support_workflows: [],
        feed_priority_by_category: [],
        feed_assigment_by_category: [],
        feed_mandatory_task: [],
        feed_elements: [],
        feed_groupers: [],
        feed_groupers_elaboration_pending: [],
        result_search: [],
        result_search_supports: [],
        result_search_exercises: [],
        feed_exercise_groupers: [],
        result_search_associate: [],
        feed_impartation: [],
        feed_stamp: [],
        result_search_stamps: [],
        result_search_certificates: [],
        feed_elements_conditions: [],
        feed_users_responsible_group: [],
        feed_certificate: [],
        feed_type_answers: [],
        feed_weighting_elements: [],
        feed_weighting_groups: [],
        feed_evaluation_basis: [],
        feed_score_range: [],
        feed_tree_itinerary: [],
        flagTreeItinerary: false,
        feed_evaluation_basis_first_level: [],
        feed_type_evaluation: [],
        feed_evaluator_modality: [],
        feed_channel: [],
        feed_ccaa: [],
        feed_groups_type_company: [],
        flagWeightingGroupItineraryCompany: false,
        feed_weigthing_group_by_itinerary_company: [],
        feed_pay_methods: [],
        feed_community_by_country: [],
        feed_community_by_country_professional: [],
        feed_province_by_community: [],
        feed_province_by_community_professional: [],
        feed_province_by_country: [],
        feed_locality_by_province: [],
        feed_professional_experience: [],
        feed_grade_experience: [],
        feed_professional_experience_sector: [],
        feed_sectors_experience: [],
        feed_ho_is_spain: [],
        feed_assign_perms: [],
        feed_tax: [],
        flagCommunityCountry: false,
        flagCommunityCountryProfessional: false,
        flagProvinceCommunity: false,
        flagProvinceCommunityProfessional: false,
        flagLocalityProvince: false,
        flagProvinceCountry: false
    },
    mutations: {
        loadElementsConditions(state,payload){
            state.feed_elements_conditions = payload;
        },
        loadLanguagesData(state,payload){
            state.feed_languages_selector = payload;
        },
        loadLanguagesEvaluatorData(state,payload){
            state.feed_languages_evaluator = payload;
        },
        loadProfessionalActivitySectorData(state,payload){
            state.feed_professional_activity_sector = payload;
        },
        loadProfessionalExperienceData(state,payload){
            state.feed_professional_experience = payload;
        },
        loadGradeExperienceData(state,payload){
            state.feed_grade_experience = payload;
        },
        loadProfessionalExperienceSectorData(state,payload){
            state.feed_professional_experience_sector = payload;
        },
        loadSectorsExperienceData(state,payload){
            state.feed_sectors_experience = payload;
        },
        loadFeedExercisesWithoutSubquestions(state,payload){
            state.feed_excerises_no_subquestions = payload;
        },
        loadFeedReferenceThesauros(state,payload){
            state.feed_reference_thesauros = payload;
        },
        loadFeedGroupers(state,payload){
            state.feed_groupers = payload;
        },
        loadFeedGroupersElaborationPending(state,payload){
            state.feed_groupers_elaboration_pending = payload;
        },
        loadLanguages(state, payload){
            state.feed_languages = payload;
        },
        loadExercises(state, payload){
            state.feed_exercises = payload;
        },
        loadPallette(state, payload){
            state.feed_pallette = payload;
        },
        loadFont(state, payload){
            state.feed_font = payload;
        },
        loadSocialReason(state, payload){
            state.feed_social_reason = payload;
        },
        loadCountry(state, payload){
            state.feed_country = payload;
        },
        loadCountryCompanies(state, payload){
            state.feed_country_companies = payload;
        },
        loadNumEmployeesCompanies(state, payload){
            state.feed_num_employees_companies = payload;
        },
        loadActivityCompanies(state, payload){
            state.feed_activity_companies = payload;
        },
        loadActivity(state, payload){
            state.feed_activity = payload;
        },
        loadNumEmployers(state, payload){
            state.feed_num_employers = payload;
        },
        loadFeedTabs(state, payload){
            state.feed_tabs = payload;
        },
        loadPositions(state, payload){
            state.feed_positions = payload;
        },
        loadPerms(state, payload){
            state.feed_perms = payload;
        },
        loadGroups(state, payload){
            state.feed_groups = payload;
        },
        loadProfiles(state, payload){
            state.feed_profiles = payload;
        },
        loadTopic(state, payload){
            state.feed_topic = payload;
        },
        loadProcedures(state, payload){
            state.feed_procedures = payload;
        },
        loadExamples(state, payload){
            state.feed_examples = payload;
        },
        loadBibliography(state, payload){
            state.feed_bibliography = payload;
        },
        loadVideography(state, payload){
            state.feed_videography = payload;
        },
        loadSupportMaterial(state, payload){
            state.feed_support_material = payload;
        },
        loadTreatmentText(state, payload){
            state.data_treatment_text = payload;
        },
        loadType(state, payload){
            state.feed_type = payload;
        },
        loadTypeExercise(state, payload){
            state.feed_type_exercise = payload;
        },
        loadSuppGroups(state, payload){
            state.feed_supp_groups = payload;
        },
        loadTypeExerciseByParent(state, payload){
            state.feed_type_exercise_by_parent = payload;
        },
        loadCondition(state, payload){
            state.feed_condition = payload;
        },
        loadSelectorActivities(state, payload){
            state.feed_selector_activities = payload;
        },
        loadFeedAmbitsCertificatesFiltered(state, payload){
            state.feed_selector_ambits_certificates = payload;
        },
        loadFeedSupportCategory(state,payload){
            state.feed_support_categories = payload
        },
        loadFeedCategoryClub(state,payload){
            state.feed_categories_club = payload
        },
        loadFeedSupportPriority(state,payload){
            state.feed_support_priority = payload
        },
        loadFeedSupportAsigned(state,payload){
            state.feed_support_asigned = payload
        },
        loadFeedSupportWorkflowTasks(state,payload){
            state.feed_support_workflow_tasks = payload
        },
        loadFeedMandatoryTasks(state,payload){
            state.feed_mandatory_task = payload
        },
        loadPriorityIncidence(state,payload){
            state.feed_incidence_priority = payload
        },
        loadCategoryIncidence(state,payload){
            state.feed_incidence_category = payload
        },
        loadAsignedIncidence(state,payload){
            state.feed_incidence_asigned = payload
        },
        loadCompany(state,payload){
            state.feed_company = payload;
        },
        loadOrganizationalUnit(state,payload){
            state.feed_organizational_unit = payload;
        },
        loadLevel(state,payload){
            state.feed_level = payload;
        },
        loadStatePhoneNumber(state,payload){
            state.feed_state_phone_number = payload;
        },
        loadStateClient(state,payload){
            state.feed_state_client = payload;
        },
        loadResponsible(state,payload){
            state.feed_responsible = payload;
        },
        loadFeedSupportWorkflows(state,payload){
            state.feed_support_workflows = payload
        },
        loadPriorityByCategory(state,payload){
            state.feed_priority_by_category = payload;
        },
        loadAssigmentByCategory(state,payload){
            state.feed_assigment_by_category = payload
        },
        loadFeedElements(state,payload){
            state.feed_elements = payload;
        },
        loadResultSearchSupports(state, payload){
            state.result_search_supports = payload;
        },
        loadResultSearchStamps(state, payload){
            state.result_search_stamps = payload;
        },
        loadResultSearchCertificates(state, payload){
            state.result_search_certificates = payload;
        },
        loadResultSearchExercises(state, payload){
            state.result_search_exercises = payload;
        },
        loadResultSearchAssociate(state, payload){
            state.result_search_associate = payload;
        },
        loadResultSearchByType(state, payload){
            state.result_search = payload;
        },
        loadExerciseGroupers(state, payload){
            state.feed_exercise_groupers = payload;
        },
        loadImpartation(state, payload){
            state.feed_impartation = payload;
        },
        loadUsersResponsibleGroup(state, payload){
            state.feed_users_responsible_group = payload;
        },
        loadStamp(state, payload){
            state.feed_stamp = payload;
        },
        loadCertificate(state, payload){
            state.feed_certificate = payload;
        },
        loadTypeAnswers(state, payload){
            state.feed_type_answers = payload;
        },
        loadWeightingElements(state, payload){
            state.feed_weighting_elements = payload;
        },
        loadWeightingGroups(state, payload){
            state.feed_weighting_groups = payload;
        },
        loadEvaluationBasisFeeds(state, payload){
            state.feed_evaluation_basis = payload;
        },
        loadScoreRangeFeeds(state, payload){
            state.feed_score_range = payload;
        },
        loadEvaluationBasisFirstLevelFeeds(state, payload){
            state.feed_evaluation_basis_first_level = payload;
        },
        loadTypeEvaluationFeeds(state, payload){
            state.feed_type_evaluation = payload;
        },
        loadEvaluatorModalityFeeds(state, payload){
            state.feed_evaluator_modality = payload;
        },
        loadChannelFeeds(state, payload){
            state.feed_channel = payload;
        },
        loadFeedCCAA(state, payload){
            state.feed_ccaa = payload;
        },
        loadGroupsTypeCompanyFeeds(state, payload){
            state.feed_groups_type_company = payload;
        },
        loadTreeItinerary(state, payload){
            state.flagTreeItinerary = !state.flagTreeItinerary;
            state.feed_tree_itinerary = payload;
        },
        loadCommunityByCountry(state, payload){
            state.flagCommunityCountry = !state.flagCommunityCountry;
            state.feed_community_by_country = payload;
        },
        loadCommunityByCountryProfessional(state, payload){
            state.flagCommunityCountryProfessional = !state.flagCommunityCountryProfessional;
            state.feed_community_by_country_professional = payload;
        },
        loadProvinceByCommunity(state, payload){
            state.flagProvinceCommunity = !state.flagProvinceCommunity;
            state.feed_province_by_community = payload;
        },
        loadProvinceByCommunityProfessional(state, payload){
            state.flagProvinceCommunityProfessional = !state.flagProvinceCommunityProfessional;
            state.feed_province_by_community_professional = payload;
        },
        loadProvinceByCountry(state, payload){
            state.flagProvinceCountry = !state.flagProvinceCountry;
            state.feed_province_by_country = payload;
        },
        loadLocalityByProvince(state, payload){
            state.flagLocalityProvince = !state.flagLocalityProvince;
            state.feed_locality_by_province = payload;
        },
        loadWeightingGroupItineraryCompany(state, payload){
            state.flagWeightingGroupItineraryCompany = !state.flagWeightingGroupItineraryCompany;
            state.feed_weigthing_group_by_itinerary_company = payload;
        },
        loadPayMethods(state, payload){
            state.feed_pay_methods = payload;
        },
        loadHoIsSpain(state, payload){
            state.feed_ho_is_spain = payload;
        },
        loadAssignPerms(state, payload){
            state.feed_assign_perms = payload;
        },
        loadTax(state, payload){
            state.feed_tax = payload;
        }
    },
    getters: {
        getterTreeItinerary(state) {
            const flagTreeItinerary = state.flagTreeItinerary; // eslint-disable-line no-unused-vars
            return state.feed_tree_itinerary;
        },
        getterCommunityByCountry(state){
            const dummy = state.flagCommunityCountry; // eslint-disable-line no-unused-vars
            return state.feed_community_by_country;
        },
        getterCommunityByCountryProfessional(state){
            const dummy = state.flagCommunityCountryProfessional; // eslint-disable-line no-unused-vars
            return state.feed_community_by_country_professional;
        },
        getterProvinceByCommunity(state){
            const dummy = state.flagProvinceCommunity; // eslint-disable-line no-unused-vars
            return state.feed_province_by_community;
        },
        getterProvinceByCountry(state){
            const dummy = state.flagProvinceCountry; // eslint-disable-line no-unused-vars
            return state.feed_province_by_country;
        },
        getterProvinceByCommunityProfessional(state){
            const dummy = state.flagProvinceCommunityProfessional; // eslint-disable-line no-unused-vars
            return state.feed_province_by_community_professional;
        },
        getterLocalityByProvince(state){
            const dummy = state.flagLocalityProvince; // eslint-disable-line no-unused-vars
            return state.feed_locality_by_province;
        },
        getterWeightingGroupItineraryCompany(state){
            const dummy = state.flagWeightingGroupItineraryCompany; // eslint-disable-line no-unused-vars
            return state.feed_weigthing_group_by_itinerary_company;
        },
    },
    actions: {
        async getWeightingGroupItineraryCompany(state, item){
            await axios.get(
                API_URL + 'feed/weighting-group/' + item.itinerary_uuid + '/' + item.company_uuid
            ).then(
                response => {
                    state.commit('loadWeightingGroupItineraryCompany', response.data.data);
                }
            )
        },
        async getTreeItinerary(state,item){
            let  requestLogin = API_URL + "feed/itinerary/" + item.id;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadTreeItinerary", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPermissionsAssign(state, item){
            let  requestLogin = API_URL + "feed/assign-perms" + (item.agrupator_id !== undefined ? '/' + item.agrupator_id : '');
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadAssignPerms", response.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPayMethods(state){
            let  requestLogin = API_URL + "feed/pay-methods";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadPayMethods", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        resetCommunityByCountry(state){
            state.commit("loadCommunityByCountry", []);
        },
        resetCommunityByCountryProfessional(state){
            state.commit("loadCommunityByCountryProfessional", []);
        },
        resetLocalityByProvince(state){
            state.commit("loadLocalityByProvince", []);
        },
        resetProvinceByCommunity(state){
            state.commit("loadProvinceByCommunity", []);
        },
        resetProvinceByCommunityProfessional(state){
            state.commit("loadProvinceByCommunityProfessional", []);
        },
        resetProvinceByCountry(state){
            state.commit("loadProvinceByCountry", []);
        },
        async getCommunityByCountry(state,item){
            let  requestLogin = API_URL + "feed/community/" + item.country;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    item.from !== undefined && item.from === 'professional' ? state.commit("loadCommunityByCountryProfessional", response.data) : state.commit("loadCommunityByCountry", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getProvinceByCommunity(state,item){
            let  requestLogin = API_URL + "feed/province/" + item.community;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    item.from !== undefined && item.from === 'professional' ? state.commit("loadProvinceByCommunityProfessional", response.data) : state.commit("loadProvinceByCommunity", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getProvinceByCountry(state,item){
            let  requestLogin = API_URL + "feed/country/province/" + item.country;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadProvinceByCountry", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getLocalityByProvince(state,item){
            let  requestLogin = API_URL + "feed/locality/" + item.province;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadLocalityByProvince", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getLanguagesData(state){
            let  requestLogin = API_URL + "feed/languages-selector";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadLanguagesData", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getProfessionalActivitySectorFeed(state){
            let  requestLogin = API_URL + "feed/professional-activity-sector";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadProfessionalActivitySectorData", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getHoIsSpain(state){
            let  requestLogin = API_URL + "feed/country_spain";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadHoIsSpain", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getLanguagesEvaluatorData(state){
            let  requestLogin = API_URL + "feed/languages-evaluator";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadLanguagesEvaluatorData", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getProfessionalExperienceData(state){
            let  requestLogin = API_URL + "feed/professional-experience";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadProfessionalExperienceData", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getGradeExperienceData(state){
            let  requestLogin = API_URL + "evaluator/grade_experience/feed";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadGradeExperienceData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getProfessionalExperienceSectorFeedData(state){
            let  requestLogin = API_URL + "evaluator/professional_experience_sector/feed";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadProfessionalExperienceSectorData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSectorsExperienceData(state){
            let  requestLogin = API_URL + "feed/sectors-experience";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadSectorsExperienceData", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getExerciseGroupers(state,item){
            let  requestLogin = API_URL + "feed/exercise-groupers/" + item.id;
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadExerciseGroupers", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getLanguages(state){
            let  requestLogin = API_URL + "feed/languages";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadLanguages", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedGroupers(state){
            let  requestLogin = API_URL + "feed/groupers";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedGroupers", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedGroupersElaborationPending(state){
            let  requestLogin = API_URL + "feed/groupers/elaboration-pending";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadFeedGroupersElaborationPending", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPallette(state){
            let  requestLogin = API_URL + "feed/pallette";
            await axios({
                method:"GET",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadPallette", response.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFont(state){
            let  requestLogin = API_URL + "feed/font";
            await axios({
                url: requestLogin,
                method:"GET"
            }).then(
                response => {
                    state.commit("loadFont", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSocialReason(state){
            let  requestLogin = API_URL + "feed/social_reason";
            await axios({
                method: "get",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadSocialReason", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCountry(state){
            let  requestLogin = API_URL + "feed/country";
            await axios({
                method: "get",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadCountry", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCountryCompanies(state){
            let  requestLogin = API_URL + "feed/country/companies";
            await axios({
                method: "get",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadCountryCompanies", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getNumEmployeesCompanies(state){
            let  requestLogin = API_URL + "feed/num_employee/companies";
            await axios({
                method: "get",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadNumEmployeesCompanies", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getActivityCompanies(state){
            let  requestLogin = API_URL + "feed/activity/companies";
            await axios({
                method: "get",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadActivityCompanies", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getActivity(state){
            let  requestLogin = API_URL + "feed/activity";
            await axios({
                method:'GET',
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadActivity", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPosition(state){
            let  requestLogin = API_URL + "feed/position";
            await axios({
                method:"GET",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadPositions", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPerms(state){
            let  requestLogin = API_URL + "perms";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadPerms", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getGroups(state){
            let  requestLogin = API_URL + "groups/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadGroups", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getProfiles(state){
            let  requestLogin = API_URL + "profiles/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadProfiles", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedType(state){
            let  requestLogin = API_URL + "subtype-exercise/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadType", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTypeExercise(state){
            let  requestLogin = API_URL + "type-exercises/feed";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadTypeExercise", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedSuppGroups(state){
            let  requestLogin = API_URL + "support/groups/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadSuppGroups", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedCondition(state){
            let  requestLogin = API_URL + "condition/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadCondition", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTopic(state){
            let  requestLogin = API_URL + "topic/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    if(response);
                    state.commit("loadTopic", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedProcedures(state, item){
            let  requestLogin = API_URL + "procedure/feed"; // Review 3ul3r
            return await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadProcedures", response.data.data);

                    if(item.returned){
                        return response.data.data;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedExercises(state){
            let  requestLogin = API_URL + "feed/exercises";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadExercises", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getElementsConditions(state, item){
            let request = API_URL + 'grouper/conditional-elements';

            let formData = new FormData();
            formData.append('conditional[id]', item.id);
            formData.append('conditional[item_id]', item.item_id);
            formData.append('conditional[element_id]', item.element_id);
            formData.append('conditional[grouper_id]', item.grouper_id);

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadElementsConditions", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedImpartation(state){
            let  requestLogin = API_URL + "feed/impartation";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadImpartation", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedWorkflow(state){
            let  requestLogin = API_URL + "support/workflow/feeds";
            await axios.get(
                requestLogin
            ).then(
                response => {
                    state.commit('loadFeedSupportWorkflows', response.data.data);
                }
            )
        },
        async getFeedExamples(state, item){
            let  requestLogin = API_URL + "example/feed"; // Review 3ul3r
            return await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadExamples", response.data.data);

                    if(item.returned){
                        return response.data.data;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedBibliography(state){
            let  requestLogin = API_URL + "bibliography/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadBibliography", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedVideography(state){
            let  requestLogin = API_URL + "videography/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadVideography", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSelectorActivities(state){
            let  requestLogin = API_URL + "feed/creation/activities";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadSelectorActivities", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedSupportMaterial(state){
            let  requestLogin = API_URL + "support-material/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadSupportMaterial", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getTreatmentText(state){
            let  requestLogin = API_URL + "data_treatment_text";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadTreatmentText", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getNumEmployers(state){
            let  requestLogin = API_URL + "feed/num_employee";
            await axios({
                method:"GET",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadNumEmployers", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTabs(state){
            let  requestLogin = API_URL + "feed_tabs";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    console.log(response.data.data)
                    state.commit("loadFeedTabs", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedAmbitsCertificatesFiltered(state,item){
            let  requestLogin = API_URL + "feed/creation/scope_certificate";
            let formData = new FormData();
            let keys_array = Object.keys(item.topic);

            for(var i=0;i<keys_array.length;i++){
                if(keys_array[i] !== undefined){
                    formData.append('itinerary[topic][]', keys_array[i]);
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
            }).then(
                response => {
                    state.commit("loadFeedAmbitsCertificatesFiltered", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getExecutionUserSearch(state, item){
            let requestLogin = API_URL + 'users/search/execution';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getLeaderUserSearch(state, item){
            let requestLogin = API_URL + 'users/search/leader';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getRevisionUserSearch(state, item){
            let requestLogin = API_URL + 'users/search/revision';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getValidationUserSearch(state, item){
            let requestLogin = API_URL + 'users/search/validation';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getUserSearch(state, item){
            let requestLogin = API_URL + 'responsible/feed'; // Review 3ul3r
            // let requestLogin = API_URL + 'users/group/1/company/1'; // Review 3ul3r
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getUserSearchByGroupAndCompany(state, item){
            let company_id = document.getElementById('assign_company_search').value;
            let group_id = document.getElementById('assign_group_search').value;
            let requestLogin = API_URL + 'user/group/' + group_id + '/company/' + company_id;
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getUserSupervisedEvaluated(state, item){
            let request = API_URL + 'users/search/supervised_evaluated'

            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: 'get',
                url: request,
                params: params
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getBusinessSearch(state, item){
            let requestLogin = API_URL + 'business/search';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedSupportCategory(state){
            await axios.get(
                API_URL + "support/categories/feeds"
            ).then(
                response => {
                    state.commit('loadFeedSupportCategory', response.data.data);
                }
            )
        },
        async getFeedCategoryClub(state){
            await axios.get(
                API_URL + "evaluator/category_club/feed"
            ).then(
                response => {
                    state.commit('loadFeedCategoryClub', response.data.data);
                }
            )
        },
        async getFeedSupportPriority(state){
            await axios.get(
                API_URL + "support/priorities/feeds"
            ).then(
                response => {
                    state.commit('loadFeedSupportPriority', response.data.data);
                }
            )
        },
        async getFeedSupportAsigned(state){
            await axios.get(
                API_URL + "support/groups/tickets-feeds"
            ).then(
                response => {
                    state.commit('loadFeedSupportAsigned', response.data.data);
                }
            )
        },
        async getFeedMandatoryTaks(state){
            await axios.get(
                API_URL + "support/workflow/mandatory-tasks/feeds"
            ).then(
                response => {
                    state.commit('loadFeedMandatoryTaks', response.data.data);
                }
            )
        },
        async getFeedSupportWorkflowTasks(state,item){
            await axios.get(
                API_URL + "support/workflow/tasks/feeds" + (item.id !== undefined ? "/" + item.id : "")
            ).then(
                response => {
                    state.commit('loadFeedSupportWorkflowTasks', response.data.data);
                }
            )
        },
        async getFeedMandatoryTasks(state){
            await axios.get(
                API_URL + "support/workflow/mandatory-tasks/feeds"
            ).then(
                response => {
                    state.commit('loadFeedMandatoryTasks', response.data.data);
                }
            )
        },
        async getCompany(state){
            await axios.get(
                API_URL + "feed_company"
            ).then(
                response => {
                    state.commit('loadCompany', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getOrganizationalUnit(state){
            await axios.get(
                API_URL + "feed_organizational_unit"
            ).then(
                response => {
                    state.commit('loadOrganizationalUnit', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedLevel(state){
            await axios.get(
                API_URL + 'feed_level'
            ).then(
                response => {
                    state.commit('loadLevel', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getStatePhoneNumber(state){
            await axios.get(
                API_URL + "commercial-management/actions-types"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadStatePhoneNumber', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getStateClient(state){
            await axios.get(
                API_URL + "commercial-management/company-states/feeds"
            ).then(
                response => {
                    state.commit('loadStateClient', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedResponsibles(state){
            await axios.get(
                API_URL + "responsible/feed"
            ).then(
                response => {
                    state.commit('loadResponsible', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedExercisesWithoutSubquestions(state){
            await axios.get(
                API_URL + "exercises/no_subquestions/feed"
            ).then(
                response => {
                    state.commit('loadFeedExercisesWithoutSubquestions', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedReferenceThesauros(state){
            await axios.get(
                API_URL + "reference/thesauros/feeds"
            ).then(
                response => {
                    state.commit('loadFeedReferenceThesauros', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPriorityByCategory(state,item){
            await axios.get(
                API_URL + "support/priority/category/" + item.category, {params:{'ticket_uuid' : item.uuid}}
            ).then(
                response => {
                    state.commit('loadPriorityByCategory', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getAssigmentByCategory(state,item){
            await axios.get(
                API_URL + "support/groups/category/" + item.category, {
                    params : {'ticket_uuid' : item.id}
                }
            ).then(
                response => {
                    state.commit('loadAssigmentByCategory', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        getTypeExerciseByParent(state, item){
            var params = [];
            state.state.feed_type_exercise.forEach((props) => {
                var newProp = [];
                if(props.value != item.id){
                    newProp = {...props};
                    params.push(newProp);
                    if(props.children !== undefined){
                        props.children.forEach(function(i, k){
                            if(i.value == item.id){
                                params[params.length - 1].children.splice(k, 1);
                            }
                        });
                    }

                }
            });

            state.commit('loadTypeExerciseByParent', params);
            state.dispatch('getFeedTypeExercise');

        },
        async getFeedElements(state){
            await axios.get(
                API_URL + "elements/feed"
            ).then(
                response => {
                    state.commit('loadFeedElements', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedUsersResponsibleGroup(state){
            await axios.get(
                API_URL + "users/group/responsible/feed"
            ).then(
                response => {
                    state.commit('loadUsersResponsibleGroup', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedStamp(state){
            await axios.get(
                API_URL + "seal/feed"
            ).then(
                response => {
                    state.commit('loadStamp', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedCertificate(state){
            await axios.get(
                API_URL + "certificates/feed"
            ).then(
                response => {
                    state.commit('loadCertificate', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTypeAnswers(state){
            await axios.get(
                API_URL + "type-answers/feed"
            ).then(
                response => {
                    state.commit('loadTypeAnswers', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedWeightingElements(state){
            await axios.get(
                API_URL + "weighting-element/feed"
            ).then(
                response => {
                    state.commit('loadWeightingElements', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedWeightingGroups(state){
            await axios.get(
                API_URL + "weighting-group/feed"
            ).then(
                response => {
                    state.commit('loadWeightingGroups', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedEvaluationBasis(state){
            await axios.get(
                API_URL + "base-evaluations/feed"
            ).then(
                response => {
                    state.commit('loadEvaluationBasisFeeds', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedScoreRange(state){
            await axios.get(
                API_URL + "evaluator/score_range/feed"
            ).then(
                response => {
                    state.commit('loadScoreRangeFeeds', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedEvaluationBasisOnlyFirstLevel(state){
            await axios.get(
                API_URL + "base-evaluations/first-level/feed"
            ).then(
                response => {
                    state.commit('loadEvaluationBasisFirstLevelFeeds', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTypeEvaluation(state){
            await axios.get(
                API_URL + "evaluator/type_evaluation/feed"
            ).then(
                response => {
                    state.commit('loadTypeEvaluationFeeds', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedEvaluatorModality(state){
            await axios.get(
                API_URL + "evaluator/evaluator_modality/feed"
            ).then(
                response => {
                    state.commit('loadEvaluatorModalityFeeds', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedChannel(state){
            await axios.get(
                API_URL + "evaluator/canal/feed"
            ).then(
                response => {
                    state.commit('loadChannelFeeds', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedCCAA(state){
            await axios.get(
                API_URL + "feed/ccaa"
            ).then(
                response => {
                    state.commit('loadFeedCCAA', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedGroupsTypeCompany(state){
            await axios.get(
                API_URL + "groups/feeds"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadGroupsTypeCompanyFeeds', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTax(state){
            await axios.get(
                API_URL + "feed/tax"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadTax', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchSupports(state, item){
            let params = {};

            var count = 0;
            item.formData.forEach((item) => {
                let _key = item[0];
                if(_key === 'search_supports[topic][]'){
                    params['search_supports[topic][' + count + ']'] = item[1];
                    count++;
                }else {
                    params[_key] = item[1];
                }
            });

            await axios({
                method: "get",
                url: API_URL + "base-creation/support-materials/search",
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchSupports", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchStamps(state, item){
            let params = {};

            var count = 0;
            item.formData.forEach((item) => {
                let _key = item[0];
                if(_key === 'search_format[topic][]'){
                    params['search_format[topic][' + count + ']'] = item[1];
                    count++;
                }else {
                    params[_key] = item[1];
                }
            });

            await axios({
                method: "get",
                url: API_URL + "stamp/search",
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchStamps", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchCertificates(state, item){
            let params = {};

            var count = 0;
            item.formData.forEach((item) => {
                let _key = item[0];
                if(_key === 'search_format[topic][]'){
                    params['search_format[topic][' + count + ']'] = item[1];
                    count++;
                }else {
                    params[_key] = item[1];
                }
            });

            await axios({
                method: "get",
                url: API_URL + "certificate/search",
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchCertificates", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchExercises(state, item){
            let params = {};

            var count_topic = 0;
            var count_exercise = 0;
            item.formData.forEach((item) => {
                let _key = item[0];
                if(_key === 'search_exercises[topic][]'){
                    params['search_exercises[topic][' + count_topic + ']'] = item[1];
                    count_topic++;
                }else if(_key === 'search_exercises[type_of_exercise][]'){
                    params['search_exercises[type_of_exercise][' + count_exercise + ']'] = item[1];
                    count_exercise++;
                }else{
                    params[_key] = item[1];
                }
            });

            await axios({
                method: "get",
                url: API_URL + "base-creation/exercises/search",
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchExercises", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchAssociate(state, item){
            let params = {};

            var count_topic = 0;
            var count_exercise = 0;
            var count_type = 0;
            item.formData.forEach((item) => {
                let _key = item[0];
                if(_key === 'search_associate[type_of_exercise][]'){
                    params['search_associate[type_of_exercise][' + count_type + ']'] = item[1];
                    count_type++;
                }else if(_key === 'search_associate[groupers][]'){
                    params['search_associate[groupers][' + count_exercise + ']'] = item[1];
                    count_exercise++;
                }else if(_key === 'search_associate[topics][]'){
                    params['search_associate[topics][' + count_topic + ']'] = item[1];
                    count_topic++;
                }else if(_key === 'search_associate[exercises][]'){
                    params['search_associate[exercises][' + count_exercise + ']'] = item[1];
                    count_exercise++;
                }else if(_key === 'search_associate[thesauros][]'){
                    params['search_associate[thesauros][' + count_exercise + ']'] = item[1];
                    count_exercise++;
                }else{
                    params[_key] = item[1];
                }
            });

            await axios({
                method: "get",
                url: API_URL + "creation/associate/search",
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchAssociate", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected) == true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchUsersLeader(state, item){
            let params = {};

            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            await axios({
                method: "get",
                url: API_URL + "search/users/tutorship",
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchByType", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected) == true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
