var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window",staticStyle:{"padding-bottom":"20px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'section_clients',
            title: _vm.$t('clients') + ' ' + (_vm.showTable ? _vm.getterRealListClientsIndex[_vm.actualOpenKey].name : ''),
            num_results: '',
            label: _vm.showTable ? _vm.getterRealListClientsIndex[_vm.actualOpenKey].count + ' ' + _vm.$t('results') : ''
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?[(!_vm.showTable)?[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticStyle:{"display":"flex"}},_vm._l((_vm.getterListClientsIndex),function(column,columnIndex){return _c('div',{key:columnIndex,staticStyle:{"display":"block","width":"33.33%"}},_vm._l((column),function(client,clientIndex){return _c('div',{key:'B' + columnIndex + '-' + clientIndex,class:clientIndex % 2 !== 0 ? 'striped' : 'striped_white',staticStyle:{"padding-bottom":"2px","padding-top":"2px","padding-left":"5px","padding-right":"5px"}},[(client)?_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"definition-field_header-text T14"},[_vm._v(_vm._s(client.name + ' ' + '(' + _vm.$t('num') + ' ' + client.count + ')'))]),_c('div',{staticClass:"link T14",on:{"click":function($event){return _vm.loadDataClient(client)}}},[_c('div',{staticClass:"definition-field-icon-open"},[_vm._v(_vm._s('[ + ]'))])])]):_c('div',{staticStyle:{"display":"flex","height":"17px"}})])}),0)}),0)])]:[_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                            id: 'table_clients'
                        },"header":_vm.header,"data":_vm.getterListClients},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [(labelProp === 'client')?[_c('router-link',{attrs:{"to":{name: 'client_id', params: {
                                    id: itemProp.id
                                }}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(_vm._s(itemProp[labelProp]))])])]:[_c('GhChecks',{ref:'refPartnerClub' + itemProp.id,attrs:{"datainput":{
                                        id: 'partner_club_' + itemProp.id,
                                        value: 1,
                                        type: 'checkbox',
                                        name: 'partner_club[' + itemProp.id + ']',
                                        selected: itemProp[labelProp],
                                        disabled: false
                                    },"datalabel":{class: '', id: '', text: ''}},on:{"click":function($event){return _vm.setPartnerClub('refPartnerClub' + itemProp.id, itemProp.id)}}})]]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{
                                name: 'client_id',
                                params: {id: itemProp.id}
                            }}},[_c('GhAction',{attrs:{"dataction":{
                                        id: 'view_client',
                                        text: _vm.$t('view'),
                                        icon: require('../../assets/gh_new_dessign/view.svg')
                                    }}})],1)]}}],null,false,3641382247)})]]:[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]]},proxy:true},{key:"actions",fn:function(){return [(_vm.showTable)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'back_index',
                        class: _vm.getterRealListClientsIndex[_vm.actualOpenKey - 1] !== undefined ? '' : 'disabled',
                        text: _vm.getterRealListClientsIndex[_vm.actualOpenKey - 1] !== undefined ? _vm.getterRealListClientsIndex[_vm.actualOpenKey - 1].name : _vm.getterRealListClientsIndex[0].name,
                    }},on:{"click":function($event){return _vm.changeIndex('back')}}}),_c('label',{staticClass:"gh_text_field-label T14"},[_vm._v(_vm._s('|'))]),_c('GhAction',{attrs:{"dataction":{
                        id: 'next_index',
                        class: _vm.getterRealListClientsIndex[_vm.actualOpenKey + 1] !== undefined ? '' : 'disabled',
                        text: _vm.getterRealListClientsIndex[_vm.actualOpenKey + 1] !== undefined ? _vm.getterRealListClientsIndex[_vm.actualOpenKey + 1].name : _vm.getterRealListClientsIndex[_vm.getterRealListClientsIndex.length - 1].name,
                    }},on:{"click":function($event){return _vm.changeIndex('next')}}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px","margin-bottom":"20px"}},[(_vm.showTable)?[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_back',
                    text: _vm.$t('back'),
                }},on:{"click":function($event){_vm.showTable = false; _vm.actualOpenKey = null;}}})]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }