<template>
    <div class="float_window" style="padding-bottom: 20px;">
        <GhDropSelector v-if="show.company_file"
            :datablock="{
                id: 'list_business',
                title: $t('date_company'),
                second_title: '',
                num_results: ''
            }"
        >
            <template v-slot:content v-if="haveData">
                <template v-if="view">
                    <ViewBusiness/>
                </template>
                <template v-if="!view">
                    <EditBusiness/>
                </template>
            </template>
            <template v-slot:actions v-if="view">
                <GhAction
                    :dataction="{
                        id: 'modify_business',
                        text: $t('modify'),
                        icon: require('../../assets/gh_new_dessign/edit.svg'),
                    }"
                    @click="changeState"
                />
            </template>
        </GhDropSelector>

        <component v-bind:is="components.list_users" v-if="show.users"/>
        <component v-bind:is="components.list_around" v-if="show.environment_personalization"/>

    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhAction from 'fe-gh-action-lib';
    import GhButton from "fe-gh-button-lib";
    import ViewBusiness from './template/view';
    import EditBusiness from './template/edit';

    import {mapGetters, mapState} from "vuex";

    import list_users from "@/view/users/list_users.vue";
    import list_around from "@/view/config/around/list_around.vue";

    export default {
        name: "list_business",
        components: {
            GhDropSelector,
            GhAction,
            GhButton,
            ViewBusiness,
            EditBusiness
        },
        computed:{
            ...mapState(['Login']),
            ...mapGetters(['getterCompanyData'])
        },
        data(){
            return {
                show: {
                    company_file: false,
                    users: false,
                    environment_personalization: false
                },
                view: true,
                haveData: false,
                components: {
                    list_users: list_users,
                    list_around: list_around
                }
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getBusinessData', {}, {root: true});
                await this.$store.dispatch('getSocialReason', '', {root: true});
                await this.$store.dispatch('getCountry', '', {root: true});
                if(this.getterCompanyData !== undefined && this.getterCompanyData.country !== undefined){
                    await this.$store.dispatch('getProvinceByCountry', {
                        country: Object.keys(this.getterCompanyData.country)[0],
                    }, {root: true});
                }
                await this.$store.dispatch('getActivity', '', {root: true});
                await this.$store.dispatch('getPosition', '', {root: true});
                await this.$store.dispatch('getNumEmployers', '', {root: true});

                if(this.Login.tabs.length !== 0) {
                    this.Login.tabs[2]['sub-modules'].forEach((subroute) => {
                        if(this.show[subroute.name] !== undefined) {
                            this.show[subroute.name] = subroute.have_permission;
                        }
                    });
                }

                this.haveData = true;
            }
        },
        methods:{
            changeState(){
                this.view = !this.view;
            },
            async modifyBusiness(){
                if(this.Global.checkRequired('form_business') == 'OK') {
                    const formData = new FormData(document.getElementById('form_business'));
                    this.$store.dispatch('setBusinessData', {formData: [...formData]},{root:true});
                    this.changeState();
                }
            }
        }
    }
</script>

<style>
#company_province_data_selector_popup > div {
    overflow: hidden auto !important;
}
</style>