<template>
    <div>
        <template v-if="haveData">
            <div v-for="(label, labelIndex) in data" :key="'KEY1' + labelIndex">
                <GhDropSelector v-if="CreationGrouper.counters[label['result']].have_permission"
                    :datablock="{
                        id: 'grouper_' + label.id,
                        title: $t(label['name']),
                        second_title: $t(label['second_text']),
                        num_results: CreationGrouper.counters[label['result']].counter,
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="label.component" :id_grouper="id_grouper" />
                    </template>
                </GhDropSelector>
            </div>
        </template>
        <template v-else-if="!haveData">
            <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
            </div>
        </template>
    </div>
</template>

<script>
    import list_elaboration from "@/view/creation/templates/lists/list_elaboration.vue";
    import list_pending from "@/view/creation/templates/lists/list_pending.vue";
    import list_enabled from "@/view/creation/templates/lists/list_enabled.vue";
    import list_historic from "@/view/creation/templates/lists/list_historic.vue";
    import GhDropSelector from "fe-gh-dropselector-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_groupers",
        components: {
            GhDropSelector
        },
        computed:{
            ...mapState(['CreationGrouper','Login','TabCreation']),
        },
        props:['id_grouper'],
        data(){
            return {
                data: [
                    {id: 1, name: 'in_elaboration', component: list_elaboration, result: 'elaboration', second_text: 'results'},
                    {id: 2, name: 'pending_activate', component: list_pending, result: 'pending', second_text: 'results'},
                    {id: 3, name: 'enabled', component: list_enabled, result: 'enabled', second_text: 'results'},
                    {id: 4, name: 'historic', component: list_historic, result: 'historic', second_text: 'results'},
                ],
                haveData: false
            }
        },
        watch:{
            async 'TabCreation.actual_grouper'(){
                await this.$store.dispatch('getCountersByGrouper',{id: this.id_grouper},{root:true});
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getCountersByGrouper', {id: this.id_grouper}, {root: true});
                this.haveData = true;
            }
        }
    }
</script>