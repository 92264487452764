<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'general'+label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: PermsGroup.counters[label['result']],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapState} from "vuex";

    import Groups from './groups/list_groups.vue';
    import Profiles from './profiles/list_profiles.vue';

    export default {
        name: 'list_groups',
        components: {
            GhDropSelector
        },
        computed: {
            ...mapState(['Login', 'PermsGroup'])
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'groups', component: Groups, result: 'groups', show: false , second_text: 'results'},
                    {id: 2, name: 'profiles', component: Profiles, result: 'profiles', show: false , second_text: 'results'}
                ],
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getPermsGroups', '', {root: true});
            }

            this.haveData = true;

            if(this.Login.tabs.length !== 0){
                this.Login.tabs[3]['sub-modules'][0]['sub-sections'].forEach((subroute,subkey) => {
                    if(this.data[subkey] !== undefined && this.data[subkey].name === subroute.name) {
                        this.data[subkey].show = subroute.have_permission;
                    }
                });
            }
        },
    }
</script>