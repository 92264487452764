<template>
    <div style="margin-top: -3px;" :style="read ? 'margin-left: 38px; margin-right: 20px;' : ''">
        <template v-if="read">
            <template v-for="(itemDoneEvaluation, indexDoneEvaluation) in getterQuestionnaireRate.evaluation">
                <div :key="'ROW_EVALUATED' + indexDoneEvaluation" style="width: 100%;">
                    <template v-for="(itemE, indexE) in itemDoneEvaluation.childrens">
                        <div class="gh-row-content" :key="'INDEXELEMENT' + indexE" style="width: 100%">
                            <div>
                                <div style="width: 100%;">
                                    <label class="gh_text_field-label T-subtitle-second" style="margin-right: 4px;">{{itemDoneEvaluation.name + ': ' + ' '}}</label>
                                    <label v-for="(itemEvaluation, indexEvaluation) in itemE.name" :key="'EVALUATED' + indexDoneEvaluation + indexEvaluation" :class="indexEvaluation === 0 ? 'gh_text_field-label T-subtitle-second' : 'T-text'" :style="indexEvaluation !== 0 ? 'padding-top: 2px;' : ''">
                                        {{itemEvaluation + (indexEvaluation === 0 ? '' : ';')}}
                                    </label>
                                </div>
                                <div style="width: 100px">
                                    <label class="gh_text_field-label T-subtitle-second" style="float: right;"> {{itemE.punctuation}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="gh-row-content" :key="'INDEXELEMENT2' + indexE" style="width: 100%; margin-top: -5px;" v-if="itemE.result_description !== ''">
                            <div>
                                <div style="width: 100%;">
                                    <label class="gh_text_field-label T-text" style="margin-right: 4px;">{{itemE.result_description}}</label>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </template>
        <template v-else>
            <GhLocation
                :dataLocation="{
                    showButton: Login.perm_support,
                    secondWindow: true,
                    showLoader: haveData ? 'OK' : '',
                    text: getterQuestionnaireRate.name,
                    img: require('@/assets/gh_new_dessign/floating.svg')
                }"
                :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
                @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
            />

            <div class="gh-content-global-questionnaire">
                <template v-if="$root._route.params.complete !== undefined && $root._route.params.complete !== null">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T-subtitle-second">{{$t('evaluation_realized_by') + ' ' + getterQuestionnaireRate.user_evaluator}}</label>
                        </div>
                    </div>
                </template>
                <template v-if="$root._route.params.complete == 'true' || ($root._route.params.complete === undefined || $root._route.params.complete === null)">
                    <div v-for="(itemRate, indexRate) in getterQuestionnaireRate.evaluation" :key="'SECTION_RATE' + indexRate">
                        <template v-if="(itemRate.current && !itemRate.finished) || (!itemRate.current && itemRate.finished)">
                            <div class="question-body" :style="indexRate !== 0 ? 'margin-top: -20px' : ''">
                                <div class="gh-statement-content T15">
                                    <div class="T-subtitle" v-html="itemRate.name" style="margin-left: 10px; margin-top: 2px;"></div>
                                </div>
                                <div class="separator-line" style="margin-top: unset;"></div>
                                <component :is="component.evaluation" :actualKey="indexRate" :parentId="itemRate.id" :key="'REFRESHER' + refreshComponent"></component>
                            </div>
                        </template>
                    </div>
                </template>
                <div class="centered" style="margin-top: -10px;">
                    <GhButton
                        style="margin-bottom: 20px; margin-top: -10px;"
                        class="button-questionnaire-secondary"
                        :datainput="{
                            id: 'btn_close_rate_questionnaire',
                            text: $t('close')
                        }"
                        :title="$t('close')"
                        @click="Global.windowClose()"
                    />
                    <template v-if="$root._route.params.complete === undefined || $root._route.params.complete === null">
                        <GhButton
                            style="margin-bottom: 20px; margin-top: -10px;"
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_reset_rate_questionnaire',
                                text: $t('reset')
                            }"
                            :disabled="!getterQuestionnaireRate.allow.reset"
                            :title="!getterQuestionnaireRate.allow.reset ? $t('no_element_reset') : $t('reset')"
                            @click="setReset()"
                        />
                        <template v-if="getterQuestionnaireRate.evaluation_finished">
                            <GhButton
                                style="margin-bottom: 20px; margin-top: -10px;"
                                class="button-questionnaire-primary"
                                :datainput="{
                                    id: 'btn_finish_rate_questionnaire',
                                    text: $t('finish')
                                }"
                                :title="$t('finish')"
                                @click="finishRate()"
                            />
                        </template>
                    </template>
                </div>
            </div>

            <component :is="component.confirmPopup" :text="textPopup" @accept="acceptReset()"/>
        </template>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";

    import evaluation from '../template/evaluate.vue';
    import confirmPopup from '../popup/confirm.vue';

    export default {
        name: 'view_evaluate',
        props: ['read'],
        components: {
            GhLocation,
            GhButton
        },
        computed: {
            ...mapState(['Login']),
            ...mapGetters(['getterQuestionnaireRate'])
        },
        data(){
            return {
                haveData: false,
                disabledSave: true,
                refreshComponent: 0,
                textPopup: 'reset_evaluate_text',
                component: {
                    confirmPopup: confirmPopup,
                    evaluation: evaluation
                }
            }
        },
        async beforeMount(){
            if(!this.read || this.read === undefined) {
                if (this.$root._route.params.user_id !== undefined && this.$root._route.params.user_id !== '') {
                    await this.$store.dispatch('getRateByUser', {
                        id: this.$root._route.params.id,
                        actual_id: this.$root._route.params.actual_id,
                        user_id: this.$root._route.params.user_id,
                        type: this.$root._route.params.type,
                        extra_id: this.$root._route.params.extra_id
                    }, {root: true}).then((response) => {
                        if (response.status === 200) {
                            this.haveData = true;
                            this.refreshComponent++;
                        }
                    });
                } else {
                    await this.$store.dispatch('getStartRate', {
                        id: this.$root._route.params.id,
                        actual_id: this.$root._route.params.actual_id,
                        type: this.$root._route.params.type,
                        extra_id: this.$root._route.params.extra_id
                    }, {root: true}).then((response) => {
                        if (response.status === 200) {
                            this.haveData = true;
                            this.refreshComponent++;
                        }
                    });
                }
            }
        },
        methods: {
            setReset(){
                this.Global.openPopUp('popup_confirm');
            },
            async acceptReset(){
                await this.$store.dispatch('setResetRate', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true}).then(async (response) => {
                    if(response.status === 200){
                        await this.$store.dispatch('getStartRate', {
                            id: this.$root._route.params.id,
                            actual_id: this.$root._route.params.actual_id,
                            type: this.$root._route.params.type,
                            extra_id: this.$root._route.params.extra_id
                        }, {root: true}).then((response) => {
                            if(response.status === 200){
                                this.haveData = true;
                                this.refreshComponent++;
                            }
                        });

                        this.Global.closePopUp('popup_confirm');
                    }
                });
            },
            async finishRate(){
                await this.$store.dispatch('setStartRate', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type,
                    type_action: 'finish',
                    extra_id: this.$root._route.params.extra_id
                }, {root: true}).then(async (response) => {
                    if (response.status === 200) {
                        this.Global.windowClose();
                    }
                });
            }
        }
    }
</script>