var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_weighting',
        title: _vm.show ? _vm.$t('assing_weighting_value') : _vm.$t('assing_weighting_to_program'),
        type: _vm.show ? 'info' : 'add',
        style: 'width: 40%; min-width: 940px'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;max-height: 40vh; min-height: 2vh; overflow-y: auto !important;',
        text: _vm.$t(_vm.show ? 'assing_weighting_value_continue' : 'assing_weighting_to_program_continue') + (_vm.show ? '' : ': ' + _vm.getterInfoAgrupator.title)
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{key:'REFRESHER_ASSIGN' + _vm.refresher + _vm.refreshPonderation,attrs:{"id":"form_weighting"}},[_c('div',{staticClass:"gh-content-global"},[(!_vm.show)?[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('ponderation_accepted_values') + '.'))])])]:_vm._e(),_vm._l((_vm.getterWeightingElementAgrupator),function(program,keyProgram){return _c('div',{key:'PROGRAM' + keyProgram,staticStyle:{"width":"100%"},style:(keyProgram % 2 ? 'background-color:  var(--pallette-three) !important' : '')},[_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"10px"}},[(!_vm.show)?[_c('GhInputTextField',{ref:'refWeighting' + keyProgram,refInFor:true,attrs:{"datalabel":{
                                    text: '',
                                    style: 'width: 160px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'assignation_base_associated_weighting_groups_' + keyProgram,
                                    name: 'weighting[' + keyProgram + ']',
                                    style: 'min-width: 40px; width: 40px',
                                    value: _vm.propDataWeighting[keyProgram] !== undefined && _vm.propDataWeighting[keyProgram].value !== undefined && _vm.propDataWeighting[keyProgram].value !== '' ? _vm.propDataWeighting[keyProgram].value : '',
                                    type: 'int',
                                    limitNumMax: 40,
                                    label_required: program.name + '*'
                                }},on:{"focusout":function($event){return _vm.controlWeighting('refWeighting' + keyProgram, keyProgram)}}})]:_vm._e(),(_vm.show)?_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"20px"}},[_vm._v(_vm._s(_vm.propDataWeighting[keyProgram] !== undefined && _vm.propDataWeighting[keyProgram].value !== undefined && _vm.propDataWeighting[keyProgram].value !== '' ? _vm.propDataWeighting[keyProgram].value : ''))]):_vm._e(),_c('label',{staticClass:"gh_text_field-label",class:_vm.show ? 'T15' : 'T15_b',style:(_vm.show ? '' : 'margin-left: 10px;')},[_vm._v(_vm._s(program.name + (_vm.show ? '' : '*')))])],2)])}),(!_vm.show)?[_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"10px"}},[_c('label',{staticClass:"gh_text_field-label T15_b"},[_c('span',{style:(_vm.sumPonderation === 100 ? '' : 'color: red')},[_vm._v(_vm._s(_vm.sumPonderation))]),_vm._v(" "+_vm._s(' ' + _vm.$t('of') + ' ' + 100)+" ")])])]:_vm._e()],2)])]},proxy:true},{key:"footer",fn:function(){return [(!_vm.show)?[_c('GhButton',{attrs:{"datainput":{
                    id: 'button_accept_assign',
                    text: _vm.$t('accept'),
                    class: 'T19 container-md_button_content ' + (_vm.sumPonderation === 100 ? '': 'disabled'),
                    style: ' display: flex;'
                }},on:{"click":_vm.setWeighting}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'button_close_assign',
                text: _vm.$t('close'),
                class: _vm.show ? 'button-questionnaire-secondary' : 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_weighting');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }