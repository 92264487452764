import Vue from "vue";
import vuex from "vuex";
import router from "@/routes/router";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        subTabActive: '',
        nameSubTabActive:'',
        idSubTabActive:'',
        actual_grouper:'',
        info: [],
        dependency: null,
        data_dependent: [],
        temp_id: null,
        reload: 0
    },
    mutations: {
        loadSubActiveItem(state, payload) {
            state.subTabActive = payload
        },
        loadNameSubActiveItem(state,payload){
            state.nameSubTabActive = payload
        },
        loadNameSubTabIdActive(state,payload){
            state.idSubTabActive = payload
        },
        loadInfoByTypeAndID(state, payload){
            state.info = payload;
        },
        loadRemoveDependency(state, payload){
            state.dependency = payload
        },
        loadDataDependent(state,payload){
            state.data_dependent = payload
        },
        loadDataTemp(state,payload){
            state.temp_id = payload
        },
        loadActualGrouper(state,payload){
            state.actual_grouper = payload
        },
        loadReloadTab(state){
            state.reload++;
        }
    },
    actions: {
        async getCreationTabActive(state, item) {
            var valRouter = await router.options.routes.find((i) => (item.call == i.name && item.call)  || i.name == item.value);

            state.commit("loadSubActiveItem", valRouter.component != '' ? valRouter.component : '');
            state.commit("loadNameSubActiveItem", valRouter.name != '' ? valRouter.name : '');
            state.commit("loadReloadTab");

            state.commit("loadNameSubTabIdActive", item.id);
            state.commit("loadActualGrouper", item.value);
        },
        async getGroupersTabs(state, item){
            await axios({
                url: API_URL + 'groupers/' + (item.search !== undefined && item.search ? 'search/' : '') + 'tabs'
            }).then(
                async response => {
                    router.options.GhSubTabsCreation = [];

                    router.options.GhSubTabsSearch = [];

                    response.data.data.forEach((item) => {
                        if(item.value !== 'Ejercicios') {
                            var params = {
                                id: item.id,
                                value: item.value,
                                call: 'list_grouper',
                            }
                            router.options.GhSubTabsCreation.push(params);
                        }

                        var params_search = {
                            id: item.id,
                            value: item.value,
                            call: 'search_groupers',
                        }
                        router.options.GhSubTabsSearch.push(params_search);
                    });
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeActivity(state,item){
            for(var i=0;i<state.state.listActivities.length;i++){
                if(state.state.listActivities[i].id == item.id){
                    delete state.state.listActivities[i];
                }
            }
            state.state.listActivities = state.state.listActivities.filter(function (item) {
                return item !== undefined;
            });

            let requestLogin = API_URL + 'creation/activities/'+item.id+'/delete/';

            await axios({
                method: "DELETE",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadlistItineraries", response.data.data);
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeAmbit(state,item){
            for(var i=0;i<state.state.listAmbits.length;i++){
                if(state.state.listAmbits[i].id == item.id){
                    delete state.state.listAmbits[i];
                }
            }
            state.state.listAmbits = state.state.listAmbits.filter(function (item) {
                return item !== undefined;
            });

            let requestLogin = API_URL + 'creation/scopes/'+item.id+'/delete';

            await axios({
                method: "DELETE",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadlistItineraries", response.data.data);
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeItinerary(state,item){
            for(var i=0;i<state.state.listItineraries.length;i++){
                if(state.state.listItineraries[i].id == item.id){
                    delete state.state.listItineraries[i];
                }
            }
            state.state.listItineraries = state.state.listItineraries.filter(function (item) {
                return item !== undefined;
            });

            let requestLogin = API_URL + 'creation/itineraries/'+item.id+'/delete/';

            await axios({
                method: "DELETE",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadlistItineraries", response.data.data);
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeCertificate(state,item){
            for(var i=0;i<state.state.listCertifictes.length;i++){
                if(state.state.listCertifictes[i].id == item.id){
                    delete state.state.listCertifictes[i];
                }
            }
            state.state.listCertifictes = state.state.listCertifictes.filter(function (item) {
                return item !== undefined;
            });

            let requestLogin = API_URL + 'creation/certificates/'+item.id+'/delete';

            await axios({
                method: "delete",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadlistItineraries", response.data.data);
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getInfoByTypeAndID(state, item){
            var concat = ''
            if(item.type === 'activity'){
                concat = 'creation/activities';
            }else if(item.type === 'ambits_certificates'){
                concat = 'creation/itineraries';
            }else{
                concat = item.type;
            }

            let request = API_URL  + concat + '/' + item.id + '/details';
            await axios({
                url: request,
            }).then(
                response => {
                    state.commit("loadInfoByTypeAndID", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeDependencies(state, item){
            state.commit('loadRemoveDependency', item.id);
        },
        async getDependentByIdAndActivity(state, item){
            await axios({
                url: API_URL + 'creation/' + item.type + '/' + item.id_activity + '/' + item.id_exercise
            }).then(
                async response => {
                    state.commit("loadDataDependent", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDependentByIdAndCertificate(state, item){
            await axios({
                url: API_URL + 'creation/certificates/' + item.id_activity + '/' + item.id_exercise
            }).then(
                async response => {
                    state.commit("loadDataDependent", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDependentByIdAndAmbit(state, item){
            await axios({
                url: API_URL + 'creation/ambits/' + item.id_activity + '/' + item.id_exercise
            }).then(
                async response => {
                    state.commit("loadDataDependent", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDependentByIdAndItinerary(state, item){
            await axios({
                url: API_URL + 'creation/itineraries/' + item.id_activity + '/' + item.id_exercise
            }).then(
                async response => {
                    state.commit("loadDataDependent", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getTempIdentificator(state, item){
            await axios({
                url: API_URL + 'creation/' + item.type + '/temporal'
            }).then(
                async response => {
                    state.commit("loadDataTemp", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};