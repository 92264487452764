<template>
    <div
        :id="datablock.id"
        :class="datablock.class"
        :style="datablock.styles"
        style="display: flex"
    >
        <label
            class="gh_text_field-label T15 T15_b"
            style="margin-right: 0px;"
            :class="datainput.required == true ? 'T15_b':''"
            :id=datalabel.id
            v-if="datalabel.text !== undefined && datalabel.text !== ''"
        >
            {{datalabel.text}}
        </label>
        <input
            :id=datainput.id
            :style="datainput.style"
            class="gh-text-field-input"
            :class=datainput.class
            v-model=datainput.value
            type="color"
            :name=datainput.name
            :placeholder=datainput.value
            v-on="$listeners"
            @change="ChangeColor"
        >
        <input
            :id="`${datainput.id}_hex`"
            class="gh-text-field-input T17"
            :class="datainput.required == true ? 'required':''"
            v-model="datainput.value"
            @keyup="ChangeColor"
            v-on="$listeners"
            :type="datainput.hidden !== undefined ? datainput.hidden : 'text'"
            :required="datainput.required ? true : false"
        >
    </div>
</template>

<script>
    export default {
          name: "GhColor",
          props: ['datalabel', 'datainput', 'datablock'],
          mounted() {
              if(this.datainput.value == ''){
                  this.datainput.value = '#000000';
                  document.getElementById(`${this.datainput.id}_hex`).value = '#000000';
              }
          },
          methods:{
              ChangeColor(){
                  document.getElementById(`${this.datainput.id}_hex`).value = this.datainput.value;
                  document.getElementById(`${this.datainput.id}`).value = this.datainput.value;
              },
          },
    }
</script>