var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.$root._route.params.windowed ? 'margin-top: -3px' : '')},[(_vm.$root._route.params.windowed)?_c('GhLocation',{attrs:{"dataLocation":{
            showButton: _vm.Login.perm_support,
            secondWindow: true,
            showLoader: 'OK',
            text: _vm.$t('theory')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')}}}):_vm._e(),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'theory',title: _vm.$t('theory')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_theory"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"170px"}},[_c('div',{staticStyle:{"width":"168px","height":"168px"}},[_c('img',{staticStyle:{"border-radius":"6px","max-height":"168px"},attrs:{"src":_vm.image,"width":"168","alt":""}})]),_c('div',{staticClass:"other-image"},[_c('GhEasyUpload',{attrs:{"extrablock":{
                                        id: 'theory_other_image',
                                        class: '',
                                        name_hidden: 'theory[other_image]',
                                        id_hidden: 'theory_other_image',
                                        icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                    },"datalabel":{
                                        id: 'theory_other_image',
                                        style: 'margin-right: -15px'
                                    },"datainput":{
                                        view: _vm.$root._route.params.view,
                                        accept: '.png, .jpg, .jpeg',
                                        text: _vm.$t(_vm.haveImage ? 'change_image' : 'attach_image'),
                                        show_image: false,
                                        delete: false,
                                        href: 'easyUpload',
                                        downloadHref: 'easyDownload',
                                        file_name: _vm.theory.other_image.file_name,
                                        file_path: _vm.theory.other_image.file_path,
                                        file_download: _vm.theory.other_image.file_download,
                                        required: false
                                    }}})],1)]),_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefCode",attrs:{"datalabel":{
                                            text: _vm.$t('code') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'theory_code',
                                            name: 'theory[code]',
                                            style: 'width: 200px',
                                            value: _vm.theory.code,
                                            type: 'text',
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.theory.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefName",attrs:{"datalabel":{
                                            text: _vm.$t('name') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'theory_title',
                                            name: 'theory[name]',
                                            style: 'min-width: 625px; width: 40%',
                                            value: _vm.theory.name,
                                            type: 'text',
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.theory.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefAuthor",attrs:{"datalabel":{
                                            text: _vm.$t('author'),
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: false,
                                            id: 'theory_author',
                                            name: 'theory[author]',
                                            style: 'min-width: 625px; width: 40%',
                                            value: _vm.theory.author,
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('author') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.theory.author))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefEditor",attrs:{"datalabel":{
                                            text: _vm.$t('editor'),
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: false,
                                            id: 'theory_editor',
                                            name: 'theory[editor]',
                                            style: 'min-width: 625px; width: 40%',
                                            value: _vm.theory.editor,
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('editor') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.theory.editor))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhCalendar',{ref:"RefPublicationDate",attrs:{"datalabel":{
                                            id: 'label_theory_publication_date',
                                            text: _vm.$t('publication_date'),
                                            style: 'width:150px; min-width:150px; text-align: right;'
                                        },"datainput":{
                                            id: 'input_theory_publication_date',
                                            name: 'theory[publication_date]',
                                            value: _vm.theory.publication_date
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('publication_date') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.theory.publication_date))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefReferences",attrs:{"datalabel":{
                                            text: _vm.$t('references'),
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: false,
                                            id: 'theory_references',
                                            name: 'theory[references]',
                                            style: 'min-width: 625px; width: 40%',
                                            value: _vm.theory.references,
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('references') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.theory.references))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefLink",attrs:{"datalabel":{
                                            text: _vm.$t('link'),
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: false,
                                            id: 'theory_link',
                                            name: 'theory[link]',
                                            style: 'min-width: 625px; width: 40%',
                                            value: _vm.theory.link,
                                            type: 'text'
                                        }},on:{"keyup":function($event){return _vm.setLink()}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('link') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.theory.link))])]],2),_c('div',{staticClass:"gh-row-content",style:(_vm.$root._route.params.view ? '' : 'margin-bottom: -5px')},[(!_vm.$root._route.params.view)?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                                            type: 'radio',
                                            id: 'label_topic',
                                            label: _vm.$t('topic'),
                                            title: _vm.$t('topic'),
                                            text: _vm.$t('select_topic') + ':',
                                            name: 'theory[topic]',
                                            id_value: 'value',
                                            name_label: 'text',
                                            child_key: 'children',
                                            required: true,
                                            checkparentdata: true,
                                            style: '',
                                            label_style: 'width: 149px',
                                            popupStyle: 'min-width: 900px; width: 55%;'
                                        },"selected_input":_vm.theory.topic,"items":_vm.getFeeds.feed_topic}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('topic') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.theory.topic[0].text))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:"RefDescription",attrs:{"datalabel":{
                                            text: _vm.$t('description'),
                                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        },"datainput":{
                                            id: 'theory_description',
                                            name: 'theory[description]',
                                            value: _vm.theory.description,
                                            styles: 'padding-top: 2px;'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.theory.description))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('document') + ':'))]),_c('div',[_c('GhEasyUpload',{attrs:{"extrablock":{
                                            id: 'theory',
                                            class: '',
                                            name_hidden: 'theory[document]',
                                            id_hidden: 'theory',
                                            icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                        },"datalabel":{
                                            id: 'label_import_block',
                                            style: 'margin-right: -15px'
                                        },"datainput":{
                                            view: _vm.$root._route.params.view,
                                            accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword, .odt, .zip, .pdf, .png, .jpg, .jpeg',
                                            text: _vm.$t('attach'),
                                            show_image: false,
                                            delete: false,
                                            href: 'easyUpload',
                                            downloadHref: 'easyDownload',
                                            file_name: _vm.theory.attached.file_name,
                                            file_path: _vm.theory.attached.file_path,
                                            file_download: _vm.theory.attached.file_download,
                                            required: _vm.theory.link !== '' ? false : true
                                        }}})],1)])])])]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save',
                    text: _vm.$t('save')
                }},on:{"click":_vm.submitSupportMaterial}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_return',
                    text: _vm.$root._route.params.windowed ? _vm.$t('close') : _vm.$t('back'),
                }},on:{"click":function($event){_vm.$root._route.params.windowed ? _vm.close() : _vm.router.go(-1)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }