<template>
    <div style="padding-bottom: 15px">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'billings_'+label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: label['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
        <GhNoDropSelector v-if="HistoricBilling && HistoricBilling.haveResult == true"
            :datablock="{
                id: 'result_search_billing',
                title: typeof HistoricBilling.resultTitle == 'number' ? HistoricBilling.resultTitle + ' ' + $t('results') : $t(HistoricBilling.resultTitle),
                num_results: '',
            }"
        >
            <template v-slot:content>
                <component v-bind:is="result_billing" />
            </template>
            <template v-slot:actions>
                <GhAction
                    :dataction="{
                        id: 'export_result_data', class: 'T15_action', text: $t('export_results')
                    }"
                    @click="exportResult"
                />
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
    import billing from './billing/list_billing';
    import historic from './historic/list_historic';
    import result_billing from "./historic/result_content/result_billing";
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from 'fe-gh-action-lib';
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import {mapState} from "vuex";
    export default {
        name: "list_billing",
        components: {
            GhDropSelector,
            GhTableCommon,
            GhAction,
            GhNoDropSelector
        },
        computed: {
            ...mapState(['TabBilling','HistoricBilling','Login']),
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'outstanding', component: billing, result: '', show: false, second_text: 'Resultados'},
                    {id: 2, name: 'historical', component: historic, result: '', show: false, second_text: ''},
                ],
                result_billing: result_billing
            }
        },
        beforeMount(){
            if (this.Login.tabs.length !== 0) {
                this.Login.tabs[11]['sub-modules'].forEach((subroute,subkey) => {
                    if (this.data[subkey] !== undefined && this.data[subkey].name === subroute.name) {
                        this.data[subkey].show = subroute.have_permission;
                    }
                });
            }
        },
        methods: {
            exportResult() {
                const formData = new FormData(document.getElementById('form_search_billing'));
                this.HistoricBilling.searchData && this.HistoricBilling.searchData != '' && this.HistoricBilling.searchData != undefined ? this.$store.dispatch('exportBillingResult',{formData: [...formData]}) : '';
            }
        }
    }
</script>