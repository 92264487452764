<template>
    <div style="padding-bottom: 20px;" :key="getterRefreshAssignationBase">
        <GhNoDropSelector
            :datablock="{
                id: 'assignation_base',
                title: $t('assignation_base')
            }"
        >
            <template v-slot:content>
                <template v-if="haveData">
                    <form id="form_assignation_base">
                        <component v-bind:is="assignation_base"/>
                    </form>
                </template>
                <template v-else-if="!haveData">
                    <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                        <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </div>
                </template>
            </template>
        </GhNoDropSelector>

        <template v-if="haveData && getterAssignationBase.show.list_evaluators">
            <div v-for="(label, labelIndex) in list" :key="labelIndex">
                <GhDropSelector v-if="label.show"
                    :datablock="{
                        id: 'section_evaluators_' + label.id,
                        title: $t(label['name']),
                        second_title: '',
                        num_results: '',
                    }"
                >
                    <template v-slot:content>
                        <component :is="label.component"/>
                    </template>
                </GhDropSelector>
            </div>
        </template>

        <template v-if="!getterAssignationBase.buttons.recalculate && !getterAssignationBase.buttons.validate">
            <div class="centered" style="margin-top: 15px; margin-bottom: 15px;">
                <template v-if="!$root._route.params.view && haveData">
                    <template v-if="getterAssignationBase.buttons.finish">
                        <GhButton
                            :datainput="{
                                id: 'btn_finish',
                                text: $t('finish')
                            }"
                            @click="submit('finish')"
                        />
                    </template>
                    <GhButton
                        :datainput="{
                            id: 'btn_save',
                            text: $t('save')
                        }"
                        @click="submit('save')"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'btn_return',
                        text: $t('back'),
                    }"
                    @click="$router.go(-1)"
                />
            </div>
        </template>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    import assignation_base from './template/assignation_base.vue';
    import lead_manager from './template/lead_manager.vue';
    import advisory_evaluator from "./template/advisory_evaluator.vue";
    import practice_evaluator from "./template/practice_evaluator.vue";

    export default {
        name: 'section_assignation_base',
        components: {
            GhNoDropSelector,
            GhDropSelector,
            GhButton
        },
        computed: {
            ...mapGetters(['getterAssignationBase', 'getterRefreshAssignationBase'])
        },
        data(){
            return {
                assignation_base: assignation_base,
                list: [
                    {id: 1, name: 'lead_manager', component: lead_manager, result: '', show: true , second_text: 'results'},
                    {id: 2, name: 'advisory_evaluator', component: advisory_evaluator, result: '', show: true , second_text: 'results'},
                    {id: 3, name: 'practice_evaluator', component: practice_evaluator, result: '', show: true , second_text: 'results'},
                ],
                leader: [],
                leaderText: '',
                evaluator: [],
                evaluatorText: '',
                refreshAssign: 0,
                haveData: false
            }
        },
        watch: {
            async 'getterRefreshAssignationBase'(){
                const token = localStorage.getItem("token");
                if (token) {
                    await this.$store.dispatch('getFeedTypeEvaluation', '', {root: true});
                    await this.$store.dispatch('getFeedEvaluatorModality', '', {root: true});
                    await this.$store.dispatch('getLanguagesEvaluatorData', '', {root:true});
                    await this.$store.dispatch('getFeedChannel', '', {root:true});
                    await this.$store.dispatch('getFeedCCAA', '', {root:true});
                    await this.$store.dispatch('getFeedScoreRange', '', {root:true});
                    await this.$store.dispatch('getAssignationBase', {
                        id: this.$root._route.params.id,
                        itinerary: this.$root._route.params.itinerary,
                    }, {root: true});
                    this.haveData = true;
                }
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFeedTypeEvaluation', '', {root: true});
                await this.$store.dispatch('getFeedEvaluatorModality', '', {root: true});
                await this.$store.dispatch('getLanguagesEvaluatorData', '', {root:true});
                await this.$store.dispatch('getFeedChannel', '', {root:true});
                await this.$store.dispatch('getFeedCCAA', '', {root:true});
                await this.$store.dispatch('getFeedScoreRange', '', {root:true});
                await this.$store.dispatch('getAssignationBase', {
                    id: this.$root._route.params.id,
                    itinerary: this.$root._route.params.itinerary,
                }, {root: true});
                this.haveData = true;
            }
        },
        async beforeDestroy(){
            this.$store.dispatch('destroyAssignationBase', {
                id: this.$root._route.params.id,
                itinerary: this.$root._route.params.itinerary,
            }, {root: true});
        },
        methods: {
            async submit(type){
                switch (type){
                    case 'save':
                        await this.saveAssignationBase(type);
                        break;
                    case 'recalculate':
                        var formData = new FormData(document.getElementById('form_assignation_base'));

                        var type_evaluation = true;
                        var format_evaluation = true;
                        var preferred_language = true;
                        var ccaa = true;
                        var estimated_score_range = true;

                        var alertRequired = [];

                        var count = 0;
                        var newFormData = new FormData();
                        [...formData].forEach((item) => {
                            if(item[0] === 'assignation_base[new_stamp_customer]' || item[0] === 'assignation_base[type_evaluation]' || item[0] === 'assignation_base[format_evaluation]' || item[0] === 'assignation_base[preferred_language]' || item[0] === 'assignation_base[ccaa]' || item[0] === 'assignation_base[trips_during_the_visit]' || item[0] === 'assignation_base[international_ambit]' || item[0] === 'assignation_base[evaluators]' || item[0] === 'assignation_base[days]'  || item[0] === 'assignation_base[estimated_score_range]'){
                                newFormData.append(item[0], item[1]);
                            }
                            if(item[0] === 'assignation_base[type_evaluation]' || item[0] === 'assignation_base[format_evaluation]' || item[0] === 'assignation_base[preferred_language]' || item[0] === 'assignation_base[ccaa]' || item[0] === 'assignation_base[estimated_score_range]'){
                                count++
                            }

                            if(type_evaluation && item[0] === 'assignation_base[type_evaluation]'){
                                type_evaluation = false;
                            }

                            if(format_evaluation && item[0] === 'assignation_base[format_evaluation]'){
                                format_evaluation = false;
                            }

                            if(preferred_language && item[0] === 'assignation_base[preferred_language]'){
                                preferred_language = false;
                            }

                            if(ccaa && item[0] === 'assignation_base[ccaa]'){
                                ccaa = false;
                            }

                            if(estimated_score_range && item[0] === 'assignation_base[estimated_score_range]'){
                                estimated_score_range = false;
                            }
                        });

                        if(type_evaluation){
                            alertRequired.push(this.$t('type_evaluation') + '*');
                        }
                        if(format_evaluation){
                            alertRequired.push(this.$t('format_evaluation') + '*');
                        }
                        if(preferred_language){
                            alertRequired.push(this.$t('preferred_language') + '*');
                        }
                        if(ccaa){
                            alertRequired.push(this.$t('ccaa') + '*');
                        }
                        if(estimated_score_range){
                            alertRequired.push(this.$t('estimated_score_range') + '*');
                        }

                        if(count === 5){
                            this.$store.dispatch('setAssignationBase', {
                                formData: [...newFormData],
                                type: type,
                                id: this.$root._route.params.id,
                                itinerary: this.$root._route.params.itinerary,
                                keyTranslate: {
                                    'changes_have_been_recalculate_correctly': this.$t('changes_have_been_recalculate_correctly'),
                                    'changes_have_been_saved_correctly': this.$t('changes_have_been_saved_correctly')
                                }
                            }, {root: true});
                        }else{
                            this.Global.state.alert_required.requireds = alertRequired;
                            this.Global.openPopUp('warning_confirm');
                        }
                        break;
                    case 'finish':
                    case 'validate':
                        if(this.Global.checkRequired('form_assignation_base') === 'OK') {
                            await this.saveAssignationBase(type);
                        }
                        break;
                }
            },
            async saveAssignationBase(type){
                const formData = new FormData(document.getElementById('form_assignation_base'));
                this.$store.dispatch('setAssignationBase', {
                    formData: [...formData],
                    type: type,
                    id: this.$root._route.params.id,
                    itinerary: this.$root._route.params.itinerary,
                    keyTranslate: {
                        'changes_have_been_recalculate_correctly': this.$t('changes_have_been_recalculate_correctly'),
                        'changes_have_been_saved_correctly': this.$t('changes_have_been_saved_correctly')
                    }
                }, {root: true});

            }
        }
    }
</script>