var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:'KEY70' + _vm.remountComponent,staticClass:"float_window",style:(_vm.$root._route.params.from === 'grouper-exercise' || _vm.$root._route.params.from === 'parent-exercise' || _vm.$root._route.params.children === 'children' || _vm.$root._route.params.version || (_vm.$root._route.params.type === undefined && _vm.$root._route.params.view) ? 'margin-top: -4px;' : '')},[(_vm.$root._route.params.from === 'grouper-exercise' || _vm.$root._route.params.from === 'parent-exercise' || _vm.$root._route.params.children === 'children' || _vm.$root._route.params.version || (_vm.$root._route.params.type === undefined && _vm.$root._route.params.view))?_c('GhLocation',{attrs:{"dataLocation":{
            showButton: _vm.Login.perm_support,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.$t('exercise'),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}):_vm._e(),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'exercise', title: _vm.$t('exercise')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?[_c('form',{attrs:{"id":"form_exercise"}},[_c('div',{staticClass:"gh-content-global"},[(_vm.$root._route.params.type !== undefined && _vm.$root._route.params.type != 'elaboration' && Object.values(_vm.$root._route.params).length !== 0)?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.BaseCreationExerciseTable.exercise_data.code))])]):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled' && _vm.$root._route.params.id === undefined)?[_c('GhTreeSelectorBlock',{ref:"RefTypeOfExercise",attrs:{"dataBlock":{
                                            type: 'radio',
                                            id: 'label_exercise_type',
                                            label: _vm.$t('type_of_exercise') + '*',
                                            title: _vm.$t('type_of_exercise'),
                                            text: _vm.$t('select_type_of_exercise') + ':',
                                            name: 'exercise[type]',
                                            id_value: 'value',
                                            name_label: 'text',
                                            child_key: 'children',
                                            required: true,
                                            checkparentdata: false,
                                            style: '',
                                            label_style: 'width: 149px',
                                            popupStyle: 'min-width: 900px;width: 55%;'
                                        },"selected_input":_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.type !== undefined && _vm.BaseCreationExerciseTable.exercise_data.type.length != 0 ? _vm.BaseCreationExerciseTable.exercise_data.type : [],"items":_vm.getFeeds.feed_type_exercise},on:{"accept":function($event){return _vm.changeType()}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('type_of_exercise') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined ? Object.values(_vm.BaseCreationExerciseTable.exercise_data.type)[0] : ''))]),_c('input',{attrs:{"type":"hidden","name":"exercise[type]"},domProps:{"value":_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.type !== undefined && _vm.BaseCreationExerciseTable.exercise_data.type.length != 0 ? Object.keys(_vm.BaseCreationExerciseTable.exercise_data.type)[0] : ''}})]],2),_c('div',{staticClass:"gh-row-content",style:(_vm.$root._route.params.view || _vm.$root._route.params.type === 'enabled' ? '' : 'margin-bottom: 10px')},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTreeSelectorBlock',{ref:"refTopic",attrs:{"dataBlock":{
                                            type: 'radio',
                                            id: 'label_topic',
                                            label: _vm.$t('topic') + '*',
                                            title: _vm.$t('topic'),
                                            text: _vm.$t('select_topic') + ':',
                                            name: 'exercise[topic]',
                                            id_value: 'value',
                                            name_label: 'text',
                                            child_key: 'children',
                                            required: true,
                                            checkparentdata: true,
                                            style: '',
                                            label_style: 'width: 149px',
                                            popupStyle: 'min-width: 900px; width: 55%;'
                                        },"selected_input":_vm.BaseCreationExerciseTable.exercise_data.topic !== undefined && _vm.BaseCreationExerciseTable.exercise_data.topic.length !== 0 ? _vm.BaseCreationExerciseTable.exercise_data.topic : [],"items":_vm.getFeeds.feed_topic}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('topic') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.BaseCreationExerciseTable.exercise_data.topic !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.topic[0].text : ''))])]],2),_c(_vm.components.type_exercise,{tag:"component"}),_c(_vm.components.subquestions,{tag:"component"}),_c(_vm.components.conditions,{tag:"component"}),_c(_vm.components.support,{tag:"component"}),_c(_vm.components.know_more,{tag:"component"}),(_vm.getterTypeExercise.referentials !== undefined)?_c(_vm.components.associate,{tag:"component",attrs:{"props":{
                                    name:'exercise',
                                    selecteds:{
                                         groupers: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.associate !== undefined && _vm.BaseCreationExerciseTable.exercise_data.associate.groupers !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.associate.groupers : [],
                                    }
                                }}}):_vm._e(),(_vm.getterTypeExercise.referentials !== undefined)?_c(_vm.components.reference_thesauros,{tag:"component",attrs:{"props":{
                                    name:'exercise',
                                    selecteds:{
                                        groupers: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.groupers !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.groupers : [],
                                        topics: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.topics !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.topics : [],
                                        exercises: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.exercises !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.exercises : [],
                                        thesauros: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.thesauros !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.thesauros : [],
                                    },
                                    view:{
                                        groupers: true,
                                        topics: false,
                                        exercises: true,
                                        thesauros: false,
                                    },
                                    fixTopics: _vm.topicFixed
                                }}}):_vm._e(),(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.notes !== undefined && _vm.BaseCreationExerciseTable.exercise_data.notes.length !== 0)?[_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('situation')))])]),_c('div',{staticClass:"new-striped",staticStyle:{"margin-top":"10px"}},[_vm._l((_vm.BaseCreationExerciseTable.exercise_data.notes),function(note,indexNote){return [_c('div',{key:'NOTE' + indexNote,staticStyle:{"padding-left":"5px","padding-top":"3px","padding-bottom":"3px","display":"flex"}},[_c('div',{staticStyle:{"width":"100%","display":"flex"}},[_c('div',{staticClass:"T15",staticStyle:{"margin-left":"5px","margin-top":"1px"}},[_vm._v(_vm._s(note.text))])])])]})],2)]:_vm._e()],2)])]:(!_vm.haveData)?[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]:_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px","padding-bottom":"8px"}},[(_vm.haveData)?[(_vm.$root._route.params.version && (_vm.$root._route.params.type === 'elaboration' || _vm.$root._route.params.type === 'pending'))?[(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.finish)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_finish',
                        text: _vm.$root._route.params.version ? _vm.$t('enable') : _vm.$t('finish')
                    }},on:{"click":function($event){return _vm.submit('finish')}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_save',
                        text: _vm.$t('save')
                    }},on:{"click":function($event){return _vm.submit('save')}}})]:[(!_vm.$root._route.params.version && !_vm.$root._route.params.view && _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.finish && _vm.$root._route.params.type === 'elaboration')?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_finish',
                        text: _vm.$root._route.params.version ? _vm.$t('enable') : _vm.$t('finish')
                    }},on:{"click":function($event){return _vm.submit('finish')}}}):_vm._e(),(!_vm.$root._route.params.version && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_save',
                        text: _vm.$t('save')
                    }},on:{"click":function($event){return _vm.submit('save')}}}):_vm._e(),(_vm.$root._route.params.type === 'pending' && !_vm.$root._route.params.version && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_enable',
                        text: _vm.$t('enable')
                    }},on:{"click":function($event){return _vm.submit('enable')}}}):_vm._e(),(_vm.$root._route.params.type === 'pending' && !_vm.$root._route.params.version && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_copy',
                        text: _vm.$t('copy')
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','ExerciseCopy', _vm.$root._route.params.id,{'code': _vm.BaseCreationExerciseTable.exercise_data.code, 'type': _vm.BaseCreationExerciseTable.exercise_data !== undefined && Object.values(_vm.BaseCreationExerciseTable.exercise_data.type)[0]}, _vm.$t('copy_exercise'), _vm.$t('copy_delete_exercise'), 'delete')}}}):_vm._e(),(_vm.$root._route.params.type === 'pending' && !_vm.$root._route.params.version && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_delete',
                        text: _vm.$t('delete')
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','Exercise', _vm.$root._route.params.id,{'code': _vm.BaseCreationExerciseTable.exercise_data.code, 'type': _vm.BaseCreationExerciseTable.exercise_data !== undefined && Object.values(_vm.BaseCreationExerciseTable.exercise_data.type)[0]}, _vm.$t('delete_exercise'), _vm.$t('preparing_delete_exercise'), 'delete')}}}):_vm._e()],(_vm.$root._route.params.version && _vm.$root._route.params.type !== 'elaboration' && _vm.$root._route.params.type !== 'pending')?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_versioning',
                    text: _vm.$t('versioning')
                }},on:{"click":_vm.setVersion}}):_vm._e(),(_vm.$root._route.params.type == 'pending' || _vm.$root._route.params.type == 'enabled' || (_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.finish))?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_preview',
                    text: _vm.$t('preview')
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('preview_exercise', {id:_vm.$root._route.params.id,type:'exercise'}),'preview_exercise')}}}):_vm._e()]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$root._route.params.from === 'grouper-exercise' || _vm.$root._route.params.from === 'parent-exercise' || _vm.$root._route.params.children === 'children' || _vm.$root._route.params.version || (_vm.$root._route.params.type === undefined && _vm.$root._route.params.view) ? _vm.$t('close') : _vm.$t('back')
            }},on:{"click":function($event){_vm.$root._route.params.from === 'grouper-exercise' || _vm.$root._route.params.from === 'parent-exercise' || _vm.$root._route.params.children === 'children' || _vm.$root._route.params.version || (_vm.$root._route.params.type === undefined && _vm.$root._route.params.view) ? _vm.Global.windowClose() : _vm.router.push({name: 'base_creation'})}}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }