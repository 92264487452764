<template>
    <div class="float_window" style="padding-bottom: 20px;">
        <GhNoDropSelector
            :datablock="{
                id: 'section_clients',
                title: $t('clients') + ' ' + (showTable ? getterRealListClientsIndex[actualOpenKey].name : ''),
                num_results: '',
                label: showTable ? getterRealListClientsIndex[actualOpenKey].count + ' ' + $t('results') : ''
            }"
        >
            <template v-slot:content>
                <template v-if="haveData">
                    <template v-if="!showTable">
                        <div class="gh-content-global">
                            <div style="display: flex;">
                                <div v-for="(column, columnIndex) in getterListClientsIndex" :key="columnIndex" style="display: block; width: 33.33%;">
                                    <div v-for="(client, clientIndex) in column" :key="'B' + columnIndex + '-' + clientIndex" style="padding-bottom: 2px; padding-top: 2px; padding-left: 5px; padding-right: 5px;" :class="clientIndex % 2 !== 0 ? 'striped' : 'striped_white'">
                                        <div v-if="client" style="display: flex;">
                                            <div class="definition-field_header-text T14">{{ client.name + ' ' + '(' + $t('num') + ' ' + client.count + ')' }}</div>
                                            <div class="link T14" @click="loadDataClient(client)">
                                                <div class="definition-field-icon-open">{{ '[ + ]' }}</div>
                                            </div>
                                        </div>
                                        <div v-else style="display: flex; height: 17px;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <GhTableCommon
                            style="margin-top: 10px;"
                            :extratable="{
                                id: 'table_clients'
                            }"
                            :header="header"
                            :data="getterListClients"
                        >
                            <template v-slot:field_redirect="{itemProp, labelProp}">
                                <template v-if="labelProp === 'client'">
                                    <router-link :to="{name: 'client_id', params: {
                                        id: itemProp.id
                                    }}">
                                        <a class="table-cell_link T13">{{itemProp[labelProp]}}</a>
                                    </router-link>
                                </template>
                                <template v-else>
                                    <GhChecks
                                        :datainput="{
                                            id: 'partner_club_' + itemProp.id,
                                            value: 1,
                                            type: 'checkbox',
                                            name: 'partner_club[' + itemProp.id + ']',
                                            selected: itemProp[labelProp],
                                            disabled: false
                                        }"
                                        :datalabel="{class: '', id: '', text: ''}"
                                        :ref="'refPartnerClub' + itemProp.id"
                                        @click="setPartnerClub('refPartnerClub' + itemProp.id, itemProp.id)"
                                    />
                                </template>
                            </template>
                            <template v-slot:actions="{itemProp}">
                                <router-link :to="{
                                    name: 'client_id',
                                    params: {id: itemProp.id}
                                }">
                                    <GhAction
                                        :dataction="{
                                            id: 'view_client',
                                            text: $t('view'),
                                            icon: require('../../assets/gh_new_dessign/view.svg')
                                        }"
                                    />
                                </router-link>
                            </template>
                        </GhTableCommon>
                    </template>
                </template>
                <template v-else>
                    <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                        <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </div>
                </template>
            </template>
            <template v-slot:actions>
                <template v-if="showTable">
                    <GhAction
                        :dataction="{
                            id: 'back_index',
                            class: getterRealListClientsIndex[actualOpenKey - 1] !== undefined ? '' : 'disabled',
                            text: getterRealListClientsIndex[actualOpenKey - 1] !== undefined ? getterRealListClientsIndex[actualOpenKey - 1].name : getterRealListClientsIndex[0].name,
                        }"
                        @click="changeIndex('back')"
                    />
                    <label class="gh_text_field-label T14">{{'|'}}</label>
                    <GhAction
                        :dataction="{
                            id: 'next_index',
                            class: getterRealListClientsIndex[actualOpenKey + 1] !== undefined ? '' : 'disabled',
                            text: getterRealListClientsIndex[actualOpenKey + 1] !== undefined ? getterRealListClientsIndex[actualOpenKey + 1].name : getterRealListClientsIndex[getterRealListClientsIndex.length - 1].name,
                        }"
                        @click="changeIndex('next')"
                    />
                </template>
            </template>
        </GhNoDropSelector>

        <div class="centered" style="margin-top: 10px; margin-bottom: 20px;">
            <template v-if="showTable">
                <GhButton
                    :datainput="{
                        id: 'btn_back',
                        text: $t('back'),
                    }"
                    @click="showTable = false; actualOpenKey = null;"
                />
            </template>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'list_clients',
        components: {
            GhButton,
            GhNoDropSelector,
            GhTableCommon,
            GhChecks,
            GhAction
        },
        computed: {
            ...mapGetters(['getterListClientsIndex', 'getterListClients', 'getterRealListClientsIndex'])
        },
        data(){
            return {
                header: [
                    {text: 'client', field: 'client', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 40%;'},
                    {text: 'cif', field: 'cif', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'social_reason', field: 'social_reason', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'full_adress', field: 'full_adress', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 60%;'},
                    {text: 'partner_club', field: 'partner_club', sorting: false, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                showTable: false,
                actualOpenKey: null,
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getClientsIndex', '', {root: true});
                this.showTable = false;
                this.haveData = true;
            }
        },
        methods: {
            async setPartnerClub(ref,id){
                await this.$store.dispatch('setPartnerClub', {
                    check: this.$refs[ref]['selected'],
                    id: id
                }, {root: true});
            },
            async loadDataClient(item){
                this.getterRealListClientsIndex.forEach((i, k) => {
                    if(i.id === item.id){
                        this.actualOpenKey = k;
                    }
                });

                this.haveData = false;

                await this.$store.dispatch('getListClientsIndex', {
                    index: item.id
                }, {root: true});

                this.haveData = true;
                this.showTable = true;
            },
            changeIndex(type){
                this.loadDataClient(this.getterRealListClientsIndex[type === 'next' ? this.actualOpenKey + 1 : this.actualOpenKey - 1]);
            }
        }
    }
</script>

<style>
    #back_index {
        margin-right: 10px !important;
    }
</style>