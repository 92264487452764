<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhSubTab v-bind:items="$router.options.GhSubTabAssign" :dataBlock="{id: 'assign_tab_', calldispatch: 'getAssignTabActive'}"/>
        <component :is="viewLoad" :key="'subtab_' + refreshView" />
    </div>
</template>

<script>
    import GhSubTab from "fe-gh-subtab-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_assign",
        components:{
            GhSubTab,
        },
        computed:{
            ...mapState(['TabAssign']),
        },
        watch:{
            '$i18n.locale'() {
                this.haveData = false;
                this.Global.changeLanguage();

                var that = this;

                setTimeout(function () {
                    that.haveData = true;
                }, 200);
            },
            'TabAssign.reload'() {
                this.$nextTick(() => {
                    this.viewLoad = this.TabAssign.subTabActive;
                    this.refreshView++;
                });
            }
        },
        data() {
            return {
                haveData: false,
                viewLoad: null,
                refreshView: 0
            }
        },
        async beforeMount(){
            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>