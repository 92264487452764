<template>
    <div v-if="haveData">
        <GhTreeContentBlock
            style="margin-bottom: 15px; margin-left: 15px; margin-right: 15px;"
            :dataBlock="{
                id: 'historic',
                actionWithoutChildren: true,
                actionWithChildren: true,
                extraLabelNumber: false,
                extraLabelText: '',
                extraTableClass: 'class-table-exercise'
            }"
            :data="BaseCreationExerciseTable.records_data_historic !== undefined && BaseCreationExerciseTable.records_data_historic.data !== undefined && BaseCreationExerciseTable.records_data_historic.data.length === 0 ? [] : BaseCreationExerciseTable.records_data_historic.data"
            :header="header"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'config_exercise_id_view', params: { id: itemProp.id, view: true, type: 'historic'}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
            </template>
        </GhTreeContentBlock>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import GhTreeContentBlock from "@/components/GhTreeContentBlock.vue";

    export default {
        name: "list_historic",
        components: {
            GhTreeContentBlock,
        },
        computed: {
            ...mapState(['BaseCreationExerciseTable'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'code', field: 'code', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 150px;'},
                    {text: 'statement', field: 'statement', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100%;'}
                ],
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getExercisesHistoric', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>