<template>
    <div v-if="haveData">
        <div class="gh-row-content" v-if="getterTypeExercise.responsible.view" :style="!$root._route.params.view && $root._route.params.type !== 'enabled' ? 'margin-top: -10px;margin-bottom: 10px;' : ''">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'radio',
                        id: 'multi_open_responsible',
                        label: $t('responsible') + (getterTypeExercise.responsible.required ? '*' : ''),
                        title: $t('responsible'),
                        text: $t('select_responsible') + ':',
                        name: 'exercise[multi_open][responsible]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: getterTypeExercise.responsible.required,
                        checkparentdata: false,
                        style: '',
                        label_style: 'width: 149px',
                        popupStyle: 'min-width: 900px; width: 55%;'
                    }"
                    :selected_input="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.responsible ? BaseCreationExerciseTable.exercise_data.multi_open.responsible : []"
                    :items="getFeeds.feed_responsible"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('responsible') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.responsible && BaseCreationExerciseTable.exercise_data.multi_open.responsible[0] !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.responsible[0].text : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhInputTextField
                        style="margin-top: -5px;"
                        :datalabel="{
                            text: $t('prename'),
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'multi_open_statement',
                            name: 'exercise[multi_open][prename]',
                            style: 'width: 100px',
                            value: BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.prename : '',
                        }"
                        maxlength="16"
                    />
                    <GhInputTextField
                        style="margin-top: -5px;"
                        :datalabel="{
                            text: $t('name') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: true,
                            id: 'multi_open_statement',
                            name: 'exercise[multi_open][statement]',
                            style: 'width: 600px',
                            value: BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.statement : '',
                        }"
                        maxlength="100"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? (BaseCreationExerciseTable.exercise_data.multi_open.prename !== '' ? ' | ' : '') + BaseCreationExerciseTable.exercise_data.multi_open.statement : ''"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhTextarea
                        style="width: 100%;"
                        :datalabel="{
                            text: $t('description'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'multi_open_description',
                            name: 'exercise[multi_open][description]',
                            value: BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.description : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.description : ''"></label>
            </template>
        </div>
        <template v-for="(item,index) in getterTypeExercise.n_statements">
            <div class="gh-row-content" :key="'statement_' + index">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                    <div style="width: 100%">
                        <GhTextarea
                            style="width: 100%;"
                            :datalabel="{
                                text: $t('statement') + ' ' + item,
                                class: 'T15_b',
                                styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                            }"
                            :datainput="{
                                id: 'exercise_multi_open_real_statement_' + index,
                                name: 'exercise[multi_open][real_statement][' + index + ']',
                                value: BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.real_statement[index] : '',
                                required: false,
                                edited: false
                            }"
                        />
                    </div>
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('statement') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.real_statement[index] : ''"></label>
                </template>
            </div>
            <div class="gh-row-content" :key="'substatement_' + index">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                    <div style="width: 100%">
                        <GhTextarea
                            style="width: 100%;"
                            :datalabel="{
                                text: $t('substatement') + ' ' + item,
                                class: 'T15_b',
                                styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                            }"
                            :datainput="{
                                id: 'exercise_multi_open_substatement_' + index,
                                name: 'exercise[multi_open][substatement][' + index + ']',
                                value: BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.substatement[index] : '',
                                required: false,
                                edited: true,
                                image: {
                                    subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                    superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                    bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                    italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                    underline: require('../../../../../../assets/img/text/text-underline.svg'),
                                }
                            }"
                        />
                    </div>
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('substatement') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.substatement[index] : ''"></label>
                </template>
            </div>
        </template>
        <div class="gh-row-content" v-if="getterTypeExercise.information.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('information') + (getterTypeExercise.information.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'multi_open_information',
                        name: 'exercise[multi_open][information]',
                        value: BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.information : '',
                        required: getterTypeExercise.information.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('information') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.information : ''"></label>
            </template>
        </div>
        <div class="section_line" style="margin-top: 5px; margin-bottom: 10px;">
            <div class="T15_b" style="padding-left:13px;">{{''}}</div>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('text_after_download') + (getterTypeExercise.evidence.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'multi_open_text_evidence_after_download',
                        name: 'exercise[multi_open][text_evidence][after_download]',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.text_evidence.after_download !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.text_evidence.after_download : '',
                        required: getterTypeExercise.evidence.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                    maxlength="128"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('text_after_download') + ''}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.text_evidence.after_download !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.text_evidence.after_download : ''"></label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{
                    text: $t('file'),
                    style: '',
                    class: '',
                    id: ''
                }"
                :datainput="{
                    disabled: !$root._route.params.view && $root._route.params.type !== 'enabled' ? false : true,
                    required: false,
                    selected: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.type_file !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.type_file === 1 : '',
                    type: 'radio',
                    id: 'multi_open_text_evidence_after_download',
                    value: 1,
                    name: 'exercise[multi_open][text_evidence][type_file]'
                }"
            />
            <GhChecks
                :datalabel="{
                    text: $t('graphic'),
                    style: '',
                    class: '',
                    id: ''
                }"
                :datainput="{
                    disabled: !$root._route.params.view && $root._route.params.type !== 'enabled' ? false : true,
                    required: false,
                    selected: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.type_file !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.type_file === 2 : '',
                    type: 'radio',
                    id: 'multi_open_text_evidence_after_download',
                    value: 2,
                    name: 'exercise[multi_open][text_evidence][type_file]'
                }"
            />
        </div>
        <template v-if="getterTypeExercise.show_reference">
            <div class="section_line" style="margin-top: 5px; margin-bottom: 10px;">
                <div class="T15_b" style="padding-left:13px;">{{$t('reference')}}</div>
            </div>
            <div class="gh-row-content">
                <GhTextarea
                    style="width: 100%;"
                    :datalabel="{
                        text: $t('statement') + ' ' + $t('reference').toLowerCase(),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_multi_open_reference_statement',
                        name: 'exercise[multi_open][reference][statement]',
                        value: BaseCreationExerciseTable.exercise_data.multi_open !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.reference !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.reference.statement !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.reference.statement : '',
                        required: true,
                        edited: false
                    }"
                />
            </div>
            <template v-if="getterTypeExercise.references.length !== 0">
                <GhTableCommon
                    style="margin-bottom: 10px;"
                    :extratable="{
                        id: 'references'
                    }"
                    :header="header"
                    :data="getterTypeExercise.references"
                >
                    <template v-slot:field_component="{itemProp, labelProp}">
                        <div v-if="labelProp.field === 'reference'">
                            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                                <GhInputTextField
                                    :datalabel="{}"
                                    :datainput="{
                                        type: 'text',
                                        required: true,
                                        id: 'exercise_multi_open_reference_reference_' + itemProp.id,
                                        name: 'exercise[multi_open][reference][reference][' + itemProp.id +']',
                                        style: 'margin-right: 10px;',
                                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.reference !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.reference.reference !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.reference.reference[itemProp.id] !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.reference.reference[itemProp.id] : '',
                                    }"
                                />
                            </template>
                            <template v-else>
                                {{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.multi_open !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.reference !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.reference.reference !== undefined && BaseCreationExerciseTable.exercise_data.multi_open.reference.reference[itemProp.id] !== undefined ? BaseCreationExerciseTable.exercise_data.multi_open.reference.reference[itemProp.id] : ''}}
                            </template>
                        </div>
                    </template>
                </GhTableCommon>
            </template>
        </template>
    </div>
</template>

<script>
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import {mapGetters, mapState} from "vuex";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhTableCommon from "fe-gh-table-common-lib";

    export default {
        name: "type_multi_open",
        components: {
            GhTableCommon,
            GhChecks,
            GhTextarea,
            GhInputTextField,
            GhTreeSelectorBlock
        },
        computed: {
            ...mapState(['BaseCreationExerciseTable', 'getFeeds']),
            ...mapGetters(['getterTypeExercise'])
        },
        data(){
            return{
                header: [
                    {text: '', field: 'id', sorting: false, typeSort: 'int', style: 'min-width: 20px; max-width: 20px; width: 20px;'},
                    {text: 'reference', field: 'reference', field_component: this.$root._route.params.view || this.$root._route.params.type === 'enabled' ? false : true, required: true, style: 'margin-left: -5px; max-width: 10px; width: 100%; padding-right: 10px;'},
                ],
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                if (this.getterTypeExercise.responsible.view) {
                    await this.$store.dispatch('getFeedResponsibles', '', {root: true});
                }

                this.haveData = true;
            }
        }
    }
</script>

<style>
    #label_exercise_multi_open_responsible_selector_label, #label_exercise_subquestion_multi_open_responsible_selector_label{
        width: 150px;
        text-align: end;
    }
</style>