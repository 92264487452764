<template>
    <div class="gh-content-global-questionnaire">
        <div style="width: 100%;">
            <div class="T-subtitle" style="margin-left: 10px; margin-top: 2px; color: #1B82C5; padding-bottom: 25px;">{{$root._route.params.type === 'users' ? $t('contract_users') : $t('contract_programs')}}</div>
<!--            <div class="T-header" style="margin-left: 10px; margin-top: 2px; padding-bottom: 25px;">{{$t($root._route.params.type.replace('-', '_'))}}</div>-->
            <div class="question-body" style="    margin-bottom: 20px;">
                <div class="gh-statement-content T-subtitle" style="display: flex;">
                    <div style="width: 70%; display: flex; margin-left: 10px;" v-if="$root._route.params.type !== 'users'">
                        <GhChecks
                            :datalabel="{class: '', id: '', text: ''}"
                            :datainput="{
                                type: 'checkbox',
                                id: 'id_recomended_itinerary_all_payment_products',
                                value: 'all',
                                name: 'all',
                                disabled: whitePay
                            }"
                            @click="checkAllElements()"
                            ref="ghCheckAllElements"
                        />
                        <label class="gh_text_field-label T-text" style="margin-left: 10px;">{{$t('all_programs')}}</label>
                    </div>
                    <div :style="$root._route.params.type !== 'users' ? 'width: 30%;' : 'width: 100%;'" style="width: 30%; text-align: right; margin-right: 20px;">
                        <div class="T-subtitle-second" style="margin-left: 10px; margin-top: 2px; color: #1B82C5;">{{$t('amount')}}</div>
                    </div>
                </div>
                <div class="separator-line" style="margin-top: unset; margin-left: 10px; margin-right: 10px;"></div>
                <form id="form_payment">
                    <div class="gh-answer-content">
                        <div style="display: flex;">
                            <div style="width: 60%;">
                                <template v-if="$root._route.params.type !== 'users'">
                                    <div v-for="(item, index) in PlatformPay.records_data" :key="'ELEMENT_' + index" style="padding-bottom: 10px;">
                                        <div style="display: flex">
                                            <GhChecks v-if="item.payed === false || item.payed === undefined"
                                                :itemVal="item"
                                                :datalabel="{
                                                    text: '',
                                                    id: 'label_recomended_itinerary_' + item.id + '_payment_products'
                                                }"
                                                :datainput="{
                                                    type: 'checkbox',
                                                    id: 'id_recomended_itinerary_' + item.id + '_payment_products',
                                                    value: item.id,
                                                    name: 'pay[]',
                                                    disabled: whitePay
                                                }"
                                                ref="GhCheckElements"
                                                @click="isCheckAllElements(); toPay(item)"
                                            />
                                            <label class="gh_text_field-label T-text" style="margin-left: 10px;">{{item.name}}</label>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div style="display: flex">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: '',
                                                style: '',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                label_required: $t('number_desired_users') + '*',
                                                id: 'n_users_to_buy',
                                                name: 'users[buy]',
                                                style: 'width: 30px',
                                                value: users,
                                                type: 'int',
                                                limitNumMin: 1,
                                                limitNumMax: 999
                                            }"
                                            ref="RefUsers"
                                            @keyup="toPay"
                                        />
                                        <label class="gh_text_field-label T-text" style="margin-left: 10px;">{{$t('number_desired_users')}}</label>
                                    </div>
                                </template>
                            </div>
                            <div style="width: 40%">
                                <template v-if="$root._route.params.type !== 'users' && checkToPay.length !== 0">
                                    <div>
                                        <div v-for="(item, index) in checkToPay" :key="index" style="padding-top: 5px; padding-bottom: 5px; width: 100%; display: flex">
                                            <div class="container-element-info T15" style="margin-right: 10px">
                                                <div class="T-small-text" style="width: 100%; margin-right: 20px; text-align: right; color: #939393">{{item.name}}</div>
                                                <div class="T-text" style="min-width: 100px; text-align: right; color: #939393">{{item.price + ' €'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="$root._route.params.type === 'users'">
                                    <div style="padding-top: 5px; padding-bottom: 5px; width: 100%; display: flex">
                                        <div class="container-element-info T15" style="margin-right: 10px">
                                            <div class="T-small-text" style="width: 100%; margin-right: 20px; text-align: right; color: #939393">{{users + ' ' + $t('desired_users')}}</div>
                                            <div class="T-text" style="min-width: 100px; text-align: right; color: #939393">{{TabUsers.counters.price + ' €'}}</div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="gh-answer-content">
                        <div style="width: 100%; padding-bottom: 10px; display: grid;" v-if="checkToPay.length !== 0 || $root._route.params.type === 'users'">
                            <div class="separator-line" style="margin-top: unset; margin-left: 10px; margin-right: 10px; width: 90px; justify-self: right; background-color: #1B82C5; height: 2px;"></div>
                        </div>
                        <div style="display: flex;">
                            <div style="width: 60%; display: flex">
                                <label class="T-text" style="margin-right: 10px; color: #939393;">{{$t('method_pay') + ':'}}</label>
                                <div v-for="(item,index) in getFeeds.feed_pay_methods" :key="'METHOD_PAY_' + index" style="display: flex;">
                                    <GhChecks
                                        style="margin-top: -1px;"
                                        :datalabel="{
                                            text: '',
                                            style: '',
                                            id: 'label_target_' + index
                                        }"
                                        :datainput="{
                                            required: false,
                                            type: 'radio',
                                            id: 'value_target' + index,
                                            value: item.id,
                                            name: $root._route.params.type === 'users' ? 'users[method]' : 'pay_method',
                                            selected: index === 0 ? true : false,
                                            disabled: whitePay
                                        }"
                                        ref="RefTypePay"
                                    />
                                    <label class="T-subtitle-second" style="padding-left: 10px; padding-right: 20px;">{{$t(item.slug)}}</label>
                                </div>
                            </div>
                            <div style="width: 40%; margin-top: -4px; margin-right: 10px;" v-if="checkToPay.length !== 0 || $root._route.params.type === 'users'">
                                <div style="display: flex;">
                                    <div class="T-subtitle" style="margin-left: 10px; margin-top: 2px; color: #1B82C5; width: 100%; text-align: right; margin-right: 20px;">{{$t('tax') + ' ' + getFeeds.feed_tax + '%'}}</div>
                                    <div class="T-subtitle" style="min-width: 100px; margin-top: 2px; color: #1B82C5; text-align: right;">{{total_tax + ' ' + '€'}}</div>
                                </div>
                                <div style="display: flex;">
                                    <div class="T-subtitle" style="margin-left: 10px; margin-top: 2px; color: #1B82C5; width: 100%; text-align: right; margin-right: 20px;">{{$t('total_pay')}}</div>
                                    <div class="T-subtitle" style="min-width: 100px; margin-top: 2px; color: #1B82C5; text-align: right;">{{total}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="separator-line" style="margin-top: unset; margin-left: 10px; margin-right: 10px;"></div>
            <div class="centered" style="padding-top: 20px;">
                <GhButton
                    :datainput="{
                        id: 'btn_cancel',
                        text: $t('cancel'),
                        class: 'T19 container-md_button_content button-questionnaire-secondary',
                    }"
                    @click="Global.windowClose()"
                />
                <GhButton
                    :datainput="{
                        id: 'button_make_payment',
                        text: whitePay ? $t('confirm_pay') : $t('make_payment'),
                        class: 'T19 container-md_button_content button-questionnaire-primary',
                        icon: whitePay ? require('@/assets/gh_new_dessign/loader.gif') : checkToPay.length === 0 ? require('@/assets/questionnaire/arrow-right-grey.svg') : require('@/assets/questionnaire/arrow-right-white.svg'),
                        style: 'flex-direction: row-reverse;'
                    }"
                    :disabled="whitePay || ($root._route.params.type === 'users' ? false : checkToPay.length === 0)"
                    :title="$t('make_payment')"
                    @click="doPayment"
                />
            </div>
        </div>
        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'warning_pay',
                title: $t('pay'),
                type: 'warning',
                style: 'width: 35%; min-width: 430px'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: ''
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="gh_delete_confirm-content" style="overflow: auto;max-height: 800px; width: 100%; margin-right: 40px; padding-bottom: 15px; margin-top: -10px;">
                    <div class="T15_b" style="padding-left: 20px;">
                        {{$t(text_key)}}
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="Global.closePopUp('warning_pay'); checkClose()"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhPopUp from "fe-gh-popup-lib";

    import {mapState} from "vuex";

    export default {
        name: 'platform_pay',
        components:{
            GhPopUp,
            GhInputTextField,
            GhChecks,
            GhButton
        },
        computed: {
            ...mapState(['PlatformPay', 'TabUsers', 'getFeeds'])
        },
        data(){
            return {
                haveData: false,
                users: 1,
                total: 0,
                total_tax: 0,
                checkToPay: [],
                whitePay: false,
                text_key: ''
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getPayMethods', '', {root:true});
            await this.$store.dispatch('getFeedTax', '', {root:true});
            if(this.$root._route.params.type !== 'users') {
                await this.$store.dispatch('getDataPay', {
                    type: this.$root._route.params.type
                }, {root: true});
            }else{
                var sum = '';
                this.TabUsers.counters.price.toString().split('.').forEach((i) => {
                    sum += i
                });

                sum = sum.split(',');
                sum = sum[0] + '.' + sum[1];

                var countTotal = this.users * parseFloat(sum);
                countTotal = countTotal.toFixed(2);

                this.total_tax = (parseFloat(countTotal) * parseFloat('0.' + this.getFeeds.feed_tax)).toFixed(2);

                var total = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                    parseFloat(countTotal) + parseFloat(this.total_tax),
                );

                this.total = total;
            }

            this.haveData = true;
        },
        methods: {
            checkAllElements(){
                var check = this.$refs.ghCheckAllElements.selected;

                for(var i=0; i < this.$refs.GhCheckElements.length;i++) {
                    if (check) {
                        if (!this.$refs.GhCheckElements[i].selected) {
                            this.$refs.GhCheckElements[i].selected = true;
                            this.toPay(this.$refs.GhCheckElements[i].$attrs.itemVal);
                        }
                    } else {
                        if (this.$refs.GhCheckElements[i].selected) {
                            this.$refs.GhCheckElements[i].selected = false;
                            this.toPay(this.$refs.GhCheckElements[i].$attrs.itemVal);
                        }
                    }
                }
            },
            isCheckAllElements(){
                var allChecked = 1;
                for(var i=0; i < this.$refs.GhCheckElements.length;i++){
                    if(!this.$refs.GhCheckElements[i].selected){
                        allChecked = 0;
                    }
                }

                this.$refs.ghCheckAllElements.selected = allChecked;
            },
            toPay(item){
                var countTotal = 0;
                var total = '';
                switch(this.$root._route.params.type){
                    case 'users':
                        this.users = this.$refs['RefUsers'].datainput.value;
                        if(this.users === ''){
                            this.users = 1;
                        }

                        var sum = '';
                        this.TabUsers.counters.price.toString().split('.').forEach((i) => {
                            sum += i
                        });

                        sum = sum.split(',');
                        sum = sum[0] + '.' + sum[1];

                        countTotal = this.users * parseFloat(sum);
                        countTotal = countTotal.toFixed(2);

                        this.total_tax = (parseFloat(countTotal) * parseFloat('0.' + this.getFeeds.feed_tax)).toFixed(2);

                        total = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                            parseFloat(countTotal) + parseFloat(this.total_tax),
                        );

                        this.total = total;
                        break;
                    default:
                        var pos = this.checkToPay.find((i) => i.id === item.id);
                        if(pos === undefined) {
                            this.checkToPay.push(item);
                        }else {
                            this.checkToPay.forEach((i, k) => {
                                if(i.id === item.id){
                                    delete this.checkToPay[k];
                                }
                            });
                        }

                        this.checkToPay = this.checkToPay.filter(n => n);

                        this.checkToPay.forEach((item) => {
                            var sum = '';
                            item.price.split('.').forEach((i) => {
                                sum += i
                            });

                            sum = sum.split(',');
                            sum = sum[0] + '.' + sum[1];

                            countTotal += parseFloat(sum);
                        });

                        countTotal = countTotal.toFixed(2);

                        this.total_tax = (parseFloat(countTotal) * parseFloat('0.' + this.getFeeds.feed_tax)).toFixed(2);

                        total = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                            parseFloat(countTotal) + parseFloat(this.total_tax),
                        );

                        this.total = total;
                        break;
                }
            },
            async doPayment(){
                var type = '';
                this.$refs['RefTypePay'].forEach((i) => {
                    if(i.selected) {
                        type = i.datainput.value;
                    }
                });

                switch(type){
                    case 1:
                        this.whitePay = true;
                        if(this.$root._route.params.type === 'users') {
                            const formData = new FormData(document.getElementById('form_payment'));
                            await this.$store.dispatch('toBuyUsers', {
                                formData: [...formData],
                                redsys: true
                            }, {root: true}).then(async (response) => {
                                if (response.status === 200) {
                                    this.doForm(response.data.data);
                                }
                            });
                        }else {
                            const formData = new FormData(document.getElementById('form_payment'));
                            await this.$store.dispatch('setPay', {
                                formData: [...formData],
                                type: this.$root._route.params.type,
                                redsys: true
                            }, {root: true}).then(async (response) => {
                                if (response.status === 200) {
                                    this.doForm(response.data.data);
                                }
                            });
                        }
                        break;
                    case 2:
                        if(this.$root._route.params.type === 'users'){
                            const formData = new FormData(document.getElementById('form_payment'));
                            await this.$store.dispatch('toBuyUsers', {
                                formData: [...formData]
                            }, {root: true}).then((response) => {
                                if (response.status === 200) {
                                    window.opener.UpdatedSelect({
                                        response: true
                                    });
                                    this.Global.windowClose();
                                }
                            });
                        }else {
                            const formData = new FormData(document.getElementById('form_payment'));
                            await this.$store.dispatch('setPay', {
                                formData: [...formData],
                                type: this.$root._route.params.type
                            }, {root: true}).then((response) => {
                                if (response.status === 200) {
                                    window.opener.UpdatedSelect({
                                        response: true
                                    });
                                    this.Global.windowClose();
                                }
                            });
                        }
                        break;
                }
            },
            async doForm(formData){
                let form = document.createElement('form')
                form.setAttribute('method', 'POST')
                form.setAttribute('action', formData.action)

                let input = document.createElement('input')
                input.setAttribute('type', 'hidden')
                input.setAttribute('name', 'Ds_SignatureVersion')
                input.value = formData.Ds_SignatureVersion
                form.appendChild(input)

                input = document.createElement('input')
                input.setAttribute('type', 'hidden')
                input.setAttribute('name', 'Ds_Signature')
                input.value = formData.Ds_Signature
                form.appendChild(input)

                input = document.createElement('input')
                input.setAttribute('type', 'hidden')
                input.setAttribute('name', 'Ds_MerchantParameters')
                input.value = formData.Ds_MerchantParameters
                form.appendChild(input)
                //document.body.appendChild(form)

                //form.submit()


                var win = window.open('','formacion','width=1100,height=880,resizable=yes,status=no,menubar=no,location=no');
                win.document.body.appendChild(form);
                form.submit();

                var that = this;
                var timer = setInterval(async function() {
                    if(win.closed) {
                        clearInterval(timer);
                        var formD = new FormData();
                        formD.append('Ds_MerchantParameters', formData.Ds_MerchantParameters)
                        await that.$store.dispatch('getConfirmPay', {
                            formData: [...formD],
                            type: that.$root._route.params.type
                        }, {root: true}).then((response) => {
                            if(response.status === 200){
                                that.text_key = response.data.data ? 'pay_execute_correct' : 'pay_error';
                                that.whitePay = false;
                                that.Global.openPopUp('warning_pay');
                            }
                        });
                    }
                }, 1000);
            },
            checkClose(){
                if(this.text_key === 'pay_execute_correct'){
                    window.opener.UpdatedSelect({
                        response: true
                    });
                    this.Global.windowClose();
                }
            }
        }
    }
</script>

<style>
    .payment_box{
        max-height: unset !important;
    }
    #button_make_payment > img{
        margin-top: 4px !important;
        margin-right: -4px !important;
        height: 11px !important;
        margin-left: 6px !important;
    }
</style>