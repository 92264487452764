import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);
export default{
    state: {
        records_data: '',
        records_data_historic: '',
        records_data_enabled: '',
        records_data_pending: '',
        actual_page: 0,
        data_quizz: [],
        tabs_quizz: [],
        chart_quizz: [],
        status_condition_evidence_punctuation: [],
        support: [],
        type_exercise: [],
        exercise_data: [],
        data: {
            metrics: [],
            subquestions: []
        },
        records: {
            added: [],
            deleted: []
        },
        refreshTypeExercise: false,
        refreshMetrics: false,
        refreshSubquestions: false,
        refreshStatus: false,
        shouldDestroyComponent: 0
    },
    mutations: {
        destroyComponent(state) {
            state.shouldDestroyComponent++;
        },
        loadExercises(state, payload) {
            state.records_data = payload;
        },
        loadQuizzPreviewData(state, payload){
            state.data_quizz = payload;
        },
        loadQuizzTabs(state, payload){
            state.tabs_quizz = payload;
        },
        loadQuizzChart(state, payload){
            state.chart_quizz = payload;
        },
        loadExecutePreviewSupport(state, payload){
            state.support = payload;
        },
        loadActualPagePreview(state, payload){
            state.actual_page = payload;
        },
        loadExercisesHistoric(state, payload) {
            state.records_data_historic = payload;
        },
        loadExercisesPending(state, payload) {
            state.records_data_pending = payload;
        },
        loadExercisesEnabled(state, payload) {
            state.records_data_enabled = payload;
        },
        loadExerciseData(state, payload) {
            state.exercise_data = payload;
        },
        async loadCleanExerciseByType(state, payload){
            state.type_exercise = payload;
        },
        loadExerciseByType(state, payload){
            if (payload && Object.entries(payload).length > 0) {
                state.refreshTypeExercise = !state.refreshTypeExercise;
                state.type_exercise = payload;
            }
        },
        loadAddTemporalMetrics(state, payload){
            state.refreshMetrics = !state.refreshMetrics;
            state.data.metrics.push(payload);
            state.records.added.push(payload);
        },
        loadMetrics(state, payload){
            state.refreshMetrics = !state.refreshMetrics;
            state.data.metrics.push(payload);
        },
        loadTemporalSubquestionExercises(state, payload){
            state.refreshSubquestion = !state.refreshSubquestion;
            state.data.subquestions = payload;
        },
        loadTemporalSubquestionNewExercises(state, payload){
            state.refreshSubquestion = !state.refreshSubquestion;
            let updateKey = '';

            state.data.subquestions.forEach((i, k) => {
                if(payload.id_versioning !== undefined){
                    if (payload.id_versioning === i.id) {
                        updateKey = k;
                    }
                }else {
                    if (payload.id === i.id) {
                        updateKey = k;
                    }
                }
            })

            if(updateKey !== undefined && updateKey !== ''){
                state.data.subquestions[updateKey] = payload;
            }else {
                state.data.subquestions.push(payload);
            }
        },
        loadTemporalStatusConditionEvidencePunctuation(state, payload){
            state.refreshStatus = !state.refreshStatus;
            if(state.status_condition_evidence_punctuation[payload.key] === undefined) state.status_condition_evidence_punctuation[payload.key] = [];
            if(state.status_condition_evidence_punctuation[payload.key][payload.type] === undefined) state.status_condition_evidence_punctuation[payload.key][payload.type] = [];
            state.status_condition_evidence_punctuation[payload.key][payload.type] = payload.selected;
        }
    },
    getters: {
        getterTypeExercise(state){
            const dummy = state.refreshTypeExercise; // eslint-disable-line no-unused-vars
            return state.type_exercise;
        },
        getterMetrics(state){
            const dummy = state.refreshMetrics; // eslint-disable-line no-unused-vars
            return state.data.metrics;
        },
        getterSubquestions(state){
            const dummy = state.refreshSubquestion; // eslint-disable-line no-unused-vars
            return state.data.subquestions;
        },
        getterStatusConditionEvidencePunctuation(state){
            const dummy = state.refreshStatus; // eslint-disable-line no-unused-vars
            return state.data.status_condition_evidence_punctuation;
        }
    },
    actions: {
        async setTemporalStatusConditionEvidencePunctuation(state,item){
            await state.commit('loadTemporalStatusConditionEvidencePunctuation', item);
        },
        async getExercises(state){
            await axios.get(
                API_URL + "exercises"
            ).then(
                response => {
                    state.commit('loadExercises', response.data.data);
                }
            )
        },
        async getExercisesHistoric(state){
            await axios.get(
                API_URL + "exercises/historic"
            ).then(
                response => {
                    state.commit('loadExercisesHistoric', response.data.data);
                }
            )
        },
        async getExercisesPending(state){
            await axios.get(
                API_URL + "exercises/pending"
            ).then(
                response => {
                    state.commit('loadExercisesPending', response.data.data);
                }
            )
        },
        async getExercisesEnabled(state){
            await axios.get(
                API_URL + "exercises/enabled"
            ).then(
                response => {
                    state.commit('loadExercisesEnabled', response.data.data);
                }
            )
        },
        async removeExercise(state, item){
            let requestLogin = API_URL + "exercise/delete/" + item.id;
            let formData = new FormData();

            formData.append('exercise[id]', item.id);
            formData.append('exercise[type]', item.type);

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        if(window.location.pathname === '/base-creation'){
                            await this.dispatch('getExercises');
                            await this.dispatch('getExercisesHistoric');
                            await this.dispatch('getExercisesPending');
                            await this.dispatch('getExercisesEnabled');
                            await this.dispatch('getConfigExercisesCounters');
                        }else{
                            router.push({name: 'base_creation'});
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getExecutePreview(state,item){
            let requestUrl =  API_URL + item.type + "/preview/" + item.id;

            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        if(item.type === 'grouper' ? response.data.data.data.questionary !== undefined : response.data.data.questionary !== undefined){
                            var actual_page = 0;
                            Object.entries(item.type === 'grouper' ? response.data.data.data.questionary : response.data.data.questionary).find((i) => {if(i.id == item.question_id){actual_page = 0;}})
                        }

                        if(item.type === 'grouper'){
                            response.data.data.tabs.forEach((i,k) => {
                                response.data.data.tabs[k]['uuid'] = i.id;
                                response.data.data.tabs[k]['id'] = k + 1;
                            });

                            await state.commit("loadQuizzTabs", response.data.data.tabs);
                            await state.commit("loadQuizzChart", response.data.data.chart);
                            router.options.GhSubTabsQuestionary = response.data.data.tabs;
                        }

                        await state.commit("loadQuizzPreviewData", item.type === 'grouper' ? response.data.data.data : response.data.data);
                        await state.commit("loadActualPagePreview", actual_page);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSpecificPreviewQuizz(state,item){
            var tab_data = '';
            for(var i=0;i < router.options.GhSubTabsQuestionary.length;i++){
                if(router.options.GhSubTabsQuestionary[i].value === item.value){
                    tab_data = router.options.GhSubTabsQuestionary[i];
                }
            }


            let selected = '';
            state.state.data_quizz.questionary.forEach((i, k) => {
                if(i.id === tab_data.uuid){
                    selected = k;
                }
            })
            await state.commit("loadActualPagePreview", selected);
        },
        async cleanPreviewPages(state){
            await state.commit("loadActualPagePreview", 0);
        },
        async getExecutePreviewSupport(state, item){
            await axios.get(
                API_URL + item.type + "/preview/" + item.id + "/support"
            ).then(
                async response => {
                    await state.commit('loadExecutePreviewSupport', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeExerciseToHistoric(state, item){
            let requestLogin = API_URL + "exercise/to-historic/" + item.id;
            let formData = new FormData();

            formData.append('exercise[id]', item.id);
            formData.append('exercise[type]', item.type);

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getExercises');
                        await this.dispatch('getExercisesHistoric');
                        await this.dispatch('getExercisesPending');
                        await this.dispatch('getExercisesEnabled');
                        await this.dispatch('getConfigExercisesCounters');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeExerciseVersion(state, item){
            let requestLogin = API_URL + "exercise/versioning/" + item.id;
            let formData = new FormData();

            formData.append('exercise[id]', item.id);
            formData.append('exercise[type]', item.type);

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getExercises');
                        await this.dispatch('getExercisesHistoric');
                        await this.dispatch('getExercisesPending');
                        await this.dispatch('getExercisesEnabled');
                        await this.dispatch('getConfigExercisesCounters');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeExerciseCopy(state, item){
            let requestLogin = API_URL + "exercise/copy/" + item.id;
            let formData = new FormData();

            formData.append('exercise[id]', item.id);
            formData.append('exercise[type]', item.type);

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        router.push({name: 'base_creation'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async cleeanTypeExercise(state){
            await state.commit('loadCleanExerciseByType', []);
            state.commit('loadTemporalSubquestionExercises', []);
            state.state.data.metrics = [];
            state.state.records.added = [];
            state.state.records.deleted = [];
        },
        async getTypeExercise(state, item){
            await axios.get(
                API_URL + 'type-exercises/' + item.id
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadTemporalSubquestionExercises', []);
                        state.state.data.metrics = [];
                        state.state.records.added = [];
                        state.state.records.deleted = [];
                        state.commit('loadExerciseByType', response.data.data);
                    }
                }
            )
        },
        async getExerciseById(state, item){
            await axios.get(
                API_URL + 'exercise/info-to-subquestion/' + item.id
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadTemporalSubquestionNewExercises', response.data.data);
                    }
                }
            )
        },
        async setTemporalMetrics(state, item){
            var formData = {
                'id': 'T' + state.state.data.metrics.length + 1,
                'name': item.formData[0][1],
                'description': item.formData[1][1],
            }

            state.commit("loadAddTemporalMetrics", formData);
        },
        async setMetrics(state, item){
            item.data.forEach((metric) => {
                state.commit("loadMetrics", metric);
            });
        },
        async removeTemporalMetrics(state,items){
            var index = state.state.records.added.findIndex(metrics => metrics.id == items.id);

            if(index != '-1'){
                state.state.records.added.splice(index, 1);
            }else{
                var params = {
                    'id': items.id
                };
                state.state.records.deleted.push(params);
            }

            var indexTable = state.state.data.metrics.findIndex(data => data.id == items.id);
            state.state.data.metrics.splice(indexTable, 1);

            state.state.refreshMetrics = !state.state.refreshMetrics;
        },
        async setTemporalSubquestionExercises(state, item){
            state.commit('loadTemporalSubquestionExercises', item.data);
        },
        async getExercisesData(state,item){
            if(item.id === undefined){
                state.commit("loadExerciseData", []);
            }else{
                let  requestLogin = API_URL + "exercise/" + item.id + (item.grouper_id !== undefined ? '/' + item.grouper_id : '') + (item.version ? '/version' : '') + (item.view !== undefined && item.view !== null ? '?view=true' : '');
                await axios({
                    url: requestLogin,
                }).then(
                    response => {
                        state.commit("loadExerciseData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async removeParentExerciseVersion(state, item){
            let requestLogin = API_URL + "exercise/subquestion/versioning/" + item.id;
            let formData = new FormData();

            formData.append('exercise[id]', item.id);
            formData.append('exercise[type]', item.type);

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        state.commit('loadTemporalSubquestionNewExercises', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setExerciseData(state, item){
            let requestLogin = item.id ? API_URL + "exercise/" + item.type + "/" + item.id + (item.version ? '/version' : ''): API_URL + "exercise/" + item.type;
            let formData = new FormData();


            if(item.parent_grouper_id !== undefined && item.grouper_id !== undefined){
                requestLogin += '/' + item.parent_grouper_id + '/' + item.grouper_id;
            }

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            state.state.data.subquestions.forEach((i) => {
                formData.append('exercise[subquestion][]', i.id);
            });

            state.state.records.added.forEach((i,index) => {
                formData.append('exercise[metrics][added][' + index + '][name]', i.name);
                formData.append('exercise[metrics][added][' + index + '][description]', i.description);
            });

            state.state.records.deleted.forEach((i) => {
                formData.append('exercise[metrics][deleted][]', i.id);
            });

            if(item.version) {
                formData.append('exercise[version]', item.version);
            }

            return await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        state.commit('destroyComponent');

                        if (item.version === undefined) {
                            if (item.type !== 'save') {
                                if ((item.from !== undefined && item.from === 'grouper-exercise') || (item.from !== undefined && item.from === 'parent-exercise') || (item.children !== undefined && item.children === 'children')) {
                                    window.close();
                                } else {
                                    router.push({name: 'base_creation'});
                                }
                            } else if (response && response.data && response.data.data !== undefined && response.data.data !== item.id) {
                                let name = 'config_exercise_id';
                                let params = {
                                    'id': response.data.data,
                                    'type': (item.type_url !== undefined ? item.type_url : 'elaboration')
                                };

                                if (item.children !== undefined && item.children === 'children') {
                                    params['children'] = 'children';
                                    name = 'config_exercise_children_id_type';
                                }

                                if (item.from !== undefined && item.from === 'grouper-exercise') {
                                    params['from'] = 'grouper-exercise';
                                    params['grouper_id'] = item.parent_grouper_id;
                                    params['from_id'] = item.grouper_id;
                                    name = 'config_exercise_from_grouper_id';
                                }

                                router.push({
                                    name: name,
                                    params: params
                                });
                            } else if (response && response.data && response.data.data !== undefined && response.data.data === item.id) {
                                await state.dispatch('getExercisesData', {
                                    id: item.id,
                                    view: undefined,
                                    grouper_id: item.grouper_id,
                                    version: item.version,
                                }, {root: true});
                            }

                            if (item.type === 'save') {
                                this._vm.$toast(item.keyTranslate['changes_have_been_saved_correctly'], {
                                    position: "bottom-right",
                                    timeout: 2000,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 0.6,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: false,
                                    closeButton: "button",
                                    icon: true,
                                    rtl: false
                                });
                            }

                            return response.data.data;
                        } else {
                            window.close();
                        }
                    }
                },
                error => {
                    if(error.response.status === 333){
                        return error.response;
                    }
                }
            )
        },
    }
};
