var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-3px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: _vm.Login.perm_support,
            secondWindow: true,
            showLoader: 'OK',
            text: _vm.$t('substatement'),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')}}}),_c('div',{staticClass:"gh-content-global-questionnaire"},[_c('div',{staticClass:"question-body"},[_c('div',{staticClass:"gh-statement-content T-subtitle"},[_c('div',{staticClass:"T-subtitle"},[_vm._v(" "+_vm._s(_vm.getterSubstatementByMultistatement.statement)+" ")])]),_c('div',{staticClass:"separator-line",staticStyle:{"margin-top":"unset"}}),_c('div',{staticClass:"gh-answer-content"},[_c('label',{staticClass:"gh_text_field-label T-text",domProps:{"innerHTML":_vm._s(_vm.getterSubstatementByMultistatement.substatement)}})])]),_c('div',{staticClass:"centered"},[_c('GhButton',{staticStyle:{"margin-bottom":"20px","margin-top":"-10px"},attrs:{"datainput":{
                    id: 'btn_close_indications',
                    text: _vm.$t('close'),
                    class: 'T19 container-md_button_content button-questionnaire-primary'
                },"title":_vm.$t('close')},on:{"click":_vm.close}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }