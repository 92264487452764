<template>
    <div style="display: flex">
        <label v-if="datalabel.text != '' && datalabel.text != undefined"
               :id=datalabel.id
               :class="datalabel.class"
               :style="datalabel.styles"
               class="gh_text_field-label T15"
        >
            {{datalabel.text}}
        </label>

        <template v-if="!datainput.edited">
            <textarea
                :id=datainput.id
                :class="[datainput.class,datainput.required ? 'required' : '']"
                :name="datainput.name"
                :style="datainput.styles"
                v-model="hiddenValue"
                v-on="$listeners"
                v-bind="$attrs"
                :required="datainput.required ? true : false"
                :label_required="[datalabel.text ? datalabel.text : datainput.label_required]"
                class="T17 gh_text_field-input gh_textarea-double"
            />
        </template>
        <template v-else>
            <div class="content-edited-textarea">
                <div class="header-edited-textarea">
                    <div class="btn-right-border" :key="refreshBar">
                        <GhColor
                            :datalabel="{id: ''}"
                            :datainput="{id: 'edited_textarea_color_picker_' + datalabel.id, name: '', style: '', class: 'color-textarea', value: color, required: '', hidden: 'hidden'}"
                            :datablock="{id: '',class: '',style: ''}"
                            :ref="'RefColorTextEditor' + datalabel.id"
                            @change="getColorCommand"
                        />
                    </div>
                    <button ref="RefBtnB" type="button" :class="btnClassBold" class="btn-textarea btn-right-border" data-element="bold" v-if="datainput.image.bold" @click="doStyle('bold')">
                        <img :src="datainput.image.bold" width="16">
                    </button>
                    <button ref="RefBtnI" type="button" :class="btnClassItalic" class="btn-textarea btn-right-border" data-element="italic" v-if="datainput.image.italic" @click="doStyle('italic')">
                        <img :src="datainput.image.italic" width="16">
                    </button>
                    <button ref="RefBtnU" type="button" :class="btnClassUnderline" class="btn-textarea btn-right-border" data-element="underline" v-if="datainput.image.underline" @click="doStyle('underline')">
                        <img :src="datainput.image.underline" width="16">
                    </button>
                    <button ref="RefBtnSub" type="button" :class="btnClassSubscript" class="btn-textarea btn-right-border" data-element="subscript" v-if="datainput.image.subscript" @click="doStyle('subscript')">
                        <img :src="datainput.image.subscript" width="16">
                    </button>
                    <button ref="RefBtnSup" type="button" :class="btnClassSupscript" class="btn-textarea btn-right-border" data-element="superscript" v-if="datainput.image.superscript" @click="doStyle('superscript')">
                        <img :src="datainput.image.superscript" width="16">
                    </button>
                </div>
                <div :id="'body_edited_textarea_' + datainput.id" style="color: black; line-height: 18px;" class="body-edited-textarea T17 gh_textarea-double" :class="[datainput.class,datainput.required ? 'required' : '']" :contenteditable="!dragging" :ref="'RefEditableDiv' + datainput.id" v-html="datainput.value"
                     @click="detectSelectedTags();"
                     @focusout="handleInputWithoutEmiter(); focusOut();"
                     @keyup="handleInput();"
                     @paste="handlePaste"
                     @dragover="preventDrag;"
                     @drop.prevent
                     @mousedown="handleMouseDown"
                     @mousemove="handleMouseMove"
                     @mouseup="handleMouseUp"
                     @dragenter="dragging = true;"
                     @dragleave="dragging = false"
                />
            </div>
            <textarea :key="'REFRESH_' + datainput.id + '_' + refresher" hidden :name="datainput.name" :id="datainput.id" :class="[datainput.class,datainput.required ? 'required' : '']" :required="datainput.required ? true : false" :label_required="[datalabel.text ? datalabel.text : datainput.label_required]" v-model="hiddenValue" v-bind="$attrs" @keyup="$emit('emiterTextarea')" @paste="$emit('emiterTextarea')" @change="$emit('emiterTextarea')" @input="$emit('emiterTextarea')"></textarea>
        </template>
    </div>
</template>

<script>
    import GhColor from "fe-gh-color-lib";

    export default {
        name: "GhTextarea",
        components: {
            GhColor
        },
        props: ['datalabel', 'datainput'],
        data() {
            return {
                lastText: '',
                color: null,
                hiddenValue: this.datainput.value !== undefined ? this.datainput.value : '',
                refreshBar: 0,
                className: 'active-btn',
                btnClassBold: '',
                btnClassItalic: '',
                btnClassUnderline: '',
                btnClassSubscript: '',
                btnClassSupscript: '',
                dragging: false,
                isMouseDown: false,
                selectionRange: null,
                refresher: 0
            }
        },
        mounted() {
            this.hiddenValue = this.datainput.value !== undefined ? this.datainput.value : '';
            this.hiddenValue = this.hiddenValue.replaceAll('<div>','</br>');
            this.hiddenValue = this.hiddenValue.replaceAll('</div>','');
        },
        watch: {
            'hiddenValue'(data){
                if(data !== undefined){
                    this.$emit('emiterTextarea');
                }
            }
        },
        methods: {
            preventDrag(event) {
                const isHtmlContent = event.target.closest && event.target.closest('[contenteditable="true"]') !== event.currentTarget;
                if (isHtmlContent) {
                    event.preventDefault();
                }
            },
            handleMouseDown() {
                this.selectionRange = null;
                this.isMouseDown = true;
            },
            handleMouseMove() {
                if (this.isMouseDown) {
                    this.saveSelection();
                }
            },
            handleMouseUp() {
                this.isMouseDown = false;
                if (this.selectionRange) {
                    this.restoreSelection();
                }
            },
            saveSelection() {
                const selection = window.getSelection();
                if (selection.rangeCount > 0) {
                    this.selectionRange = selection.getRangeAt(0).cloneRange();
                }
            },
            restoreSelection() {
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(this.selectionRange);
            },
            getColorCommand() {
                if (!this.selectionRange || this.selectionRange.endOffset === 0 || !this.selectionRange.commonAncestorContainer) return;

                const color = this.$refs['RefColorTextEditor' + this.datalabel.id].datainput.value;

                if (this.selectionRange.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
                    const span = document.createElement('span');
                    span.style.color = color;
                    this.selectionRange.surroundContents(span);
                } else {
                    const tempContainer = document.createElement('span');
                    tempContainer.appendChild(this.selectionRange.cloneContents());

                    this.applyColorToNodes(tempContainer, color);

                    this.selectionRange.deleteContents();
                    this.selectionRange.insertNode(tempContainer);
                }

                this.selectionRange = null;
            },
            applyColorToNodes(node, color) {
                if (node.nodeType === Node.TEXT_NODE) {
                    const span = document.createElement('span');
                    span.style.color = color;
                    span.appendChild(document.createTextNode(node.textContent));
                    node.parentNode.replaceChild(span, node);
                } else {
                    const childNodes = Array.from(node.childNodes);
                    childNodes.forEach(child => {
                        this.applyColorToNodes(child, color);
                    });

                    if (node.nodeType === Node.ELEMENT_NODE) {
                        node.style.color = color;
                    }
                }
            },
            sanitizeHTML() {
                const editableDiv = this.$refs['RefEditableDiv' + this.datainput.id];
                const emptyElements = editableDiv.querySelectorAll(':empty');

                emptyElements.forEach(element => {
                    if (!element.offsetHeight && !element.offsetWidth) {
                        element.parentNode.removeChild(element);
                    }
                });
            },
            updateCursorToEnd() {
                const editableDiv = this.$refs['RefEditableDiv' + this.datainput.id];
                const range = document.createRange();
                const selection = window.getSelection();

                range.selectNodeContents(editableDiv);
                range.collapse(false); // Colapsar al final del contenido
                selection.removeAllRanges();
                selection.addRange(range);
            },
            capturePaste(event) {
                event.preventDefault();

                const pasteText = (event.clipboardData || window.clipboardData).getData('text');
                const textWithoutHtml = pasteText.replace(/<[^>]+>/g, '');
                const selection = window.getSelection();
                if (selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    range.deleteContents();
                    range.insertNode(document.createTextNode(textWithoutHtml));

                    const newRange = document.createRange();
                    newRange.setStartAfter(range.endContainer, range.endOffset);
                    newRange.collapse(true);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                }
            },
            handlePaste(event) {
                event.preventDefault();

                const clipboardData = event.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = pastedData;

                const cleanedData = this.cleanPastedData(tempDiv.innerHTML, event);

                const selection = window.getSelection();
                if (cleanedData !== undefined && selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    range.deleteContents();

                    const fragment = document.createDocumentFragment();
                    const newContent = document.createElement('div');
                    newContent.innerHTML = cleanedData;

                    if(this.$attrs !== undefined && this.$attrs.tolerance !== undefined) {
                        const words = newContent.innerText.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').replace(/\n/g, ' ').trim().split(/\s+/).filter(i => i !== '');

                        if (words.length > this.$attrs.tolerance) {
                            newContent.innerText = words.slice(0, this.$attrs.tolerance).join(' ');
                            this.updateCursorToEnd();
                        }
                    }

                    while (newContent.firstChild) {
                        fragment.appendChild(newContent.firstChild);
                    }
                    range.insertNode(fragment);

                    range.collapse(false);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }

                this.handleInputWithoutEmiter();
                this.$emit('emiter');
                this.refresher++;
            },
            cleanPastedData(data, event) {
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = data;

                const unwantedTags = ['style', 'meta', 'script', 'link'];
                unwantedTags.forEach(tag => {
                    const elements = tempDiv.getElementsByTagName(tag);
                    while (elements.length > 0) {
                        elements[0].parentNode.removeChild(elements[0]);
                    }
                });

                const elementsWithStyles = tempDiv.querySelectorAll('*');
                elementsWithStyles.forEach(element => {
                    const color = element.style.color
                    element.removeAttribute('style');
                    element.style.color = color;
                });

                if(!tempDiv.innerHTML.includes('<div') && tempDiv.innerHTML.includes('<!--StartFragment-->')) {
                    tempDiv.innerHTML = tempDiv.innerHTML.split('<!--StartFragment-->')[1];
                    tempDiv.innerHTML = tempDiv.innerHTML.split('<!--EndFragment-->')[0];

                    return tempDiv.innerHTML;
                }else{
                    this.capturePaste(event);
                }
            },
            doStyle(element) {
                document.execCommand(element, false, null);
                this.sanitizeHTML();
                this.detectSelectedTags();
            },
            handleInput() {
                this.hiddenValue = document.getElementById('body_edited_textarea_' + this.datainput.id).innerHTML;
                this.hiddenValue = this.hiddenValue.replaceAll('<div>','</br>');
                this.hiddenValue = this.hiddenValue.replaceAll('</div>','');

                if(this.$attrs !== undefined && this.$attrs.tolerance !== undefined){
                    var words = this.hiddenValue.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').replace(/\n/g, ' ').trim().split(/\s+/).filter(i => i !== '');

                    if (this.$attrs.tolerance !== '' && words.length > this.$attrs.tolerance) {
                        this.hiddenValue = words.slice(0, this.$attrs.tolerance).join(' ');
                        this.$refs['RefEditableDiv' + this.datainput.id].innerHTML = this.hiddenValue;
                        this.updateCursorToEnd();
                    }
                }

                this.$emit('emiter');
                this.refresher++;
            },
            handleInputWithoutEmiter() {
                this.hiddenValue = document.getElementById('body_edited_textarea_' + this.datainput.id).innerHTML;
                this.hiddenValue = this.hiddenValue.replaceAll('<div>','</br>');
                this.hiddenValue = this.hiddenValue.replaceAll('</div>','');
            },
            detectSelectedTags() {
                const selection = window.getSelection();
                if (selection && selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);

                    const getAncestorTags = (node, tags = []) => {
                        if (node && node.tagName !== 'DIV' && node.tagName !== 'FONT') {
                            if (node.tagName === 'SPAN' && node.style.color) {
                                const componentToHex = (c) => {
                                    var hex = c.toString(16);
                                    return hex.length === 1 ? "0" + hex : hex;
                                }

                                const rgbToHex = (r, g, b) => {
                                    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
                                }

                                if (node.style.color !== '') {
                                    console.log(node.style.color);
                                    if(node.style.color.includes('rgb')) {
                                        var rgb = node.style.color.split('rgb(')[1].split(')')[0].split(',');
                                        this.color = rgbToHex(parseInt(rgb[0].trim()), parseInt(rgb[1].trim()), parseInt(rgb[2].trim()));
                                    }else{
                                        const colorNamesToHex = {
                                            "black": "#000000",
                                            "white": "#FFFFFF",
                                            "red": "#FF0000",
                                            "green": "#008000",
                                            "blue": "#0000FF",
                                            "yellow": "#FFFF00",
                                            "cyan": "#00FFFF",
                                            "magenta": "#FF00FF",
                                            "gray": "#808080",
                                            "lightgray": "#D3D3D3",
                                            "darkgray": "#A9A9A9",
                                            "orange": "#FFA500",
                                            "purple": "#800080",
                                            "pink": "#FFC0CB",
                                            "brown": "#A52A2A",
                                        };
                                        this.color = colorNamesToHex[node.style.color];
                                    }
                                } else {
                                    this.color = '#000000';
                                }
                                this.refreshBar++;
                            }

                            tags.push(node.tagName);
                            getAncestorTags(node.parentNode, tags);
                        }
                        return tags;
                    };

                    const startTags = getAncestorTags(range.startContainer.parentNode);
                    if (!startTags.includes('SPAN')) {
                        this.color = '#000000';
                    }
                    this.btnClassBold = startTags.includes('B') ? this.className : '';
                    this.btnClassItalic = startTags.includes('I') ? this.className : '';
                    this.btnClassUnderline = startTags.includes('U') ? this.className : '';
                    this.btnClassSubscript = startTags.includes('SUB') ? this.className : '';
                    this.btnClassSupscript = startTags.includes('SUP') ? this.className : '';

                    return startTags;
                }
            },
            focusOut() {
                this.btnClassBold = '';
                this.btnClassItalic = '';
                this.btnClassUnderline = '';
                this.btnClassSubscript = '';
                this.btnClassSupscript = '';
            }
        }
    }
</script>