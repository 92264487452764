<template>
    <div :key="'KEY70' + remountComponent" :style="$root._route.params.from === 'grouper-exercise' || $root._route.params.from === 'parent-exercise' || $root._route.params.children === 'children' || $root._route.params.version || ($root._route.params.type === undefined && $root._route.params.view) ? 'margin-top: -4px;' : ''" class="float_window">
        <GhLocation v-if="$root._route.params.from === 'grouper-exercise' || $root._route.params.from === 'parent-exercise' || $root._route.params.children === 'children' || $root._route.params.version || ($root._route.params.type === undefined && $root._route.params.view)"
            :dataLocation="{
                showButton: Login.perm_support,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: $t('exercise'),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />
        <div class="content_page">
            <GhNoDropSelector :datablock="{id: 'exercise', title: $t('exercise')}">
                <template v-slot:content>
                    <template v-if="haveData">
                        <form id="form_exercise">
                            <div class="gh-content-global">
                                <div v-if="$root._route.params.type !== undefined && $root._route.params.type != 'elaboration' && Object.values($root._route.params).length !== 0" class="gh-row-content">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data.code}}</label>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled' && $root._route.params.id === undefined">
                                        <GhTreeSelectorBlock
                                            :dataBlock="{
                                                type: 'radio',
                                                id: 'label_exercise_type',
                                                label: $t('type_of_exercise') + '*',
                                                title: $t('type_of_exercise'),
                                                text: $t('select_type_of_exercise') + ':',
                                                name: 'exercise[type]',
                                                id_value: 'value',
                                                name_label: 'text',
                                                child_key: 'children',
                                                required: true,
                                                checkparentdata: false,
                                                style: '',
                                                label_style: 'width: 149px',
                                                popupStyle: 'min-width: 900px;width: 55%;'
                                            }"
                                            :selected_input="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.type !== undefined && BaseCreationExerciseTable.exercise_data.type.length != 0 ? BaseCreationExerciseTable.exercise_data.type : []"
                                            :items="getFeeds.feed_type_exercise"
                                            ref="RefTypeOfExercise"
                                            @accept="changeType()"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('type_of_exercise') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined ? Object.values(BaseCreationExerciseTable.exercise_data.type)[0] : ''}}</label>
                                        <input type="hidden" name="exercise[type]" :value="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.type !== undefined && BaseCreationExerciseTable.exercise_data.type.length != 0 ? Object.keys(BaseCreationExerciseTable.exercise_data.type)[0] : ''">
                                    </template>
                                </div>
                                <div class="gh-row-content" :style="$root._route.params.view || $root._route.params.type === 'enabled' ? '' : 'margin-bottom: 10px'">
                                    <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                                        <GhTreeSelectorBlock
                                            :dataBlock="{
                                                type: 'radio',
                                                id: 'label_topic',
                                                label: $t('topic') + '*',
                                                title: $t('topic'),
                                                text: $t('select_topic') + ':',
                                                name: 'exercise[topic]',
                                                id_value: 'value',
                                                name_label: 'text',
                                                child_key: 'children',
                                                required: true,
                                                checkparentdata: true,
                                                style: '',
                                                label_style: 'width: 149px',
                                                popupStyle: 'min-width: 900px; width: 55%;'
                                            }"
                                            :selected_input="BaseCreationExerciseTable.exercise_data.topic !== undefined && BaseCreationExerciseTable.exercise_data.topic.length !== 0 ? BaseCreationExerciseTable.exercise_data.topic : []"
                                            :items="getFeeds.feed_topic"
                                            ref="refTopic"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('topic') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data.topic !== undefined ? BaseCreationExerciseTable.exercise_data.topic[0].text : ''}}</label>
                                    </template>
                                </div>

                                <component v-bind:is="components.type_exercise"/>
                                <component v-bind:is="components.subquestions"/>
                                <component v-bind:is="components.conditions"/>
                                <component v-bind:is="components.support"/>
                                <component v-bind:is="components.know_more"/>
                                <component v-bind:is="components.associate"
                                    v-if="getterTypeExercise.referentials !== undefined"
                                    :props="{
                                        name:'exercise',
                                        selecteds:{
                                             groupers: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.associate !== undefined && BaseCreationExerciseTable.exercise_data.associate.groupers !== undefined ? BaseCreationExerciseTable.exercise_data.associate.groupers : [],
                                        }
                                    }"
                                />
                                <component
                                    v-if="getterTypeExercise.referentials !== undefined"
                                    v-bind:is="components.reference_thesauros"
                                    :props="{
                                        name:'exercise',
                                        selecteds:{
                                            groupers: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.groupers !== undefined ? BaseCreationExerciseTable.exercise_data.groupers : [],
                                            topics: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.topics !== undefined ? BaseCreationExerciseTable.exercise_data.topics : [],
                                            exercises: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.exercises !== undefined ? BaseCreationExerciseTable.exercise_data.exercises : [],
                                            thesauros: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.thesauros !== undefined ? BaseCreationExerciseTable.exercise_data.thesauros : [],
                                        },
                                        view:{
                                            groupers: true,
                                            topics: false,
                                            exercises: true,
                                            thesauros: false,
                                        },
                                        fixTopics: topicFixed
                                    }"
                                />

                                <template v-if="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.notes !== undefined && BaseCreationExerciseTable.exercise_data.notes.length !== 0">
                                    <div class="section_line" style="margin-top: 20px;">
                                        <div class="T15_b">{{$t('situation')}}</div>
                                    </div>
                                    <div class="new-striped" style="margin-top: 10px;">
                                        <template v-for="(note, indexNote) in BaseCreationExerciseTable.exercise_data.notes">
                                            <div style="padding-left: 5px; padding-top: 3px; padding-bottom: 3px; display: flex;" :key="'NOTE' + indexNote">
                                                <div style="width: 100%; display: flex;">
                                                    <div class="T15" style="margin-left: 5px; margin-top: 1px;">{{note.text}}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </form>
                    </template>
                    <template v-else-if="!haveData">
                        <div class="centered" style="padding-bottom: 10px; padding-top: 10px;">
                            <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                        </div>
                    </template>
                </template>
            </GhNoDropSelector>
        </div>

        <div class="centered_footer" style="margin-top: 8px; padding-bottom: 8px;">
            <template v-if="haveData">
                <template v-if="$root._route.params.version && ($root._route.params.type === 'elaboration' || $root._route.params.type === 'pending')">
                    <GhButton v-if="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.finish"
                        :datainput="{
                            id: 'btn_finish',
                            text: $root._route.params.version ? $t('enable') : $t('finish')
                        }"
                        @click="submit('finish')"
                    />
                    <GhButton
                        :datainput="{
                            id: 'btn_save',
                            text: $t('save')
                        }"
                        @click="submit('save')"
                    />
                </template>
                <template v-else>
                    <GhButton v-if="!$root._route.params.version && !$root._route.params.view && BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.finish && $root._route.params.type === 'elaboration'"
                        :datainput="{
                            id: 'btn_finish',
                            text: $root._route.params.version ? $t('enable') : $t('finish')
                        }"
                        @click="submit('finish')"
                    />
                    <GhButton v-if="!$root._route.params.version && !$root._route.params.view"
                        :datainput="{
                            id: 'btn_save',
                            text: $t('save')
                        }"
                        @click="submit('save')"
                    />
                    <GhButton v-if="$root._route.params.type === 'pending' && !$root._route.params.version && !$root._route.params.view"
                        :datainput="{
                            id: 'btn_enable',
                            text: $t('enable')
                        }"
                        @click="submit('enable')"
                    />
                    <GhButton v-if="$root._route.params.type === 'pending' && !$root._route.params.version && !$root._route.params.view"
                        :datainput="{
                            id: 'btn_copy',
                            text: $t('copy')
                        }"
                        @click="Global.deleteConfirm('delete_confirm','ExerciseCopy', $root._route.params.id,{'code': BaseCreationExerciseTable.exercise_data.code, 'type': BaseCreationExerciseTable.exercise_data !== undefined && Object.values(BaseCreationExerciseTable.exercise_data.type)[0]}, $t('copy_exercise'), $t('copy_delete_exercise'), 'delete')"
                    />
                    <GhButton v-if="$root._route.params.type === 'pending' && !$root._route.params.version && !$root._route.params.view"
                        :datainput="{
                            id: 'btn_delete',
                            text: $t('delete')
                        }"
                        @click="Global.deleteConfirm('delete_confirm','Exercise', $root._route.params.id,{'code': BaseCreationExerciseTable.exercise_data.code, 'type': BaseCreationExerciseTable.exercise_data !== undefined && Object.values(BaseCreationExerciseTable.exercise_data.type)[0]}, $t('delete_exercise'), $t('preparing_delete_exercise'), 'delete')"
                    />
                </template>
                <GhButton v-if="$root._route.params.version && $root._route.params.type !== 'elaboration' && $root._route.params.type !== 'pending'"
                    :datainput="{
                        id: 'btn_versioning',
                        text: $t('versioning')
                    }"
                    @click="setVersion"
                />
                <GhButton v-if="$root._route.params.type == 'pending' || $root._route.params.type == 'enabled' || (BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.finish)"
                    :datainput="{
                        id: 'btn_preview',
                        text: $t('preview')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('preview_exercise', {id:$root._route.params.id,type:'exercise'}),'preview_exercise')"

                />
            </template>
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $root._route.params.from === 'grouper-exercise' || $root._route.params.from === 'parent-exercise' || $root._route.params.children === 'children' || $root._route.params.version || ($root._route.params.type === undefined && $root._route.params.view) ? $t('close') : $t('back')
                }"
                @click="$root._route.params.from === 'grouper-exercise' || $root._route.params.from === 'parent-exercise' || $root._route.params.children === 'children' || $root._route.params.version || ($root._route.params.type === undefined && $root._route.params.view) ? Global.windowClose() : router.push({name: 'base_creation'})"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTreeSelectorBlock from 'fe-gh-tree-selector-block-lib';
    import GhDataSelector from 'fe-gh-data-selector-lib';
    import GhButton from "fe-gh-button-lib";

    import type_open_question from "@/view/base_creation/exercises/exercises/template/includes/type_open_question.vue";
    import type_close_numeric from "@/view/base_creation/exercises/exercises/template/includes/type_close_numeric.vue";
    import type_close_one_correct from "@/view/base_creation/exercises/exercises/template/includes/type_close_one_correct.vue";
    import type_close_n_correct from "@/view/base_creation/exercises/exercises/template/includes/type_close_n_correct.vue";
    import type_simple_question from "@/view/base_creation/exercises/exercises/template/includes/type_simple_question.vue";
    import type_questionnaire from "@/view/base_creation/exercises/exercises/template/includes/type_questionnaire.vue";
    import type_multi_open from "@/view/base_creation/exercises/exercises/template/includes/type_multi_open.vue";
    import type_numeric_questionnaire from "@/view/base_creation/exercises/exercises/template/includes/type_numeric_questionnaire.vue";

    import subquestions from "@/view/base_creation/exercises/exercises/template/shared/subquestion.vue";
    import conditions from "@/view/base_creation/exercises/exercises/template/shared/conditions.vue";
    import support from "@/view/base_creation/exercises/exercises/template/shared/support.vue";
    import know_more from "@/view/base_creation/exercises/exercises/template/shared/know_more.vue";
    import associate from "@/view/base_creation/exercises/exercises/template/shared/associate.vue";
    import reference_thesauros from "@/view/base_creation/exercises/exercises/template/shared/reference_thesauros.vue";

    import {mapGetters, mapState} from "vuex";
    import GhAction from "fe-gh-action-lib";
    import GhLocation from "fe-gh-location-lib";

    export default {
        name: "exercise_template",
        components: {
            GhLocation,
            GhAction,
            GhNoDropSelector,
            GhInputTextField,
            GhTreeSelectorBlock,
            GhDataSelector,
            GhButton
        },
        computed: {
            ...mapState(['BaseCreationExerciseTable', 'getFeeds','GlobalStore', 'Login']),
            ...mapGetters(['getterTypeExercise']),
        },
        data(){
            return {
                haveData: false,
                components: {
                    type_exercise: '',
                    subquestions: subquestions,
                    conditions: conditions,
                    support: support,
                    know_more: know_more,
                    associate: associate,
                    reference_thesauros: reference_thesauros,
                    references: ''
                },
                topicFixed: [],
                remountComponent: 0,
                version: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                if (this.$root._route.params.parent_grouper_id !== undefined) {
                    localStorage.setItem('is_saved_element', this.$root._route.params.id);
                }

                await this.$store.dispatch('cleeanTypeExercise');
                await this.$store.dispatch('getFeedTypeExercise', '', {root: true});
                await this.$store.dispatch('getFeedTopic', '', {root: true});
                await this.$store.dispatch('getExercisesData', {
                    id: this.$root._route.params.id,
                    view: this.$root._route.params.view,
                    grouper_id: this.$root._route.params.parent_id,
                    version: this.$root._route.params.version
                }, {root: true});

                if (this.$root._route.params.id !== undefined && this.BaseCreationExerciseTable.exercise_data !== undefined && this.BaseCreationExerciseTable.exercise_data.type !== undefined && this.BaseCreationExerciseTable.exercise_data.type.length != 0) {
                    await this.changeType(Object.keys(this.BaseCreationExerciseTable.exercise_data.type)[0]);
                    this.topicFixed = this.BaseCreationExerciseTable.exercise_data !== undefined && this.BaseCreationExerciseTable.exercise_data.topic !== undefined ? this.BaseCreationExerciseTable.exercise_data.topic : [];
                }

                if (this.getterTypeExercise.referentials !== undefined) {
                    await this.$store.dispatch('getFeedReferenceThesauros', '', {root: true});
                    await this.$store.dispatch('getFeedExercises', '', {root: true});
                    await this.$store.dispatch('getFeedGroupers', '', {root: true});
                    await this.$store.dispatch('getFeedTopic', '', {root: true});
                }

                this.haveData = true;
            }
        },
        mounted() {
            this.setRemountComponent();
        },
        watch: {
            async 'BaseCreationExerciseTable.shouldDestroyComponent'(newValue) {
                if (newValue) {
                    this.haveData = false;
                    await this.$store.dispatch('cleeanTypeExercise');
                    await this.$store.dispatch('getFeedTypeExercise', '',{root:true});
                    await this.$store.dispatch('getFeedTopic','',{root:true});
                    await this.$store.dispatch('getExercisesData',{
                        id:this.$root._route.params.id,
                        view: this.$root._route.params.view,
                        grouper_id: this.$root._route.params.parent_id,
                        version: this.$root._route.params.version
                    },{root:true});

                    if(this.$root._route.params.id !== undefined && this.BaseCreationExerciseTable.exercise_data !== undefined && this.BaseCreationExerciseTable.exercise_data.type !== undefined && this.BaseCreationExerciseTable.exercise_data.type.length != 0){
                        await this.changeType(Object.keys(this.BaseCreationExerciseTable.exercise_data.type)[0]);
                        this.topicFixed = this.BaseCreationExerciseTable.exercise_data !== undefined && this.BaseCreationExerciseTable.exercise_data.topic !== undefined ? this.BaseCreationExerciseTable.exercise_data.topic : [];
                    }

                    if(this.getterTypeExercise.referentials !== undefined){
                        await this.$store.dispatch('getFeedReferenceThesauros','',{root:true});
                        await this.$store.dispatch('getFeedExercises','',{root:true});
                        await this.$store.dispatch('getFeedGroupers','',{root:true});
                        await this.$store.dispatch('getFeedTopic','',{root:true});
                    }

                    this.haveData = true;
                }
            }
        },
        methods: {
            setRemountComponent(){
                this.remountComponent++;
            },
            async changeType(id){
                await this.$store.dispatch('getTypeExercise',{
                    id: id !== undefined ? id : this.$refs['RefTypeOfExercise'].datasaved.value
                });

                switch(this.getterTypeExercise.type){
                    case 1:
                        this.components.type_exercise = type_open_question;
                        break;
                    case 2:
                        this.components.type_exercise = type_close_numeric;
                        break;
                    case 3:
                        this.components.type_exercise = type_close_one_correct;
                        break;
                    case 4:
                        this.components.type_exercise = type_close_n_correct;
                        break;
                    case 5:
                        this.components.type_exercise = type_simple_question;
                        break;
                    case 6:
                        this.components.type_exercise = type_questionnaire;
                        break;
                    case 7:
                        this.components.type_exercise = type_multi_open;
                        break;
                    case 8:
                        this.components.type_exercise = type_numeric_questionnaire;
                        break;
                }
            },
            async submit(type){
                if(type === 'finish' || type === 'enable'){
                    this.saveExercise(type);
                }else if(type === 'save'){
                    const formData = new FormData(document.getElementById('form_exercise'));
                    if([...formData].length === 0 || [...formData].find((i) => i[0] === 'exercise[type]') === undefined || [...formData].find((i) => i[0] === 'exercise[topic]') === undefined){
                        await this.saveExercise(type);
                    }else {
                        if(this.$root._route.params.parent_grouper_id !== undefined){
                            localStorage.setItem('is_saved_element', true);
                        }

                        await this.$store.dispatch('setExerciseData', {
                            formData: [...formData],
                            type: type,
                            type_url: this.$root._route.params.type,
                            id: this.$root._route.params.id,
                            version: this.$root._route.params.version,
                            from: this.$root._route.params.from,
                            children: this.$root._route.params.children,
                            parent_grouper_id: this.$root._route.params.parent_grouper_id,
                            grouper_id: this.$root._route.params.parent_id,
                            keyTranslate: {
                                'changes_have_been_saved_correctly': this.$t('changes_have_been_saved_correctly')
                            }
                        }, {root: true}).then((response) => {
                            if(response !== undefined) {
                                if(response.status === 333){
                                    var array_requireds = [];
                                    Object.keys(response.data.data).forEach((k) => {
                                        if(k === 'subquestion'){
                                            Object.values(response.data.data.subquestion).forEach((s) => {
                                                array_requireds.push(this.$t('subquestion') + ': ' + s + '*');
                                            })
                                        }else{
                                            array_requireds.push(this.$t(k) + '*');
                                        }
                                    });

                                    this.Global.setRequiredValues(array_requireds);
                                }else {
                                    this.setUpdateSelect(response);
                                }
                            }
                        });
                    }
                }
            },
            async saveExercise(type){
                let enter = false;

                if(type === 'save') {
                    if (this.Global.checkRequired('form_exercise') === 'OK') {
                        enter = true;
                    }
                }

                if(type === 'finish' || type === 'enable'){
                    enter = true;
                }

                if(enter) {
                    if(this.$root._route.params.parent_grouper_id !== undefined){
                        localStorage.setItem('is_saved_element', true);
                    }

                    const formData = new FormData(document.getElementById('form_exercise'));
                    await this.$store.dispatch('setExerciseData', {
                        formData: [...formData],
                        type: type,
                        type_url: this.$root._route.params.type,
                        id: this.$root._route.params.id,
                        version: this.$root._route.params.version,
                        from: this.$root._route.params.from,
                        children: this.$root._route.params.children,
                        parent_grouper_id: this.$root._route.params.parent_grouper_id,
                        grouper_id: this.$root._route.params.parent_id,
                        keyTranslate: {
                            'changes_have_been_saved_correctly': this.$t('changes_have_been_saved_correctly')
                        }
                    }, {root: true}).then((response) => {
                        if(response !== undefined) {
                            if(response.status === 333){
                                var array_requireds = [];
                                Object.keys(response.data.data).forEach((k) => {
                                    if(k === 'subquestion'){
                                        Object.values(response.data.data.subquestion).forEach((s) => {
                                            array_requireds.push(this.$t('subquestion') + ': ' + s + '*');
                                        })
                                    }else{
                                        array_requireds.push(this.$t(k) + '*');
                                    }
                                });

                                this.Global.setRequiredValues(array_requireds);
                            }else {
                                this.setUpdateSelect(response);
                            }
                        }
                    });
                }
            },
            setUpdateSelect(response){
                if((this.$root._route.params.from !== undefined && this.$root._route.params.from === 'grouper-exercise') || (this.$root._route.params.from !== undefined && this.$root._route.params.from === 'parent-exercise') || (this.$root._route.params.children !== undefined && this.$root._route.params.children === 'children')){
                    window.opener.UpdatedSelect({
                        'id': response
                    });
                }
            },
            async setVersion(){
                if(this.$root._route.params.parent_grouper_id !== undefined){
                    localStorage.setItem('is_saved_element', true);
                }

                await this.$store.dispatch('setItemCreateVersion', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.path.split('/')[1]
                }, {root: true}).then((response) => {
                    if(response !== undefined){
                        if(this.$root._route.path.split('/')[1] === 'exercise'){
                            this.Global.windowOpen(this.Global.openSecondWindow('config_exercise_id_version_float',{
                                parent_grouper_id: this.$root._route.params.parent_grouper_id,
                                parent_id: this.$root._route.params.parent_id,
                                element_id: this.$root._route.params.element_id,
                                id: response.id,
                                version: true,
                                type: response.type
                            }),'view_version', true);
                        }else{
                            this.Global.windowOpen(this.Global.openSecondWindow('config_grouper_id_version_float',{
                                parent_grouper_id: this.$root._route.params.parent_grouper_id,
                                parent_id: this.$root._route.params.parent_id,
                                grouper: this.$root._route.params.grouper,
                                id: response.id,
                                version: true,
                                type: response.type
                            }),'view_version', true);
                        }
                    }
                });
            }
        }
    }
</script>