<template>
    <div style="margin-top: -3px;">
        <GhLocation
            :dataLocation="{
                showButton: Login.perm_support,
                secondWindow: true,
                showLoader: 'OK',
                text: $t('substatement'),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="gh-content-global-questionnaire">
            <div class="question-body">
                <div class="gh-statement-content T-subtitle">
                    <div class="T-subtitle">
                        {{getterSubstatementByMultistatement.statement}}
                    </div>
                </div>
                <div class="separator-line" style="margin-top: unset;"></div>
                <div class="gh-answer-content">
                    <label class="gh_text_field-label T-text" v-html="getterSubstatementByMultistatement.substatement"/>
                </div>
            </div>
            <div class="centered">
                <GhButton
                    style="margin-bottom: 20px; margin-top: -10px;"
                    :datainput="{
                        id: 'btn_close_indications',
                        text: $t('close'),
                        class: 'T19 container-md_button_content button-questionnaire-primary'
                    }"
                    :title="$t('close')"
                    @click="close"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapGetters, mapState} from "vuex";

    export default {
        name: "sub_statement",
        components: {
            GhButton,
            GhLocation
        },
        computed: {
            ...mapState(['Login']),
            ...mapGetters(['getterSubstatementByMultistatement'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getSubstatementByMultistatement', {
                association_id: this.$root._route.params.id,
                substatement_id: this.$root._route.params.id_statement
            }, {root: true});

            this.haveData = true;
        },
        methods:{
            close(){
                window.close();
            }
        }
    }
</script>