<template>
    <div class="float_window">
        <GhNoDropSelector v-if="show" :datablock="{id: 'user_file',title: $t('user')}">
            <template v-slot:content v-if="haveData">
                <component v-bind:is="user_file" />
            </template>
        </GhNoDropSelector>

        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'user_'+label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: label['result'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component"/>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import user_file from './user_file/user_file';
    import list_permisions from "./permisions/list_permisions.vue";
    import list_assignments from "./assignments/list_assignments.vue";
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import {mapState} from "vuex";
    export default {
        name: "list_user_file",
        components: {
            GhNoDropSelector,
            GhDropSelector
        },
        computed: {
            ...mapState(['Login']),
        },
        data(){
            return {
                haveData: false,
                show: false,
                user_file: user_file,
                data: [
                    {id: 'permisions', name: 'permissions', component: list_permisions, result: '', show: false, second_text: ''},
                    {id: 'assignments', name: 'assignments', component: list_assignments, result: '', show: false, second_text: ''},
                ],
            }
        },
        beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                this.Login.tabs[15]['sub-modules'].forEach((subroute, subkey) => {
                    if(subroute.name === 'user_file'){
                        this.show = subroute.have_permission;
                    }else {
                        if (this.data[subkey - 1] !== undefined && this.data[subkey - 1].name === subroute.name) {
                            this.data[subkey - 1].show = subroute.have_permission;
                        }
                    }
                });

                this.haveData = true;
            }
        }
    }
</script>