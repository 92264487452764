<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'general' + label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: ConfigEvaluators.counters !== undefined && ConfigEvaluators.counters.counters !== undefined ? ConfigEvaluators.counters.counters[label['result']] : 0,
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';

    import {mapState} from "vuex";

    import list_ccaa from "@/view/config/evaluators/ccaa/list_ccaa.vue";
    import list_club_category from "@/view/config/evaluators/club_category/list_club_category.vue";
    import list_languages from "@/view/config/evaluators/languages/list_languages.vue";
    import list_type_evaluator from "@/view/config/evaluators/type_evaluator/list_type_evaluator.vue";
    import list_professional_category from "@/view/config/evaluators/professional_category/list_professional_category.vue";
    import list_professional_activity_sector from "@/view/config/evaluators/professional_activity_sector/list_professional_activity_sector.vue";
    import list_professional_experience_sector from "@/view/config/evaluators/professional_experience_sector/list_professional_experience_sector.vue";
    import list_type_evaluation from "@/view/config/evaluators/type_evaluation/list_type_evaluation.vue";
    import list_evaluator_modality from "@/view/config/evaluators/evaluator_modality/list_evaluator_modality.vue";
    import list_grade_experience from "@/view/config/evaluators/grade_experience/list_grade_experience.vue";
    import list_score_range from "@/view/config/evaluators/score_range/list_score_range.vue";
    import list_seal_range from "@/view/config/evaluators/seal_range/list_seal_range.vue";
    import list_channel from "@/view/config/evaluators/channel/list_channel.vue";
    import list_professional_experience from "@/view/config/evaluators/professional_experience/list_professional_experience.vue";
    import list_final_punctuation from "@/view/config/evaluators/final_punctuation/list_final_punctuation.vue";
    import list_other_values from "@/view/config/evaluators/other_values/list_other_values.vue";
    import list_club_experience from "@/view/config/evaluators/club_experience/list_club_experience.vue";

    export default {
        name: "list_evaluators",
        components: {
            GhDropSelector,
        },
        computed:{
            ...mapState(['ConfigEvaluators','Login']),
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'ccaa', component: list_ccaa, result: 'ccaa', show: false, second_text: 'results'},
                    {id: 2, name: 'channel', component: list_channel, result: 'channel', show: false, second_text: 'results'},
                    {id: 3, name: 'club_category', component: list_club_category, result: 'club_category', show: false, second_text: 'results'},
                    {id: 4, name: 'professional_category', component: list_professional_category, result: 'professional_category', show: false, second_text: 'results'},
                    {id: 5, name: 'club_experience', component: list_club_experience, result: 'other_values', show: false, second_text: 'results'},
                    {id: 6, name: 'professional_experience', component: list_professional_experience, result: 'professional_experience', show: false, second_text: 'results'},
                    {id: 7, name: 'grade_of_experience', component: list_grade_experience, result: 'grade_of_experience', show: false, second_text: 'results'},
                    {id: 8, name: 'languages', component: list_languages, result: 'languages', show: false, second_text: 'results'},
                    {id: 9, name: 'evaluation_mode', component: list_evaluator_modality, result: 'evaluation_mode', show: false, second_text: 'results'},
                    {id: 10, name: 'other_values', component: list_other_values, result: 'other_values', show: false, second_text: 'results'},
                    {id: 11, name: 'final_punctuation', component: list_final_punctuation, result: 'final_punctuation', show: false, second_text: 'results'},
                    {id: 12, name: 'score_range', component: list_score_range, result: 'score_range', show: false, second_text: 'results'},
                    {id: 13, name: 'stamp_range', component: list_seal_range, result: 'stamp_range', show: false, second_text: 'results'},
                    {id: 14, name: 'professional_activity_sector', component: list_professional_activity_sector, result: 'professional_activity_sector', show: false, second_text: 'results'},
                    {id: 15, name: 'sectors_professional_experience', component: list_professional_experience_sector, result: 'sectors_professional_experience', show: false, second_text: 'results'},
                    {id: 16, name: 'type_evaluators', component: list_type_evaluator, result: 'type_evaluators', show: false, second_text: 'results'},
                    {id: 17, name: 'type_of_evaluation', component: list_type_evaluation, result: 'type_of_evaluation', show: false, second_text: 'results'}
                ],
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getConfigEvaluatorsCounters', '', {root: true});

                if (this.Login.tabs.length !== 0) {
                    this.Login.tabs[4]['sub-modules'][5]['sub-sections'].forEach((subroute, subkey) => {
                        if (this.data[subkey] !== undefined && this.data[subkey].name === subroute.name) {
                            this.data[subkey].show = subroute.have_permission;
                        }
                    });
                }
            }

            this.haveData = true;
        }
    }
</script>
