import Vue from "vue";
import vuex from "vuex";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        subTabActive: '',
        nameSubTabActive:'',
        reload: 0
    },
    mutations: {
        loadSubActiveItem(state, payload) {
            state.subTabActive = payload
        },
        loadNameSubActiveItem(state,payload){
            state.nameSubTabActive = payload
        },
        loadReloadTab(state){
            state.reload++;
        }
    },
    actions: {
        async getConfigTabActive(state, item) {
            var finish_call = item.call;
            var stop = 0;

            state.rootState.Login.tabs[4]['sub-modules'].forEach((i) => {
                if(item.call === i.name && i.have_permission){
                    finish_call = item.call;
                    stop = 1;
                }
            });

            if(stop === 0) {
                state.rootState.Login.tabs[4]['sub-modules'].forEach((i) => {
                    if (stop === 0 && i.have_permission) {
                        finish_call = i.name;
                        stop = 1;
                    }
                });
            }

            var valRouterConfiguration = await router.options.GhSubTabsConfiguration.find((i) => i.call === finish_call);

            localStorage.setItem('config_tab_', valRouterConfiguration.id + 'config_tab_');
            localStorage.setItem('config_tab__selected', JSON.stringify(valRouterConfiguration));

            var valRouter = await router.options.routes.find((i) => i.name === valRouterConfiguration.call);

            state.commit("loadSubActiveItem", valRouter.component !== '' ? valRouter.component : '');
            state.commit("loadNameSubActiveItem", valRouter.name !== '' ? valRouter.name : '');
            state.commit("loadReloadTab");
        },
    }
};
