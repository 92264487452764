<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'users' + label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: PermsUsers.counters[label['result']],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapState} from "vuex";

    import Users from './users/list_users.vue';
    import AssociatedUsers from './associated_users/list_associated_users.vue';

    export default {
        name: 'list_users',
        components: {
            GhDropSelector
        },
        computed: {
            ...mapState(['Login', 'PermsUsers'])
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'users', component: Users, result: 'users', show: false , second_text: 'results'},
                    {id: 2, name: 'associated_users', component: AssociatedUsers, result: '', show: false}
                ],
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getPermsUsers', '', {root: true});

                this.haveData = true;

                if(this.Login.tabs.length !== 0){
                    this.Login.tabs[3]['sub-modules'][1]['sub-sections'].forEach((subroute,subkey) => {
                        if(this.data[subkey] !== undefined && this.data[subkey].name === subroute.name) {
                            this.data[subkey].show = subroute.have_permission;
                        }
                    });
                }
            }
        },
    }
</script>