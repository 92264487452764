var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTreeContentBlock',{staticStyle:{"margin-bottom":"15px","margin-left":"15px","margin-right":"15px"},attrs:{"dataBlock":{
            id: 'historic',
            actionWithoutChildren: true,
            actionWithChildren: true,
            extraLabelNumber: false,
            extraLabelText: '',
            extraTableClass: 'class-table-exercise'
        },"data":_vm.BaseCreationExerciseTable.records_data_historic !== undefined && _vm.BaseCreationExerciseTable.records_data_historic.data !== undefined && _vm.BaseCreationExerciseTable.records_data_historic.data.length === 0 ? [] : _vm.BaseCreationExerciseTable.records_data_historic.data,"header":_vm.header},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'config_exercise_id_view', params: { id: itemProp.id, view: true, type: 'historic'}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}}],null,false,4127051015)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }