var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.$root._route.params.windowed ? 'margin-top: -3px' : '')},[(_vm.$root._route.params.windowed)?_c('GhLocation',{attrs:{"dataLocation":{
            showButton: _vm.Login.perm_support,
            secondWindow: true,
            showLoader: 'OK',
            text: _vm.$t('example')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')}}}):_vm._e(),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'example',title: _vm.$t('example')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_example"}},[_c(_vm.from_example,{tag:"component"})],1):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save',
                    text: _vm.$t('save')
                }},on:{"click":_vm.submitExample}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_return',
                    text: _vm.$root._route.params.windowed ? _vm.$t('close') : _vm.$t('back'),
                }},on:{"click":function($event){_vm.$root._route.params.windowed ? _vm.close() : _vm.router.go(-1)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }