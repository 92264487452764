var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:'KEY23' + _vm.remountComponent,staticClass:"float_window",style:(_vm.$root._route.params.from === 'parent-grouper' || _vm.$root._route.params.version || (_vm.$root._route.params.type === undefined && _vm.$root._route.params.view) ? 'margin-top: -4px;' : '')},[(_vm.$root._route.params.from === 'parent-grouper' || _vm.$root._route.params.version || (_vm.$root._route.params.type === undefined && _vm.$root._route.params.view))?_c('GhLocation',{attrs:{"dataLocation":{
            showButton: _vm.Login.perm_support,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.TabCreation.actual_grouper,
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')}}}):_vm._e(),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'grouper', title: _vm.TabCreation.actual_grouper}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?[_c('form',{attrs:{"id":"form_grouper"}},[_c('input',{attrs:{"type":"hidden","name":"grouper[type_grouper]"},domProps:{"value":_vm.$root._route.params.grouper}}),_c('div',{staticClass:"gh-content-global"},[(_vm.$root._route.params.type !== 'elaboration' && Object.values(_vm.$root._route.params).length !== 1)?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.code !== undefined ? _vm.CreationGrouper.grouper_data.code : ''))])]):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhInputTextField',{ref:"refInputPrename",attrs:{"datalabel":{
                                            text: _vm.$t('prename'),
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: false,
                                            id: 'input_grouper_prename',
                                            name: 'grouper[prename]',
                                            style: 'width: 100px',
                                            value: _vm.inputPrename,
                                            type: 'text',
                                        },"maxlength":"16"},on:{"blur":_vm.persistanceName}}),_c('GhInputTextField',{ref:"refInputName",attrs:{"datalabel":{
                                            text: _vm.$t('name') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        },"datainput":{
                                            required: true,
                                            id: 'input_grouper_name',
                                            name: 'grouper[name]',
                                            style: 'width: 200px',
                                            value: _vm.inputName,
                                            type: 'text',
                                        }},on:{"blur":_vm.persistanceName}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.name !== undefined ? (_vm.CreationGrouper.grouper_data.prename !== '' ? ' | ' : '') + _vm.CreationGrouper.grouper_data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{ref:"refTextareaDescription",staticStyle:{"margin-top":"-5px","width":"100%"},attrs:{"datalabel":{
                                            text: _vm.$t('description') + '*',
                                            class: 'T15_b',
                                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        },"datainput":{
                                            id: 'textarea_grouper_description',
                                            name: 'grouper[description]',
                                            value: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.description !== undefined ? _vm.CreationGrouper.grouper_data.description : '',
                                            required: true,
                                            edited: true,
                                            image: {
                                                subscript: require('@/assets/img/text/subscript.svg'),
                                                superscript: require('@/assets/img/text/superscript.svg'),
                                                bold: require('@/assets/img/text/text-bold.svg'),
                                                italic: require('@/assets/img/text/text-italic.svg'),
                                                underline: require('@/assets/img/text/text-underline.svg'),
                                            }
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px !important","max-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.description !== undefined ? _vm.CreationGrouper.grouper_data.description : '')}})]],2),_vm._l((_vm.actualExtraInfoStatement),function(item,key){return [_c('div',{key:'STATEMENT_' + key + _vm.refreshStatement,staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-row-content",staticStyle:{"width":"100%"}},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{ref:'refTextareaStatement' + key,refInFor:true,staticStyle:{"margin-top":"-5px","width":"100%"},attrs:{"datalabel":{
                                                    text: _vm.$t('statement'),
                                                    class: 'T15_b',
                                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                                },"datainput":{
                                                    id: 'textarea_grouper_statement_' + key,
                                                    name: 'grouper[extra_info_statement][' + key + '][statement]',
                                                    value: item.statement,
                                                    required: false,
                                                    edited: false
                                                }},on:{"keyup":function($event){return _vm.persistanceStatement('refTextareaStatement', key)}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px !important","max-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('statement') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(item.statement)}})]],2),(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled' && _vm.actualExtraInfoStatement.length !== 1)?[_c('div',{staticStyle:{"width":"150px"}},[_c('GhAction',{staticStyle:{"float":"right"},attrs:{"dataction":{
                                                    id: 'remove_statement_' + key,
                                                    text: _vm.$t('delete')
                                                }},on:{"click":function($event){return _vm.deleteStatement(key)}}})],1)]:_vm._e()],2),_c('div',{key:'SUBSTATEMENT_' + key + _vm.refreshStatement,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{ref:'refTextareaSubStatement' + key,refInFor:true,staticStyle:{"margin-top":"-5px","width":"100%"},attrs:{"datalabel":{
                                                text: _vm.$t('substatement'),
                                                class: 'T15_b',
                                                styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                            },"datainput":{
                                                id: 'textarea_grouper_substatement_' + key,
                                                name: 'grouper[extra_info_statement][' + key + '][sub_statement]',
                                                value: item.sub_statement,
                                                required: false,
                                                edited: true,
                                                image: {
                                                    subscript: require('@/assets/img/text/subscript.svg'),
                                                    superscript: require('@/assets/img/text/superscript.svg'),
                                                    bold: require('@/assets/img/text/text-bold.svg'),
                                                    italic: require('@/assets/img/text/text-italic.svg'),
                                                    underline: require('@/assets/img/text/text-underline.svg'),
                                                }
                                            }},on:{"emiter":function($event){return _vm.persistanceStatement('refTextareaSubStatement', key)},"focusout":function($event){return _vm.test()}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px !important","max-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('substatement') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(item.sub_statement)}})]],2)]}),(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('div',{staticStyle:{"padding-bottom":"10px"}},[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",staticStyle:{"margin-left":"160px"},attrs:{"dataction":{
                                            id: 'action_add_statement',
                                            text: _vm.$t('add_statement'),
                                            icon: require('@/assets/gh_new_dessign/add_plus.svg')
                                        }},on:{"click":_vm.addStatement}})],1)]:_vm._e(),(_vm.CreationGrouper.configuration_data !== undefined && _vm.CreationGrouper.configuration_data.information !== undefined && _vm.CreationGrouper.configuration_data.information.view)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{staticStyle:{"margin-top":"-5px","width":"100%"},attrs:{"datalabel":{
                                            text: _vm.$t('information') + (_vm.CreationGrouper.configuration_data.information.required ? '*' : ''),
                                            class: 'T15_b',
                                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        },"datainput":{
                                            id: 'textarea_grouper_information',
                                            name: 'grouper[information]',
                                            value: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.information !== undefined ? _vm.CreationGrouper.grouper_data.information : '',
                                            required: _vm.CreationGrouper.configuration_data.information.required ? true : false,
                                            edited: true,
                                            image: {
                                                subscript: require('@/assets/img/text/subscript.svg'),
                                                superscript: require('@/assets/img/text/superscript.svg'),
                                                bold: require('@/assets/img/text/text-bold.svg'),
                                                italic: require('@/assets/img/text/text-italic.svg'),
                                                underline: require('@/assets/img/text/text-underline.svg'),
                                            }
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px !important","max-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('information') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.information !== undefined ? _vm.CreationGrouper.grouper_data.information : '')}})]],2):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view  && _vm.$root._route.params.type !== 'enabled')?[_c('GhTreeSelectorBlock',{ref:"refTopic",attrs:{"dataBlock":{
                                            type: 'radio',
                                            id: 'label_topic',
                                            label: _vm.$t('topic') + '*',
                                            title: _vm.$t('topic'),
                                            text: _vm.$t('select_topic') + ':',
                                            name: 'grouper[topic]',
                                            id_value: 'value',
                                            name_label: 'text',
                                            child_key: 'children',
                                            required: true,
                                            checkparentdata: true,
                                            style: '',
                                            label_style: 'width: 149px',
                                            popupStyle: 'min-width: 900px; width: 55%;'
                                        },"selected_input":_vm.CreationGrouper.grouper_data.topic !== undefined && _vm.CreationGrouper.grouper_data.topic.length !== 0 ? _vm.CreationGrouper.grouper_data.topic : [],"items":_vm.getFeeds.feed_topic}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px !important","max-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('topic') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.topic !== undefined ? _vm.CreationGrouper.grouper_data.topic[0].text : ''))])]],2),(_vm.CreationGrouper.configuration_data !== undefined && _vm.CreationGrouper.configuration_data.responsible !== undefined && _vm.CreationGrouper.configuration_data.responsible.view)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                                            type: 'radio',
                                            id: 'label_grouper_responsible',
                                            label: _vm.$t('responsible') + (_vm.CreationGrouper.configuration_data.responsible.required ? '*' : ''),
                                            title: _vm.$t('responsible'),
                                            text: _vm.$t('select_responsible') + ':',
                                            name: 'grouper[responsible]',
                                            id_value: 'value',
                                            name_label: 'text',
                                            child_key: 'children',
                                            required: _vm.CreationGrouper.configuration_data.responsible.required,
                                            checkparentdata: false,
                                            style: '',
                                            label_style: 'width: 149px',
                                            popupStyle: 'min-width: 900px; width: 55%;'
                                        },"selected_input":_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.responsible !== undefined ? _vm.CreationGrouper.grouper_data.responsible : [],"items":_vm.getFeeds.feed_responsible}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px !important","max-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('responsible') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.responsible !== undefined && _vm.CreationGrouper.grouper_data.responsible[0] !== undefined ? _vm.CreationGrouper.grouper_data.responsible[0].text : ''))])]],2):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{staticStyle:{"margin-top":"-5px","width":"100%"},attrs:{"datalabel":{
                                            text: _vm.$t('introduction') + '*',
                                            class: 'T15_b',
                                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        },"datainput":{
                                            id: 'textarea_grouper_introduction',
                                            name: 'grouper[introduction]',
                                            value: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.introduction !== undefined ? _vm.CreationGrouper.grouper_data.introduction : '',
                                            required: true,
                                            edited: false
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px !important","max-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('introduction') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.introduction !== undefined ? _vm.CreationGrouper.grouper_data.introduction : '')}})]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view  && _vm.$root._route.params.type !== 'enabled')?[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px !important","max-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('image') + '*'))]),_c('div',[_c('GhEasyUpload',{attrs:{"extrablock":{
                                                id: 'grouper_image',
                                                class: '',
                                                name_hidden: 'grouper[image]',
                                                id_hidden: 'hidden_grouper_image',
                                                icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                                            },"datalabel":{
                                                id: 'label_grouper_image',
                                                style: ''
                                            },"datainput":{
                                                view: false,
                                                accept: '.png, .jpg, .jpeg',
                                                text: _vm.$t('attach_image'),
                                                show_image: true,
                                                delete: false,
                                                href: 'easyUpload',
                                                downloadHref: 'easyDownload',
                                                file_name: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.image !== undefined && _vm.CreationGrouper.grouper_data.image.file_name !== undefined ? _vm.CreationGrouper.grouper_data.image.file_name : '',
                                                file_path: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.image !== undefined && _vm.CreationGrouper.grouper_data.image.file_path !== undefined ? _vm.CreationGrouper.grouper_data.image.file_path : '',
                                                file_download: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.image !== undefined && _vm.CreationGrouper.grouper_data.image.file_download !== undefined ? _vm.CreationGrouper.grouper_data.image.file_download : '',
                                                image_path: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.image !== undefined && _vm.CreationGrouper.grouper_data.image.image_path !== undefined ? _vm.CreationGrouper.grouper_data.image.image_path : '',
                                                required: true
                                            }}})],1)]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px !important","max-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('image') + ':'))]),_c('img',{staticClass:"Output-Image",attrs:{"src":_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.image !== undefined && _vm.CreationGrouper.grouper_data.image.image_path !== undefined ? _vm.CreationGrouper.grouper_data.image.image_path : '',"alt":""}}),_c('GhEasyUpload',{attrs:{"extrablock":{
                                            id: 'grouper_image',
                                            class: '',
                                            name_hidden: 'grouper[image]',
                                            id_hidden: 'hidden_grouper_image',
                                            icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                                        },"datalabel":{
                                            id: 'label_grouper_image',
                                            style: ''
                                        },"datainput":{
                                            view: true,
                                            accept: '.png, .jpg, .jpeg',
                                            text: _vm.$t('attach_image'),
                                            show_image: true,
                                            delete: false,
                                            href: 'easyUpload',
                                            downloadHref: 'easyDownload',
                                            file_name: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.image !== undefined && _vm.CreationGrouper.grouper_data.image.file_name !== undefined ? _vm.CreationGrouper.grouper_data.image.file_name : '',
                                            file_path: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.image !== undefined && _vm.CreationGrouper.grouper_data.image.file_path !== undefined ? _vm.CreationGrouper.grouper_data.image.file_path : '',
                                            file_download: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.image !== undefined && _vm.CreationGrouper.grouper_data.image.file_download !== undefined ? _vm.CreationGrouper.grouper_data.image.file_download : '',
                                            image_path: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.image !== undefined && _vm.CreationGrouper.grouper_data.image.image_path !== undefined ? _vm.CreationGrouper.grouper_data.image.image_path : '',
                                            required: false
                                        }}})]],2),(_vm.CreationGrouper.grouper_data.pre_saved)?[(_vm.CreationGrouper.configuration_data !== undefined && _vm.CreationGrouper.configuration_data.elements !== undefined && _vm.CreationGrouper.configuration_data.elements.view)?_c(_vm.components.elements,{tag:"component"}):_vm._e(),_c(_vm.components.support,{tag:"component"}),_c(_vm.components.know_more,{tag:"component"}),(_vm.$root._route.params.type === 'enabled' || _vm.$root._route.params.type === 'historic')?[_c(_vm.components.format,{tag:"component"})]:_vm._e(),(_vm.CreationGrouper.configuration_data !== undefined && _vm.CreationGrouper.configuration_data.referentials !== undefined && _vm.CreationGrouper.configuration_data.referentials.view)?_c(_vm.components.associate,{tag:"component",attrs:{"props":{
                                        name: 'grouper',
                                        selecteds:{
                                            groupers: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.groupers_associated !== undefined ? _vm.CreationGrouper.grouper_data.groupers_associated : [],
                                        }
                                    }}}):_vm._e(),(_vm.CreationGrouper.configuration_data !== undefined && _vm.CreationGrouper.configuration_data.referentials !== undefined && _vm.CreationGrouper.configuration_data.referentials.view)?[_c(_vm.components.reference_thesauros,{tag:"component",attrs:{"props":{
                                            name:'grouper',
                                            selecteds:{
                                                groupers: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.groupers !== undefined ? _vm.CreationGrouper.grouper_data.groupers : [],
                                                topics: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.topics !== undefined ? _vm.CreationGrouper.grouper_data.topics : [],
                                                exercises: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.exercises !== undefined ? _vm.CreationGrouper.grouper_data.exercises : [],
                                                thesauros: _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.thesauros !== undefined ? _vm.CreationGrouper.grouper_data.thesauros : [],
                                            },
                                            view:{
                                                groupers: true,
                                                topics: false,
                                                exercises: true,
                                                thesauros: false
                                            },
                                            fixTopics: _vm.topicFixed
                                        }}})]:_vm._e(),(_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.notes !== undefined && _vm.CreationGrouper.grouper_data.notes.length !== 0)?[_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('situation')))])]),_c('div',{staticClass:"new-striped",staticStyle:{"margin-top":"10px"}},[_vm._l((_vm.CreationGrouper.grouper_data.notes),function(note,indexNote){return [_c('div',{key:'KEY22' + 'NOTE' + indexNote,staticStyle:{"padding-left":"5px","padding-top":"3px","padding-bottom":"3px","display":"flex"}},[_c('div',{staticStyle:{"width":"100%","display":"flex"}},[_c('div',{staticClass:"T15",staticStyle:{"margin-left":"5px","margin-top":"1px"}},[_vm._v(_vm._s(note.text))])])])]})],2)]:_vm._e()]:_vm._e()],2)]),_c(_vm.components.format_impartation,{tag:"component",attrs:{"info":_vm.popupFormatInfo}})]:(!_vm.haveData)?[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]:_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px","padding-bottom":"8px"}},[(_vm.haveData)?[(_vm.$root._route.params.version && (_vm.$root._route.params.type === 'elaboration' || _vm.$root._route.params.type === 'pending'))?[(_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.finish)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_finish',
                        text: _vm.$root._route.params.version ? _vm.$t('enable') : _vm.$t('finish')
                    }},on:{"click":function($event){return _vm.submit('finish')}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_save',
                        text: _vm.$t('save')
                    }},on:{"click":function($event){return _vm.submit('save')}}})]:[(!_vm.$root._route.params.version && !_vm.$root._route.params.view && _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.finish && _vm.$root._route.params.type === 'elaboration')?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_finish',
                        text: _vm.$root._route.params.version ? _vm.$t('enable') : _vm.$t('finish')
                    }},on:{"click":function($event){return _vm.submit('finish')}}}):_vm._e(),(!_vm.$root._route.params.version && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_save',
                        text: _vm.$t('save')
                    }},on:{"click":function($event){return _vm.submit('save')}}}):_vm._e(),(!_vm.$root._route.params.version && _vm.$root._route.params.type === 'pending' && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_enable',
                        text: _vm.$t('enable')
                    }},on:{"click":function($event){return _vm.submit('enable')}}}):_vm._e(),(!_vm.$root._route.params.version && _vm.$root._route.params.type === 'pending' && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_copy',
                        text: _vm.$t('copy')
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','CreationGrouperCopy', _vm.$root._route.params.id,{'code': _vm.CreationGrouper.grouper_data.code}, _vm.$t('copy_grouper'), _vm.$t('copy_delete_grouper'), 'delete')}}}):_vm._e(),(!_vm.$root._route.params.version && _vm.$root._route.params.type === 'pending' && !_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_delete',
                        text: _vm.$t('delete')
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','CreationGrouper', _vm.$root._route.params.id,{'code': _vm.CreationGrouper.grouper_data.code}, _vm.$t('delete_grouper'), _vm.$t('preparing_copy_grouper'), 'delete')}}}):_vm._e()],(_vm.$root._route.params.version && _vm.$root._route.params.type !== 'elaboration' && _vm.$root._route.params.type !== 'pending')?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_versioning',
                    text: _vm.$t('versioning')
                }},on:{"click":_vm.setVersion}}):_vm._e(),(_vm.$root._route.params.type == 'pending' || _vm.$root._route.params.type == 'enabled' || (_vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.finish))?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_preview',
                    text: _vm.$t('preview')
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('preview_grouper', {id:_vm.$root._route.params.id, type: 'grouper'}),'preview_grouper')}}}):_vm._e()]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$root._route.params.from === 'parent-grouper' || _vm.$root._route.params.version || (_vm.$root._route.params.type === undefined && _vm.$root._route.params.view) ? _vm.$t('close') : _vm.$t('back')
            }},on:{"click":function($event){_vm.$root._route.params.from === 'parent-grouper' || _vm.$root._route.params.version || (_vm.$root._route.params.type === undefined && _vm.$root._route.params.view) ? _vm.Global.windowClose() : _vm.router.push({name: 'create'})}}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }