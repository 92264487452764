var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:'KEY3' + 'view_elements_' + _vm.refreshView,staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"5px","margin-bottom":"10px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('items_that_make_up_the_grouper') + ': ' + _vm.TabCreation.actual_grouper))])]),(_vm.getterItemsComposed.length !== 0)?_c('div',{staticStyle:{"padding-bottom":"10px"},attrs:{"id":'items_composed_' + _vm.TabCreation.actual_grouper}},[_c('GhTreeActionBlock',{attrs:{"dataBlock":{
                id: 'tree_items_composed_' + _vm.TabCreation.actual_grouper,
                actionWithoutChildren: true,
                actionWithChildren: true,
                extraLabelNumber: false,
                extraLabelText: '',
                loadStorageOpen: true
            },"data":_vm.getterItemsComposed},scopedSlots:_vm._u([{key:"Action",fn:function(Action){return [(_vm.$root._route.params.type !== 'enabled')?_c('div',{staticStyle:{"display":"flex"}},[(Action.Action.Action.Action.show && false)?[_c('GhAction',{attrs:{"dataction":{
                                id: 'tree_associated_items_conditions_' + _vm.TabCreation.actual_grouper,
                                class: 'T15_action',
                                text: _vm.$t('conditions'),
                                icon: Action.Action.Action.Action.condition ? require('../../../../assets/svg/tick.svg') : require('../../../../assets/gh_new_dessign/denied.svg')
                            }},on:{"click":function($event){return _vm.checkConditions(Action.Action.Action.Action, _vm.$root._route.params.type !== undefined && _vm.$root._route.params.type !== 'elaboration')}}})]:_vm._e(),(!_vm.$root._route.params.view && _vm.$root._route.params.type !== undefined && (_vm.$root._route.params.type === 'elaboration' || _vm.$root._route.params.type === 'pending'))?[_c('GhAction',{attrs:{"dataction":{
                                id: 'tree_associated_items_modify_' + _vm.TabCreation.actual_grouper,
                                class: 'T15_action',
                                text: _vm.$t('modify'),
                                icon: require('../../../../assets/gh_new_dessign/edit.svg')
                            }},on:{"click":function($event){return _vm.checkTypeModify(Action.Action.Action.Action)}}}),_c('GhAction',{attrs:{"dataction":{
                                id: 'tree_associated_items_delete_' + _vm.TabCreation.actual_grouper,
                                class: 'T15_action',
                                text: _vm.$t('delete'),
                                icon: require('../../../../assets/gh_new_dessign/trash.svg')
                            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','AssociateItem', {
                                item_id: Action.Action.Action.Action.id,
                                element_id: Action.Action.Action.Action.element_id,
                                grouper_id: _vm.$root._route.params.grouper,
                                id: _vm.$root._route.params.id
                            },{'name': Action.Action.Action.Action.name}, _vm.$t('delete_item'), _vm.$t('preparing_delete_item'), 'delete')}}})]:_vm._e()],2):_vm._e()]}}],null,false,3008202313)})],1):_vm._e(),(!_vm.$root._route.params.view && _vm.$root._route.params.type !== undefined && (_vm.$root._route.params.type === 'elaboration' || _vm.$root._route.params.type === 'pending'))?_c('div',{staticClass:"centered"},[(_vm.CreationGrouper.grouper_data.show_punctuation && false)?[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_punctuation_item',
                    text: _vm.$t('punctuation')
                }},on:{"click":function($event){return _vm.openPunctuation()}}})]:_vm._e(),(_vm.getterItemsComposed.length !== 0 && _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.saved !== undefined && _vm.CreationGrouper.grouper_data.saved)?[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_order_item',
                    text: _vm.$t('order')
                }},on:{"click":function($event){return _vm.Global.openPopUp('popup_order')}}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_add_item',
                text: _vm.$t('add')
            }},on:{"click":function($event){return _vm.checkBeforeAssociate()}}})],2):_vm._e(),_c('GhDataSelector',{key:'KEY4' + _vm.refreshSelector,ref:"refAssociate",attrs:{"datablock":{
            id: 'associate_item',
            class: 'T15_b',
            subtitle: _vm.$t('select_associate_item') + ':',
            text: _vm.$t('associate'),
            label: _vm.$t('associate_item'),
            type: 'radio',
            columns: 3,
            required: false,
            style: 'margin-left: 84px; display: none;',
            popupStyle: 'min-width: 900px;width: 55%;'
        },"datainput":{
            id: 'value_associate_item',
            name: 'associate_item'
        },"feed":_vm.getFeeds.feed_exercise_groupers,"selected_input":[]},on:{"accept":_vm.associateSearch,"inputs":_vm.checkLoadAction,"close":_vm.checkCloseAssociate}}),_c('div',{staticStyle:{"display":"none"},attrs:{"id":"action_add_new_exercise"}},[_c('div',{staticStyle:{"display":"flex","margin-left":"50px"}},[_c('GhAction',{attrs:{"dataction":{
                    id: 'add_new_exercise',
                    text: _vm.$t('add_new'),
                    icon: require('../../../../assets/svg/add_plus.svg'),
                }},on:{"click":_vm.openNewExercise}})],1)]),(_vm.CreationGrouper.grouper_data.show_punctuation)?[_c(_vm.components.punctuation,{tag:"component"})]:_vm._e(),(_vm.getterItemsComposed.length !== 0 && _vm.CreationGrouper.grouper_data !== undefined && _vm.CreationGrouper.grouper_data.saved !== undefined && _vm.CreationGrouper.grouper_data.saved)?[_c(_vm.components.order,{tag:"component"})]:_vm._e(),_c(_vm.components.warning,{tag:"component"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }