import Vue from "vue";
import {API_URL} from "@/common/config";
import vuex from "vuex";
import axios from "axios";
import router from "@/routes/router";
// import router from "../../../routes/router"
// import Global from "../Global";
Vue.use(vuex);
export default {
    state: {
        itemsPay: [],
        typeButton: false,
        processPay: false,
        payments: {},
    },
    mutations:{
        loadPayments: (state, payload) => (state.payments = payload),
        loadTypeButton: (state, payload) => (state.typeButton = payload),
        loadProcessPay: (state, payload) => (state.processPay = payload),
        loadItemsPay: (state, payload) => (state.itemsPay = payload),
    },
    actions: {
        getPayments(state,item) {
            let requestLogin = API_URL + "payments" + (item.id !== '' ? "/" + item.id : '');

            axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                    state.commit("loadPayments", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async toNormalView(state){
            await this.dispatch('resetAppTabs');
            var id_redirect = router.options.GhTabs.find(n => n.value === 'my_itineraries')['id']

            localStorage.setItem('TabItem','my_itineraryes')
            localStorage.setItem('item','my_itineraryes')
            localStorage.setItem('RedirectPerm','programs')
            localStorage.setItem('id','C-' + id_redirect)
            state.rootState.Tabs.TabActive = 'programs';
            router.push({path: '/programs'});
        },
        async setPayments(state, item) {
            let requestLogin = API_URL + "payments" + (item.id !== '' ? "/" + item.id : '');

            let formData = new FormData();

            await axios({
                method: "post",
                data: formData,
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.dispatch('getAuthRedirect');
                        await this.dispatch('resetAppTabs');
                        var id_redirect = '';
                        if(state.rootState.Login.auth_redirect === 'recommended_programs'){
                            id_redirect = router.options.GhTabs.find(n => n.value === 'my_itineraries')['id'];

                            localStorage.setItem('TabItem','my_itineraries');
                            localStorage.setItem('item','my_itineraries');
                            localStorage.setItem('RedirectPerm','programs');
                            localStorage.setItem('id','C-' + id_redirect);
                            state.rootState.Tabs.TabActive = 'programs';
                            localStorage.setItem('my_itinerary_tab_','2my_itinerary_tab_');
                            localStorage.setItem('my_itinerary_tab__selected','{"id":2,"value":"recomended_programs","call":"recomended_programs"}');
                            router.push({path: '/programs'});
                        }else if(state.rootState.Login.auth_redirect === 'business'){
                            id_redirect = router.options.GhTabs.find(n => n.value === 'company')['id'];

                            localStorage.setItem('TabItem','company');
                            localStorage.setItem('item','company');
                            localStorage.setItem('RedirectPerm','business');
                            localStorage.setItem('id','C-' + id_redirect);
                            state.rootState.Tabs.TabActive = 'company';
                            router.push({path: '/business'});
                        }else{
                            router.push({path: localStorage.getItem('TabItem') ? router.options.routes[router.options.routes.findIndex((route) => route.name == localStorage.getItem('TabItem'))].path : '/config'});
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}
