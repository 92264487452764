<template>
    <div :style="$root._route.params.windowed ? 'margin-top: -3px' : ''">
        <GhLocation v-if="$root._route.params.windowed"
            :dataLocation="{
                showButton: Login.perm_support,
                secondWindow: true,
                showLoader: 'OK',
                text: $t('good_practices')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector :datablock="{id: 'good_practices',title: $t('good_practices')}">
                <template v-slot:content>
                    <form id="form_good_practices" v-if="haveData">
                        <div style="display: flex">
                            <div class="gh-content-global" style="width: 170px">
                                <div style="width: 168px; height: 168px;">
                                    <img :src="image" width="168" style="border-radius: 6px; max-height: 168px;" alt="">
                                </div>
                                <div class="other-image">
                                    <GhEasyUpload
                                        :extrablock="{
                                            id: 'good_practices_other_image',
                                            class: '',
                                            name_hidden: 'good_practices[other_image]',
                                            id_hidden: 'good_practices_other_image',
                                            icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                        }"
                                        :datalabel="{
                                            id: 'good_practices_other_image',
                                            style: 'margin-right: -15px'
                                        }"
                                        :datainput="{
                                            view: $root._route.params.view,
                                            accept: '.png, .jpg, .jpeg',
                                            text: $t(haveImage ? 'change_image' : 'attach_image'),
                                            show_image: false,
                                            delete: false,
                                            href: 'easyUpload',
                                            downloadHref: 'easyDownload',
                                            file_name: good_practices.other_image.file_name,
                                            file_path: good_practices.other_image.file_path,
                                            file_download: good_practices.other_image.file_download,
                                            required: false
                                        }"
                                    />
                                </div>
                            </div>
                            <div class="gh-content-global" style="width: 100%">
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('code') + '*',
                                                style: 'width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'good_practices_code',
                                                name: 'good_practices[code]',
                                                style: 'width: 200px',
                                                value: good_practices.code,
                                                type: 'text',
                                            }"
                                            ref="RefCode"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{good_practices.code}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('name') + '*',
                                                style: 'width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'good_practices_title',
                                                name: 'good_practices[name]',
                                                style: 'min-width: 625px; width: 40%',
                                                value: good_practices.name,
                                                type: 'text',
                                            }"
                                            ref="RefName"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{good_practices.name}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('author'),
                                                style: 'width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: false,
                                                id: 'good_practices_author',
                                                name: 'good_practices[author]',
                                                style: 'min-width: 625px; width: 40%',
                                                value: good_practices.author,
                                                type: 'text'
                                            }"
                                            ref="RefAuthor"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('author') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{good_practices.author}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('editor'),
                                                style: 'width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: false,
                                                id: 'good_practices_editor',
                                                name: 'good_practices[editor]',
                                                style: 'min-width: 625px; width: 40%',
                                                value: good_practices.editor,
                                                type: 'text'
                                            }"
                                            ref="RefEditor"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('editor') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{good_practices.editor}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhCalendar
                                            :datalabel="{
                                                id: 'label_good_practices_publication_date',
                                                text: $t('publication_date'),
                                                style: 'width:150px; min-width:150px; text-align: right;'
                                            }"
                                            :datainput="{
                                                id: 'input_good_practices_publication_date',
                                                name: 'good_practices[publication_date]',
                                                value: good_practices.publication_date
                                            }"
                                            ref="RefPublicationDate"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('publication_date') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{good_practices.publication_date}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('references'),
                                                style: 'width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: false,
                                                id: 'good_practices_references',
                                                name: 'good_practices[references]',
                                                style: 'min-width: 625px; width: 40%',
                                                value: good_practices.references,
                                                type: 'text'
                                            }"
                                            ref="RefReferences"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('references') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{good_practices.references}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('link'),
                                                style: 'width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: false,
                                                id: 'good_practices_link',
                                                name: 'good_practices[link]',
                                                style: 'min-width: 625px; width: 40%',
                                                value: good_practices.link,
                                                type: 'text'
                                            }"
                                            ref="RefLink"
                                            @keyup="setLink()"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('link') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{good_practices.link}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                                    <template v-if="!$root._route.params.view">
                                        <GhTreeSelectorBlock
                                            :dataBlock="{
                                                type: 'radio',
                                                id: 'label_topic',
                                                label: $t('topic'),
                                                title: $t('topic'),
                                                text: $t('select_topic') + ':',
                                                name: 'good_practices[topic]',
                                                id_value: 'value',
                                                name_label: 'text',
                                                child_key: 'children',
                                                required: true,
                                                checkparentdata: true,
                                                style: '',
                                                label_style: 'width: 149px',
                                                popupStyle: 'min-width: 900px; width: 55%;'
                                            }"
                                            :selected_input="good_practices.topic"
                                            :items="getFeeds.feed_topic"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('topic') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{good_practices.topic[0].text}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view">
                                        <GhTextarea
                                            :datalabel="{
                                                text: $t('description'),
                                                styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                            }"
                                            :datainput="{
                                                id: 'good_practices_description',
                                                name: 'good_practices[description]',
                                                value: good_practices.description,
                                                styles: 'padding-top: 2px;'
                                            }"
                                            ref="RefDescription"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{good_practices.description}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <label class="gh_text_field-label T15_b" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('document') + ':'}}</label>
                                    <div>
                                        <GhEasyUpload
                                            :extrablock="{
                                                id: 'good_practices',
                                                class: '',
                                                name_hidden: 'good_practices[document]',
                                                id_hidden: 'good_practices',
                                                icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                            }"
                                            :datalabel="{
                                                id: 'label_import_block',
                                                style: 'margin-right: -15px'
                                            }"
                                            :datainput="{
                                                view: $root._route.params.view,
                                                accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword, .odt, .zip, .pdf, .png, .jpg, .jpeg',
                                                text: $t('attach'),
                                                show_image: false,
                                                delete: false,
                                                href: 'easyUpload',
                                                downloadHref: 'easyDownload',
                                                file_name: good_practices.attached.file_name,
                                                file_path: good_practices.attached.file_path,
                                                file_download: good_practices.attached.file_download,
                                                required: good_practices.link !== '' ? false : true
                                            }"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <template v-else-if="!haveData">
                        <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </template>
                </template>
            </GhNoDropSelector>
            <div class="centered_footer" style="margin-top: 8px;">
                <GhButton v-if="!$root._route.params.view"
                    :datainput="{
                        id: 'btn_save',
                        text: $t('save')
                    }"
                    @click="submitSupportMaterial"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_return',
                        text: $root._route.params.windowed ? $t('close') : $t('back'),
                    }"
                    @click="$root._route.params.windowed ? close() : router.go(-1)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhEasyUpload from 'fe-gh-easy-upload-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState} from "vuex";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhLocation from "fe-gh-location-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    export default {
        name: "good_practices",
        components: {
            GhCalendar,
            GhLocation,
            GhTreeSelectorBlock,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhEasyUpload,
            GhButton
        },
        computed: {
            ...mapState(['BaseCreationSupportMaterialGoodPractice', 'getFeeds', 'UploadFiles', 'Login'])
        },
        data(){
            return {
                haveData: false,
                haveImage: false,
                good_practices: {
                    code: '',
                    name: '',
                    author: '',
                    editor: '',
                    publication_date: '',
                    references: '',
                    link: '',
                    topic: [],
                    description: '',
                    attached: {
                        image: '',
                        file_name: '',
                        file_path: '',
                        file_download: ''
                    },
                    other_image: {
                        image: '',
                        file_name: '',
                        file_path: '',
                        file_download: ''
                    }
                },
                image: ''
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFeedTopic', '', {root: true});
                await this.$store.dispatch('getSupportGoodPracticeData', {id: this.$root._route.params.id}, {root: true});

                if (this.$root._route.params.id !== undefined) {
                    this.good_practices.code = this.BaseCreationSupportMaterialGoodPractice.data.code;
                    this.good_practices.name = this.BaseCreationSupportMaterialGoodPractice.data.name;
                    this.good_practices.author = this.BaseCreationSupportMaterialGoodPractice.data.author;
                    this.good_practices.editor = this.BaseCreationSupportMaterialGoodPractice.data.editor;
                    this.good_practices.publication_date = this.BaseCreationSupportMaterialGoodPractice.data.publication_date;
                    this.good_practices.references = this.BaseCreationSupportMaterialGoodPractice.data.references;
                    this.good_practices.link = this.BaseCreationSupportMaterialGoodPractice.data.link;
                    this.good_practices.topic = this.BaseCreationSupportMaterialGoodPractice.data.topic;
                    this.good_practices.description = this.BaseCreationSupportMaterialGoodPractice.data.description;
                    this.good_practices.attached.image = this.BaseCreationSupportMaterialGoodPractice.data.attached.image;
                    this.good_practices.attached.file_name = this.BaseCreationSupportMaterialGoodPractice.data.attached.file_name;
                    this.good_practices.attached.file_path = this.BaseCreationSupportMaterialGoodPractice.data.attached.file_path;
                    this.good_practices.attached.file_download = this.BaseCreationSupportMaterialGoodPractice.data.attached.file_download;
                    this.good_practices.other_image.image = this.BaseCreationSupportMaterialGoodPractice.data.other_image.image;
                    this.good_practices.other_image.file_name = this.BaseCreationSupportMaterialGoodPractice.data.other_image.file_name;
                    this.good_practices.other_image.file_path = this.BaseCreationSupportMaterialGoodPractice.data.other_image.file_path;
                    this.good_practices.other_image.file_download = this.BaseCreationSupportMaterialGoodPractice.data.other_image.file_download;
                }

                if (this.good_practices.other_image.image !== '') {
                    this.image = this.good_practices.other_image.image;
                } else if (this.good_practices.attached.image !== '') {
                    this.image = this.good_practices.attached.image;
                } else {
                    this.image = require('../../../../../assets/gh_new_dessign/no-image.svg');
                }

                this.haveImage = this.good_practices.attached.image !== '' && this.good_practices.other_image.image !== '';
                this.haveData = true;
            }
        },
        watch:{
            'UploadFiles.data'(data){
                this.image = data.image;
                this.setLink();
                this.haveImage = true;
            }
        },
        methods: {
            async submitSupportMaterial(){
                if(this.Global.checkRequired('form_good_practices') === 'OK') {
                    const formData = new FormData(document.getElementById('form_good_practices'));
                    this.$store.dispatch('setSupportGoodPracticeData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            },
            setLink(){
                this.good_practices.code = this.$refs['RefCode'].datainput.value;
                this.good_practices.name = this.$refs['RefName'].datainput.value;
                this.good_practices.author = this.$refs['RefAuthor'].datainput.value;
                this.good_practices.editor = this.$refs['RefEditor'].datainput.value;
                this.good_practices.references = this.$refs['RefReferences'].datainput.value;
                this.good_practices.link = this.$refs['RefLink'].datainput.value;
                this.good_practices.description = this.$refs['RefDescription'].datainput.value;
            },
            close(){
                window.close();
            }
        },
    }
</script>

<style>
    .other-image > .easy_upload_block{
        flex-direction: initial;
    }
    .other-image > div > #good_practices_other_image_easy_download_block{
        display: none !important;
    }
</style>