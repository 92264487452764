import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        index: [],
        clients: [],
        listPrograms: [],
        listEvaluations: [],
        refreshListClientsIndex: false,
        refreshListClients: false,
        refreshListProgramsClients: false,
        refreshListEvaluationsClients: false
    },
    mutations: {
        loadClientsIndex(state, payload) {
            state.refreshListClientsIndex = !state.refreshListClientsIndex;
            state.index = payload;
        },
        loadClients(state, payload) {
            state.refreshListClients = !state.refreshListClients;
            state.clients = payload;
        },
        loadProgramsClients(state, payload) {
            state.refreshListProgramsClients = !state.refreshListProgramsClients;
            state.listPrograms = payload;
        },
        loadEvaluationsClients(state, payload) {
            state.refreshListEvaluationsClients = !state.refreshListEvaluationsClients;
            state.listEvaluations = payload;
        }
    },
    getters: {
        getterListClientsIndex(state){
            const refreshListClientsIndex = state.refreshListClientsIndex; // eslint-disable-line no-unused-vars
            var itemIndex = [[], [], []];

            var rows = Math.ceil(state.index.length / 4);

            var count = 0;
            var row = 0;
            state.index.forEach((item) => {
                itemIndex[row].push(item);
                count++;
                if(count === rows){
                    row++;
                    count = 0;
                }
            });

            itemIndex.forEach((i, k) => {
                if(i.length !== rows){
                    const emptyLength = rows - i.length;
                    for(var x = 0; x < emptyLength; x++){
                        itemIndex[k].push(null);
                    }
                }
            });

            return itemIndex;
        },
        getterRealListClientsIndex(state){
            const refreshListClientsIndex = state.refreshListClientsIndex; // eslint-disable-line no-unused-vars
            return state.index;
        },
        getterListClients(state){
            const refreshListClients = state.refreshListClients; // eslint-disable-line no-unused-vars
            return state.clients;
        },
        getterListProgramsClients(state){
            const refreshListProgramsClients = state.refreshListProgramsClients; // eslint-disable-line no-unused-vars
            return state.listPrograms;
        },
        getterListEvaluationsClients(state){
            const refreshListProgramsClients = state.refreshListEvaluationsClients; // eslint-disable-line no-unused-vars
            return state.listEvaluations;
        }
    },
    actions: {
        async getClientsIndex(state){
            await axios.get(
                API_URL + "clients/index"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadClientsIndex', response.data.data);
                    }
                }
            )
        },
        async getListClientsIndex(state, item){
            await axios.get(
                API_URL + "clients/index/" + item.index
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadClients', response.data.data);
                    }
                }
            )
        },
        async getProgramsFinishByCleint(state,items){
            let requestLogin = API_URL + "user/company/" + items.id + "/programs/finish";

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadProgramsClients', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getEvaluationsAndStampFinishByClient(state,items){
            let requestLogin = API_URL + "user/company/" + items.id + "/evaluations/finish";

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadEvaluationsClients', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPartnerClub(state, item){
            let requestLogin = API_URL + "partner-club/" + item.id;
            let formData = new FormData();

            formData.append('partner_club', item.check);

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        //
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};
