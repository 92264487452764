<template>
    <div class="gh-content-global">
        <div class="gh-row-line-flex">
            <div style="width: 33.33%; display: flex">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 160px; min-width: 160px; text-align: right;">{{$t('company_name') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterAssignationBase.data !== undefined ? getterAssignationBase.data.company_name : ''}}</label>
            </div>
            <div style="width: 66.66%; display: flex">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('program') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterAssignationBase.data !== undefined ? getterAssignationBase.data.program : ''}}</label>
            </div>
        </div>
        <div class="gh-row-line-flex">
            <div class="gh-row-column-flex">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 160px; min-width: 160px; text-align: right;">{{$t('number_employees') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterAssignationBase.data !== undefined ? getterAssignationBase.data.number_employees : ''}}</label>
            </div>
            <div class="gh-row-column-flex">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('activity_sector') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterAssignationBase.data !== undefined ? getterAssignationBase.data.activity_sector : ''}}</label>
            </div>
            <div class="gh-row-column-flex">
                <template v-if="getterAssignationBase.show !== undefined && getterAssignationBase.show.new_stamp_customer">
                    <GhChecks
                        :datalabel="{
                            text: $t('new_stamp_customer'),
                            style: '',
                            class: '',
                            id: ''
                        }"
                        :datainput="{
                            required: false,
                            disabled: $root._route.params.view !== undefined ? $root._route.params.view : false,
                            selected: getterAssignationBase.data !== undefined && getterAssignationBase.data.stamp_customer === 1,
                            type: 'checkbox',
                            id: 'assignation_base_new_stamp_customer',
                            value: 1,
                            style: 'margin-left: 160px;',
                            name: 'assignation_base[new_stamp_customer]'
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('stamp_customer') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterAssignationBase.data !== undefined && getterAssignationBase.data.stamp_customer ? $t('yes') : $t('no')}}</label>
                </template>
            </div>
        </div>
        <template v-if="getterAssignationBase.show !== undefined && getterAssignationBase.show.last_evaluation_rank">
            <div class="gh-row-line-flex">
                <div style="width: 33.33%; display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 160px; min-width: 160px; text-align: right;">{{$t('last_evaluation_rank') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterAssignationBase.data !== undefined ? getterAssignationBase.data.last_evaluation_rank : ''}}</label>
                </div>
                <div style="width: 66.66%; display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('date') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterAssignationBase.data !== undefined ? getterAssignationBase.data.date : ''}}</label>
                </div>
            </div>
        </template>
        <template v-if="getterAssignationBase.show !== undefined && getterAssignationBase.show.last_evaluation_rank">
            <div class="gh-row-line-flex">
                <div style="width: 100%; display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 160px; min-width: 160px; text-align: right;">{{$t('team_evaluation') + ' ' + $t('leader') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterAssignationBase.data !== undefined ? getterAssignationBase.data.leader : ''}}</label>
                    <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('evaluators') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterAssignationBase.data !== undefined ? getterAssignationBase.data.evaluators : ''}}</label>
                </div>
            </div>
        </template>
        <div class="gh-row-line-flex">
            <div class="gh-row-column-flex no-min-width">
                <template v-if="!$root._route.params.view">
                    <GhDataSelector
                        :datablock="{
                            id: 'assignation_base_type_evaluation',
                            class: 'T15_b',
                            subtitle: $t('select_type_evaluation') + ':',
                            text: $t('type_evaluation') + '*',
                            label: $t('type_evaluation'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        }"
                        :datainput="{
                            id: 'value_assignation_base_type_evaluation',
                            name: 'assignation_base[type_evaluation]'
                        }"
                        :feed="getFeeds.feed_type_evaluation"
                        :selected_input="getterAssignationBase.data !== undefined ? getterAssignationBase.data.type_evaluation : []"
                        @accept="checkTypeEvaluationFormatEvaluation"
                        ref="refTypeEvaluation"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('type_evaluation') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 170px;">{{getterAssignationBase.data !== undefined ? Object.values(getterAssignationBase.data.type_evaluation).join() : ''}}</label>
                </template>
            </div>
            <div class="gh-row-column-flex no-min-width">
                <template v-if="!$root._route.params.view">
                    <GhDataSelector
                        :datablock="{
                            id: 'assignation_base_format_evaluation',
                            class: 'T15_b',
                            subtitle: $t('select_format_evaluation') + ':',
                            text: $t('format_evaluation') + '*',
                            label: $t('format_evaluation'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        }"
                        :datainput="{
                            id: 'value_assignation_base_format_evaluation',
                            name: 'assignation_base[format_evaluation]'
                        }"
                        :feed="getFeeds.feed_evaluator_modality"
                        :selected_input="getterAssignationBase.data !== undefined ? getterAssignationBase.data.format_evaluation : []"
                        @accept="checkTypeEvaluationFormatEvaluation"
                        ref="refFormatEvaluation"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="position: absolute;width: 150px; min-width: 150px; text-align: right;">{{$t('format_evaluation') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{getterAssignationBase.data !== undefined ? Object.values(getterAssignationBase.data.format_evaluation).join() : ''}}</label>
                </template>
            </div>
            <div class="gh-row-column-flex no-min-width">
                <template v-if="!$root._route.params.view">
                    <GhDataSelector
                        :datablock="{
                            id: 'assignation_base_preferred_language',
                            class: 'T15_b',
                            subtitle: $t('select_preferred_language') + ':',
                            text: $t('preferred_language') + '*',
                            label: $t('preferred_language'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        }"
                        :datainput="{
                            id: 'value_assignation_base_preferred_language',
                            name: 'assignation_base[preferred_language]'
                        }"
                        :feed="getFeeds.feed_languages_evaluator"
                        :selected_input="getterAssignationBase.data !== undefined ? getterAssignationBase.data.preferred_language : []"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="position: absolute;width: 150px; min-width: 150px; text-align: right;">{{$t('preferred_language') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{getterAssignationBase.data !== undefined ? Object.values(getterAssignationBase.data.preferred_language).join() : ''}}</label>
                </template>
            </div>
        </div>
        <div class="gh-row-line-flex">
            <div class="gh-row-column-flex no-min-width">
                <template v-if="!$root._route.params.view">
                    <GhDataSelector
                        :datablock="{
                            id: 'assignation_base_ccaa',
                            class: 'T15_b',
                            subtitle: $t('select_autonomous_community') + ':',
                            text: $t('autonomous_community') + '*',
                            label: $t('autonomous_community'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        }"
                        :datainput="{
                            id: 'value_assignation_base_ccaa',
                            name: 'assignation_base[ccaa]'
                        }"
                        :feed="getFeeds.feed_ccaa"
                        :selected_input="getterAssignationBase.data !== undefined ? getterAssignationBase.data.ccaa : []"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="position: absolute;width: 150px; min-width: 150px; text-align: right;">{{$t('autonomous_community') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{getterAssignationBase.data !== undefined ? Object.values(getterAssignationBase.data.ccaa).join() : ''}}</label>
                </template>
            </div>
            <div class="gh-row-column-flex">
                <GhChecks
                    :datalabel="{
                        text: $t('trips_during_the_visit'),
                        style: '',
                        class: '',
                        id: ''
                    }"
                    :datainput="{
                        required: false,
                        disabled: $root._route.params.view !== undefined ? $root._route.params.view : false,
                        selected: getterAssignationBase.data !== undefined && getterAssignationBase.data.trips_during_the_visit === 1,
                        type: 'checkbox',
                        id: 'assignation_base_trips_during_the_visit',
                        value: 1,
                        style: 'margin-left: 160px;',
                        name: 'assignation_base[trips_during_the_visit]'
                    }"
                />
            </div>
            <div class="gh-row-column-flex">
                <GhChecks
                    :datalabel="{
                        text: $t('international_ambit'),
                        style: '',
                        class: '',
                        id: ''
                    }"
                    :datainput="{
                        required: false,
                        disabled: $root._route.params.view !== undefined ? $root._route.params.view : false,
                        selected: getterAssignationBase.data !== undefined && getterAssignationBase.data.international_ambit === 1,
                        type: 'checkbox',
                        id: 'assignation_base_international_ambit',
                        value: 1,
                        style: 'margin-left: 160px;',
                        name: 'assignation_base[international_ambit]'
                    }"
                />
            </div>
        </div>
        <div class="gh-row-line-flex">
            <div style="width: 33.33%; display: flex">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 160px; min-width: 160px; text-align: right;">{{$t('n_evaluators') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{evaluators}}</label>
            </div>
            <div style="width: 66.66%; display: flex">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('n_days') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{days}}</label>
            </div>
        </div>
        <div class="gh-row-line-flex">
            <div style="width: 33.33%; display: flex">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('evaluators_ampliation'),
                            style: 'width: 160px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'assignation_base_evaluators',
                            name: 'assignation_base[evaluators]',
                            style: 'min-width: 20px; width: 20px',
                            value: persistance.evaluators,
                            type: 'int'
                        }"
                        @keyup="sumEvaluators"
                        maxlength="1"
                        ref="refEvaluatorsAmpliation"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('evaluators_ampliation') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 170px;">{{persistance.evaluators}}</label>
                </template>
            </div>
            <div style="width: 66.66%; display: flex">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('days_ampliation'),
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'assignation_base_days',
                            name: 'assignation_base[days]',
                            style: 'min-width: 20px; width: 20px',
                            value: persistance.days,
                            type: 'int'
                        }"
                        @keyup="sumDays"
                        maxlength="1"
                        ref="refDaysAmpliation"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="position: absolute;width: 150px; min-width: 150px; text-align: right;">{{$t('days_ampliation') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 160px;">{{persistance.days}}</label>
                </template>
            </div>
        </div>
        <div class="gh-row-line-flex">
            <div style="width: 33.33%; display: flex">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 160px; min-width: 160px; text-align: right;">{{$t('total_evaluators') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{total_evaluators}}</label>
            </div>
            <div style="width: 66.66%; display: flex">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 160px; text-align: right;">{{$t('total_days') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{total_days}}</label>
            </div>
        </div>
        <div class="gh-row-line-flex">
            <template v-if="!$root._route.params.view">
                <GhDataSelector
                    :datablock="{
                        id: 'assignation_base_estimated_score_range',
                        class: 'T15_b',
                        subtitle: $t('select_estimated_score_range') + ':',
                        text: $t('estimated_score_range') + '*',
                        label: $t('estimated_score_range'),
                        type: 'radio',
                        columns: 3,
                        required: false,
                        style: '',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_assignation_estimated_score_range',
                        name: 'assignation_base[estimated_score_range]'
                    }"
                    :feed="getFeeds.feed_score_range"
                    :selected_input="getterAssignationBase.data !== undefined ? getterAssignationBase.data.estimated_score_range : []"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right; height: 34px;">{{$t('estimated_score_range') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-left: 170px;">{{getterAssignationBase.data !== undefined ? Object.values(getterAssignationBase.data.estimated_score_range).join() : ''}}</label>
            </template>
        </div>
        <div class="gh-row-line-flex" :style="$root._route.params.view ? 'margin-top: 40px;' : ''" style="width: 100%;background-color: var(--pallette-three) !important;padding-bottom: 4px;margin-bottom: 25px;">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 160px; min-width: 160px; text-align: right;">{{$t('global_score') + ':'}}</label>
            <label class="gh_text_field-label T15_b">{{persistance.type_score}}</label>
            <label class="gh_text_field-label T15_b">{{persistance.value_range}}</label>
            <label class="gh_text_field-label T15_b">{{persistance.percentage + '%'}}</label>
            <label class="gh_text_field-label T15_b">{{global_score.global}}</label>
        </div>
        <div class="gh-row-line-flex">
            <div class="children-contents" style="width: 33.33%; display: flex">
                <template v-if="!$root._route.params.view">
                    <GhCalendar
                        :datalabel="{
                            id: 'label_assignation_base_start_date',
                            text: $t('start_date_visit'),
                            style: 'width: 160px; min-width: 160px; text-align: right; margin-right: 12px;'
                        }"
                        :datainput="{
                            id: 'assignation_base_start_date',
                            name: 'assignation_base[start_date]',
                            value: getterAssignationBase.data !== undefined ? getterAssignationBase.data.start_date : '',
                            required: true,
                            format: 'DD/MM/YYYY',
                            placeholder: 'DD/MM/YYYY'
                        }"
                        @change="setStartDate"
                        ref="refStartDate"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right; height: 34px;">{{$t('start_date_visit') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 170px;">{{getterAssignationBase.data !== undefined ? getterAssignationBase.data.start_date : ''}}</label>
                </template>
                <template v-if="week !== ''">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; text-align: right;">{{$t('week') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{week}}</label>
                </template>
            </div>
            <div class="children-contents" style="width: 33.33%; display: flex">
                <template v-if="!$root._route.params.view">
                    <GhCalendar
                        :datalabel="{
                            id: 'label_assignation_base_start_date_realization',
                            text: $t('start_date_realization'),
                            style: 'width: 160px; min-width: 160px; text-align: right; margin-right: 12px;'
                        }"
                        :datainput="{
                            id: 'assignation_base_start_date_realization',
                            name: 'assignation_base[start_date_realization]',
                            value: getterAssignationBase.data !== undefined ? getterAssignationBase.data.start_date_realization : '',
                            required: true,
                            format: 'DD/MM/YYYY',
                            placeholder: 'DD/MM/YYYY'
                        }"
                        @change="setStartDateRealization"
                        ref="refStartDateRealization"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right; height: 34px;">{{$t('start_date_realization') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 170px;">{{getterAssignationBase.data !== undefined ? getterAssignationBase.data.start_date_realization : ''}}</label>
                </template>
            </div>
            <div style="width: 33.33%; display: flex">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('close_date_realization') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{date_close_realization}}</label>
            </div>
        </div>
        <div class="gh-row-line-flex">
            <div class="gh-row-column-flex">
                <GhChecks
                    :datalabel="{
                        text: $t('tutorships'),
                        style: '',
                        class: '',
                        id: ''
                    }"
                    :datainput="{
                        required: false,
                        disabled: $root._route.params.view !== undefined ? $root._route.params.view : false,
                        selected: getterAssignationBase.data !== undefined && getterAssignationBase.data.tutorships === 1,
                        type: 'checkbox',
                        id: 'assignation_base_tutorships',
                        value: 1,
                        style: 'margin-left: 170px;',
                        name: 'assignation_base[tutorships]'
                    }"
                />
            </div>
            <div class="gh-row-column-flex">
                <GhChecks
                    :datalabel="{
                        text: $t('support'),
                        style: '',
                        class: '',
                        id: ''
                    }"
                    :datainput="{
                        required: false,
                        disabled: $root._route.params.view !== undefined ? $root._route.params.view : false,
                        selected: getterAssignationBase.data !== undefined && getterAssignationBase.data.support === 1,
                        type: 'checkbox',
                        id: 'assignation_base_support',
                        value: 1,
                        style: 'margin-left: 160px;',
                        name: 'assignation_base[support]'
                    }"
                />
            </div>
            <div class="gh-row-column-flex no-min-width">
<!--                <template v-if="getterAssignationBase.data !== undefined && getterAssignationBase.data.weighting_groups !== undefined">-->
<!--                    <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right; height: 34px;">{{$t('weighting_group') + ':'}}</label>-->
<!--                    <label class="gh_text_field-label T15_b" style="margin-left: 170px;">{{getterAssignationBase.data !== undefined ? Object.values(getterAssignationBase.data.weighting_groups).join() : ''}}</label>-->
<!--                </template>-->
            </div>
        </div>
        <template v-if="getterAssignationBase.data !== undefined && getterAssignationBase.data.weighting_groups !== undefined && getterAssignationBase.data.associated_weighting_groups">
            <div class="gh-row-line-flex">
                <label class="T15_b gh_text_field-label" style="margin-left: 170px;">{{$t('weighting_groups')}}</label>
            </div>
            <div v-for="(i, k) in Object.keys(getterAssignationBase.data.associated_weighting_groups)" class="gh-row-line-flex" :key="'ASSOCIATED_WEIGHTING_GROUPS_' + k">
                <GhInputTextField
                    :datalabel="{
                        text: getterAssignationBase.data.associated_weighting_groups[i].name + '*',
                        style: 'width: 160px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'assignation_base_associated_weighting_groups_' + i,
                        name: 'assignation_base[associated_weighting_groups][' + i + ']',
                        style: 'min-width: 40px; width: 40px',
                        value: persistance.weighting_groups[i] !== undefined && persistance.weighting_groups[i].value !== undefined ? persistance.weighting_groups[i].value : '',
                        type: 'int',
                        limitNumMax: maxlength
                    }"
                    @focusout="controlWeighting('refWeighting' + i, i)"
                    :ref="'refWeighting' + i"
                />
            </div>
        </template>
        <template v-if="getterAssignationBase.show.list_evaluators">
            <div class="gh-row-line-flex" v-if="getterAssignationBase.show.show_leader">
                <div class="gh-row-column-flex" style="height: 16px">
                    <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('lead_manager') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 172px;">{{$parent.$parent.leaderText}}</label>
                </div>
            </div>
            <div class="gh-row-line-flex" v-if="getterAssignationBase.show.show_evaluator">
                <div class="gh-row-column-flex" style="height: 16px">
                    <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('advisory_evaluator') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-left: 172px;">{{$parent.$parent.evaluatorText}}</label>
                </div>
            </div>
        </template>

        <template v-if="getterAssignationBase.buttons.recalculate || getterAssignationBase.buttons.validate">
            <div class="centered" style="margin-top: 15px;">
                <template v-if="!$root._route.params.view">
                    <template v-if="getterAssignationBase.buttons.validate">
                        <GhButton
                            :datainput="{
                                id: 'btn_validate',
                                text: $t('validate'),
                                class: $parent.$parent.leaderText === '' || $parent.$parent.evaluatorText === '' || ($parent.$parent.evaluatorText !== '' && $parent.$parent.evaluatorText.split(',').length !== total_evaluators) ? 'disabled' : ''
                            }"
                            :disabled="$parent.$parent.leaderText === '' || $parent.$parent.evaluatorText === '' || ($parent.$parent.evaluatorText !== '' && $parent.$parent.evaluatorText.split(',').length !== total_evaluators)"
                            @click="$parent.$parent.submit('validate')"
                        />
                    </template>
                    <template v-if="getterAssignationBase.buttons.recalculate">
                        <GhButton
                            :datainput="{
                                id: 'btn_recalculate',
                                text: $t('recalculate')
                            }"
                            @click="$parent.$parent.submit('recalculate')"
                        />
                    </template>
                    <GhButton
                        :datainput="{
                            id: 'btn_save',
                            text: $t('save')
                        }"
                        @click="$parent.$parent.submit('save')"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'btn_return',
                        text: $t('back'),
                    }"
                    @click="$router.go(-1)"
                />
            </div>
        </template>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'assignation_base',
        components: {
            GhChecks,
            GhDataSelector,
            GhInputTextField,
            GhCalendar,
            GhButton
        },
        computed: {
            ...mapGetters(['getterAssignationBase']),
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                persistance: {
                    type_score: '',
                    evaluators: '',
                    days: '',
                    value_range: '',
                    percentage: '',
                    start_date: '',
                    weighting_groups: []
                },
                maxlength: 100,
                evaluators: '',
                total_evaluators: '',
                days: '',
                total_days: '',
                global_score: {
                    global: ''
                },
                week: '',
                date_close_realization: '',
                min_start_date_realization: ''
            }
        },
        async beforeMount(){
            if(this.getterAssignationBase.data !== undefined && this.getterAssignationBase.data.type_evaluation !== undefined && this.getterAssignationBase.data.format_evaluation !== undefined && Object.keys(this.getterAssignationBase.data.type_evaluation)[0] !== undefined && Object.keys(this.getterAssignationBase.data.format_evaluation)[0] !== undefined) {
                await this.$store.dispatch('getValuesTypeEvaluation', {
                    id_type_eval: Object.keys(this.getterAssignationBase.data.type_evaluation)[0],
                    id_format_eval: Object.keys(this.getterAssignationBase.data.format_evaluation)[0]
                }, {root: true}).then((response) => {
                    if (response.status === 200) {
                        this.evaluators = response.data.data.evaluators;
                        this.days = response.data.data.days;
                    }
                });
            }

            if(this.getterAssignationBase.data !== undefined){
                if(this.getterAssignationBase.data.value_range !== undefined && this.getterAssignationBase.data.value_range !== '' && this.getterAssignationBase.data.percentage !== undefined && this.getterAssignationBase.data.percentage !== '') {
                    await this.$store.dispatch('getGlobalScore', {
                        value_range: this.getterAssignationBase.data.value_range,
                        percentage: this.getterAssignationBase.data.percentage,
                    }, {root: true}).then((response) => {
                        if (response.status === 200) {
                            this.global_score.global = response.data.data.global_score;
                        }
                    });
                }
                if(this.getterAssignationBase.data.start_date !== '' && this.getterAssignationBase.data.start_date !== undefined){
                    let split_date = this.getterAssignationBase.data.start_date.split('/');
                    let date = split_date[2] + '-' + split_date[1] + '-' + split_date[0];

                    this.week = this.getWeekNumber(date);
                    this.date_close_realization = this.subtractDays(date, 15);
                    this.min_start_date_realization = this.subtractDays(date, 30);
                }


                this.persistance.type_score = this.getterAssignationBase.data.type_score;
                this.persistance.evaluators = this.getterAssignationBase.data.evaluators_ampliation;
                this.persistance.days = this.getterAssignationBase.data.days_ampliation;
                this.persistance.value_range = this.getterAssignationBase.data.value_range;
                this.persistance.percentage = this.getterAssignationBase.data.percentage;
                this.persistance.weighting_groups = this.getterAssignationBase.data.associated_weighting_groups;
                this.evaluators = this.getterAssignationBase.data.evaluators;
                this.days = this.getterAssignationBase.data.days;

                this.total_evaluators = parseInt(this.evaluators !== null ? this.evaluators : 0) + parseInt(this.getterAssignationBase.data.evaluators_ampliation !== null ? this.getterAssignationBase.data.evaluators_ampliation : 0);
                this.total_days = parseInt(this.days !== null ? this.days : 0) + parseInt(this.getterAssignationBase.data.days_ampliation !== null ? this.getterAssignationBase.data.days_ampliation : 0);

                if(this.getterAssignationBase.data.users.leader !== undefined) {
                    let leaderText = '';
                    this.getterAssignationBase.data.users.leader.forEach((i) => {
                        leaderText += i.full_name + ', ';
                    });
                    this.$parent.$parent.leaderText = leaderText.slice(0, -2);
                }

                if(this.getterAssignationBase.data.users.evaluator !== undefined) {
                    let evaluatorText = '';
                    this.getterAssignationBase.data.users.evaluator.forEach((i) => {
                        evaluatorText += i.full_name + ', ';
                    });
                    this.$parent.$parent.evaluatorText = evaluatorText.slice(0, -2);
                }
            }
        },
        methods: {
            async checkTypeEvaluationFormatEvaluation(){
                if(Object.keys(this.$refs['refTypeEvaluation'].checkdata)[0] !== undefined && Object.keys(this.$refs['refFormatEvaluation'].checkdata)[0] !== undefined) {
                    await this.$store.dispatch('getValuesTypeEvaluation', {
                        id_type_eval: Object.keys(this.$refs['refTypeEvaluation'].checkdata)[0],
                        id_format_eval: Object.keys(this.$refs['refFormatEvaluation'].checkdata)[0]
                    }, {root: true}).then((response) => {
                        if (response.status === 200) {
                            this.evaluators = response.data.data.evaluators;
                            this.days = response.data.data.days;
                        }
                    });

                    this.sumEvaluators();
                    this.sumDays();
                }
            },
            sumEvaluators(){
                this.setPersistance();
                this.total_evaluators = parseInt(this.evaluators !== null ? this.evaluators : 0) + parseInt(this.$refs['refEvaluatorsAmpliation'].datainput.value !== null ? this.$refs['refEvaluatorsAmpliation'].datainput.value : 0);
            },
            sumDays(){
                this.setPersistance();
                this.total_days = parseInt(this.days !== null ? this.days : 0) + parseInt(this.$refs['refDaysAmpliation'].datainput.value !== null ? this.$refs['refDaysAmpliation'].datainput.value : 0);
            },
            setPersistance(){
                this.persistance.evaluators = this.$refs['refEvaluatorsAmpliation'].datainput.value;
                this.persistance.days = this.$refs['refDaysAmpliation'].datainput.value;
            },
            setStartDate(){
                let date = this.formatDate(this.$refs['refStartDate'].value1);
                this.week = this.getWeekNumber(this.formatDate(this.$refs['refStartDate'].value1));
                this.date_close_realization = this.subtractDays(date, 15);
                this.min_start_date_realization = this.subtractDays(date, 30);
                if(this.formatDate(this.$refs['refStartDateRealization'].value1) !== 'NaN-NaN-NaN'){
                    this.setStartDateRealization();
                }
            },
            setStartDateRealization(){
                let real_date = this.formatDate(this.$refs['refStartDateRealization'].value1);
                let split_date = this.min_start_date_realization.split('/');
                let date = split_date[2] + '-' + split_date[1] + '-' + split_date[0];
                if (date < real_date) {
                    this.$parent.$parent.$parent.warning_element_text = 'start_date_realization_min_30_days';
                    this.Global.openPopUp('warning_element');
                    this.$refs['refStartDateRealization'].value1 = '';
                }
            },
            formatDate(date) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');

                return `${year}-${month}-${day}`;
            },
            getWeekNumber(date) {
                if (!(date instanceof Date)) {
                    date = new Date(date);
                }

                const currentDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                const startDate = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
                const daysSinceStart = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
                return Math.ceil((daysSinceStart + startDate.getUTCDay() + 1) / 7);
            },
            subtractDays(date, days) {
                if (!(date instanceof Date)) {
                    date = new Date(date);
                }

                date.setDate(date.getDate() - days);

                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();

                return `${day}/${month}/${year}`;
            },
            controlWeighting(ref, id){
                var num = parseInt(this.$refs[ref][0].datainput.value, 10);

                if (num % 5 !== 0) {
                    num = Math.floor(num / 5) * 5;
                }

                this.persistance.weighting_groups[id].value = num;
                this.maxlength = 100;

                Object.keys(this.persistance.weighting_groups).forEach((i) => {
                    this.maxlength = this.maxlength - this.persistance.weighting_groups[i].value;
                });
            }
        }
    }
</script>

<style>
    #assignation_base_type_evaluation_selector_label, #assignation_base_estimated_score_range_selector_label, #assignation_base_ccaa_selector_label{
        width: 160px !important;
        text-align: right;
    }

    #assignation_base_format_evaluation_selector_label, #assignation_base_preferred_language_selector_label, #assignation_base_weighting_group_selector_label{
        width: 150px !important;
        text-align: right;
    }
</style>