var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'practice_evaluator',
        },"header":_vm.header,"data":_vm.getterPracticeEvaluator},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('span',[_vm._v(_vm._s(itemProp[labelProp] === 1 ? _vm.$t('leader') : (itemProp[labelProp] === 2 ? _vm.$t('evaluator') : '')))])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'assign_practice_evaluator_' + itemProp.id,
                    text: _vm.$t(itemProp.show_assign ? 'assign' : 'unassign'),
                    icon: require(itemProp.show_assign ? '../../../../assets/gh_new_dessign/tick.svg' : '../../../../assets/gh_new_dessign/return-blue.svg')
                }},on:{"click":function($event){return _vm.assignAction(itemProp)}}})]}}],null,false,3111541344)})],1):_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])
}
var staticRenderFns = []

export { render, staticRenderFns }